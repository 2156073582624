import CN from 'clsx';

import { useAuth } from '@r-client/shared/feature/auth';
import { Box, Button, Link, Text } from '@r-client/shared/ui/core';
import { Dropdown } from '@r-client/shared/ui/dropdown';
import { Flex } from '@r-client/shared/ui/flex';
import { Spinner } from '@r-client/shared/ui/spinner';

import { useNotificationsMarkAsSeenMutation } from '../../graphql/notifications-mark-as-seen-mutation';
import type { INotification } from '../../types';
import { NotificationList } from '../notification-list/notification-list';

import styles from './notifications-dropdown.module.scss';
export interface INotificationsDropdownProps {
  children: React.ReactElement;
  loading?: boolean;
  notifications: INotification[] | undefined;
  visibilitySetter?: (a: boolean) => void;
  visible?: boolean;
}

const isUnseen = (notification: INotification) => !notification.seen;

export function NotificationsDropdown({
  children,
  loading,
  notifications,
  visibilitySetter,
  visible,
}: INotificationsDropdownProps) {
  const updateAllMutation = useNotificationsMarkAsSeenMutation({
    variables: {
      notificationLimit: 30,
    },
  });
  const hasUnseenNotifications = notifications?.some(isUnseen);
  const { reloadSession } = useAuth();

  const handleMarkAllAsSeen = async () => {
    const response = await updateAllMutation.submit();
    if (response) reloadSession();
  };

  const hasNotifications =
    notifications !== undefined && notifications?.length > 0;

  return (
    <Dropdown
      arrow
      visible={visible}
      trigger="click"
      interactive={false}
      onVisibleChange={visibilitySetter}
      content={
        <div>
          <div className={styles.listContainer}>
            {loading && (
              <Box p="1brm">
                <Spinner centered />
              </Box>
            )}
            {hasNotifications ? (
              <NotificationList notifications={notifications} compact />
            ) : (
              <Box m="1.5brm">
                <Text align="center" color="gray60">
                  You don't have any recent notifications
                </Text>
              </Box>
            )}
          </div>
          <Box className={styles.dropdownActions}>
            <Link className={styles.displayContents} href="/notifications">
              <Flex
                p=".5brm"
                alignItems="center"
                justifyContent="center"
                className={CN(styles.dropdownAction, {
                  [styles.bordered]: hasNotifications,
                })}
                data-testid="view-all-notifications"
              >
                View All
              </Flex>
            </Link>
            {hasUnseenNotifications && (
              <Button
                className={styles.displayContents}
                type="link"
                linkColor="gray60"
                onClick={handleMarkAllAsSeen}
              >
                <Flex
                  p=".5brm"
                  alignItems="center"
                  justifyContent="center"
                  className={styles.dropdownAction}
                  data-testid="mark-all-as-seen"
                >
                  Mark all as seen
                </Flex>
              </Button>
            )}
          </Box>
        </div>
      }
    >
      {children}
    </Dropdown>
  );
}
