import { autorun, createAtom, when } from 'mobx';

import { IPromisedComputedValue } from './promised-computed';

export const loadAsyncComputed = async <T>(
  field: IPromisedComputedValue<T>
): Promise<T> => {
  let value: T;
  await when(() => {
    value = field.get();
    return field.busy === false;
  });
  return value!;
};

export function preloadCall<
  T,
  TFn extends (...args: any[]) => Promise<T>,
  TArgs extends Parameters<TFn>
>(init: T, fn: TFn): { init: T; get: TFn } {
  let flag = false;
  const get: any = (...param: TArgs): Promise<T> => {
    if (flag) {
      return fn(...param);
    }
    flag = true;
    return Promise.resolve(init);
  };
  return { init, get };
}

export function computedDelayed<T>(
  opt: { delay: number; init: T | (() => T) },
  fn: (prev: T | undefined) => T
): { get(): T };
export function computedDelayed<T>(
  opt: number,
  fn: (prev: T | undefined) => T
): { get(): T | undefined };
export function computedDelayed<T>(
  opt: any,
  fn: (prev: T | undefined) => T
): { get(): T | undefined } {
  const init: T | undefined =
    typeof opt === 'number'
      ? undefined
      : opt.init instanceof Function
      ? opt.init()
      : opt.init;
  const delay: number = typeof opt === 'number' ? opt : opt.delay ?? 0;
  let lastValue: T | undefined;
  let readValue = true;
  let token: any = null;
  let cancel: undefined | (() => void);
  const notify = createAtom('notify');
  const atom = createAtom(
    'computedDelayed',
    () => {
      cancel = autorun(() => {
        notify.reportObserved();
        if (token) {
          clearTimeout(token);
          token = null;
        }
        if (readValue) {
          lastValue = fn(lastValue ?? init);
          atom.reportChanged();
          readValue = false;
        } else {
          token = setTimeout(() => {
            token = null;
            readValue = true;
            notify.reportChanged();
          }, delay);
        }
      });
    },
    () => {
      cancel?.();
      cancel = undefined;
      if (token) {
        clearTimeout(token);
        token = null;
      }
      readValue = true;
      lastValue = undefined;
    }
  );

  return {
    get: () => {
      atom.reportObserved();
      return lastValue ?? (init instanceof Function ? init() : init);
    },
  };
}
