import { ReactNode, useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import { BannerWrapper, Disclosure } from '@r-client/republic/feature/banners';
import { Footer, IFooterProps } from '@r-client/republic/feature/layout';
import { Layout, TLayoutConfig } from '@r-client/republic/feature/layout';
import {
  HEADER_THEMES,
  IRegionSwitcherSettings,
  MainHeader,
} from '@r-client/republic/feature/main-header';
import { ScrollTopButton } from '@r-client/republic/ui/core';
import { useAnalytics } from '@r-client/shared/data/analytics';
import { AdminMenu } from '@r-client/shared/feature/admin-menu';
import { useAuth } from '@r-client/shared/feature/auth';
import {
  TRegionValidIds,
  useRegionContext,
  withRegionContext,
} from '@r-client/shared/feature/region';
import { setCookie, useAppConfig } from '@r-client/shared/util/core';

import { regionalSwitcherVariants } from '../../utils/regional-switcher';

export type TFooterConfig = IFooterProps & {
  hideFooter?: boolean;
};

export type THeaderConfig = IFooterProps & {
  hideRaiseCapitalCta?: boolean;
  hideEventDisplay?: boolean;
  hideBanner?: boolean;
  hideTradeRepublicDisclosure?: boolean;
  bringToTop?: boolean;
};

export interface IMainLayoutProps {
  children?: ReactNode;
  sticky?: boolean;
  theme?: HEADER_THEMES;
  overlay?: boolean;
  transparent?: boolean;
  transparentGray?: boolean;
  footerConfig?: TFooterConfig;
  headerConfig?: THeaderConfig;
  layoutConfig?: TLayoutConfig;
}

const globalRoutes = ['/', '/hldmzfknye'];

export const MainLayout = withRegionContext(
  observer<IMainLayoutProps>(function MainLayoutComponent({
    children,
    sticky,
    theme,
    overlay = false,
    footerConfig,
    headerConfig,
    layoutConfig,
  }) {
    const { route } = useRouter();
    const { region: currentRegion, setRegion } = useRegionContext();
    const { republicEuropeBaseUrl } = useAppConfig();

    const { viewer, isLoading: authLoading } = useAuth();

    const analytics = useAnalytics();

    const isLoading = authLoading;

    const onRegionSwitch = useMemo(
      () => (id: TRegionValidIds) => {
        setCookie({
          name: 'region',
          value: id,
          options: { maxAge: 400 * 24 * 60 * 60 * 1000 },
        });
        setRegion(id);
      },
      [setRegion]
    );

    const regionSwitcherSettings: IRegionSwitcherSettings | undefined =
      useMemo(() => {
        if (isLoading) {
          return undefined;
        }
        return {
          variants: regionalSwitcherVariants({
            onChange: onRegionSwitch,
            mode: globalRoutes.includes(route) ? 'cookie-only' : 'navigate',
            republicEuropeBaseUrl:
              republicEuropeBaseUrl ?? 'https://europe.republic.com',
          }),
          activeVariantID: currentRegion,
        };
      }, [
        isLoading,
        onRegionSwitch,
        route,
        republicEuropeBaseUrl,
        currentRegion,
      ]);

    const onScrollTopClick = () =>
      analytics.track({ name: 'Back to top link - clicked' });

    const footer = !footerConfig?.hideFooter ? (
      <Footer {...footerConfig} />
    ) : null;

    return (
      <>
        <AdminMenu />
        {headerConfig?.hideTradeRepublicDisclosure === false && <Disclosure />}
        {!headerConfig?.hideBanner ? <BannerWrapper /> : null}
        <Layout
          config={layoutConfig}
          header={
            <MainHeader
              theme={theme}
              overlay={overlay}
              sticky={sticky}
              user={{ viewer, isLoading }}
              region={currentRegion === 'global' ? 'global' : 'us'}
              regionSwitcherSettings={regionSwitcherSettings}
            />
          }
          footer={footer}
        >
          {children}
        </Layout>
        <div onClick={onScrollTopClick}>
          <ScrollTopButton />
        </div>
      </>
    );
  })
);
