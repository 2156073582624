import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';

import { HEADER_THEMES } from '../../types';
import { getTextColor } from '../../utils/get-text-color';
import { GlobeIcon } from './icons';
import { RegionSwitcherDialog } from './region-switcher-dialog';
import { RegionSwitcherItem } from './region-switcher-item';
import { IRegionSwitcherSettings } from './types'; // Import the missing interface
import { useRegionSwitcher } from './use-region-switcher'; // Import the missing hook

export interface IRegionSwitcherDesktopProps {
  variant: HEADER_THEMES;
  regionalSwitcherSettings: IRegionSwitcherSettings;
}

export function RegionSwitcherDesktop({
  variant: currentVariant,
  regionalSwitcherSettings,
}: IRegionSwitcherDesktopProps) {
  const {
    variants,
    activeVariantID,
    onSetVariant,
    onModalClose,
    onConfirmVariant,
    isModalOpen,
    proposedVariant,
  } = useRegionSwitcher(regionalSwitcherSettings);

  const { titleName: name, inlineName } =
    variants.find((v) => v.id === activeVariantID) ?? variants[0];

  const popoverTrigger = (
    <Flex
      _hover={
        currentVariant !== 'dark' ? { bg: 'grey.50', color: 'grey.900' } : {}
      }
      borderRadius="8px"
      padding="7px"
      color={getTextColor(currentVariant)}
      cursor="default"
      alignItems="center"
    >
      <GlobeIcon boxSize="6" />
      <Text
        as="span"
        verticalAlign="middle"
        ml="1"
        textStyle="md"
        color={getTextColor(currentVariant)}
      >
        {name}
      </Text>
    </Flex>
  );

  return (
    <>
      <Popover trigger="hover" placement="bottom-end">
        <PopoverTrigger>{popoverTrigger}</PopoverTrigger>
        <PopoverContent
          borderColor="transparent"
          boxShadow="base"
          w="max"
          flexDir="row"
        >
          <PopoverArrow />
          <PopoverBody p="0" px="0">
            <Box mx="4" mt="4">
              <Text textStyle="md" fontWeight="semibold" mb="2">
                Region preference
              </Text>
              <Text textStyle="md" mb="3" color="grey.400">
                You are viewing the {inlineName} site
              </Text>
            </Box>

            <Box>
              {variants.map((variant) => (
                <RegionSwitcherItem
                  key={variant.id}
                  px="4"
                  py="2.5"
                  title={variant.name}
                  isActive={variant.id === activeVariantID}
                  onClick={() => onSetVariant(variant)}
                />
              ))}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <RegionSwitcherDialog
        isOpen={isModalOpen}
        onClose={onModalClose}
        onConfirm={onConfirmVariant}
        variant="modal"
        targetRegion={proposedVariant}
        regionalSwitcherSettings={regionalSwitcherSettings}
      />
    </>
  );
}
