import { useState } from 'react';

export const useSelectedNavigationEntry = (defaultSelected?: number) => {
  const [selectedNavigationEntry, setSelectedNavigationEntry] = useState<
    number | undefined
  >(defaultSelected);

  const onSelect = (index?: number) => {
    setSelectedNavigationEntry(
      index !== undefined ? index : selectedNavigationEntry
    );
  };

  const onReset = () => {
    setSelectedNavigationEntry(undefined);
  };

  return { selectedNavigationEntry, onSelect, onReset };
};
