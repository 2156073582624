import { useRef } from 'react';

export const useNoPageScroll = () => {
  const previouslySetChecked = useRef(false);
  const pageScrollPreviouslyDisabled = useRef(false);

  const disablePageScroll = () => {
    if (pageScrollPreviouslyDisabled.current) return;

    if (
      !previouslySetChecked.current &&
      !!document.documentElement.style.overflow
    ) {
      pageScrollPreviouslyDisabled.current = true;
    } else {
      previouslySetChecked.current = true;
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    }
  };

  const enablePageScroll = () => {
    if (pageScrollPreviouslyDisabled.current) return;

    document.documentElement.style.overflow = '';
    document.body.style.overflow = '';
  };

  return { disablePageScroll, enablePageScroll };
};
