import { useMediaQuery as useMediaQuery$ } from 'react-responsive';
export const media = {
  sm: 768,
  md: 970,
  lg: 1170,
};

// TODO: MK move hooks to separate ui library
const useMediaQuery: typeof useMediaQuery$ =
  typeof window === 'undefined' ? () => true : useMediaQuery$;

export const useMediaUpSm = () =>
  useMediaQuery({ query: `all and (min-width: ${media.sm}px)` });

export const useMediaUpMd = () =>
  useMediaQuery({ query: `all and (min-width: ${media.md}px)` });

export const useMediaUpLg = () =>
  useMediaQuery({ query: `all and (min-width: ${media.lg}px)` });

export const useMediaDownSm = () =>
  useMediaQuery({ query: `all and (max-width: ${media.md - 1}px)` });

export const useMediaDownXs = () =>
  useMediaQuery({ query: `all and (max-width: ${media.sm - 1}px)` });

// TODO: Find out why the useMediaDownXs is not working properly in JSX for time being I have create similar with function

export const useMobile = () =>
  useMediaQuery$({ query: `(max-width: ${media.sm - 1}px)` });

export const useDesktop = () =>
  useMediaQuery$({ query: `(min-width: ${media.sm}px)` });

export const useMediaDownMd = () =>
  useMediaQuery({ query: `all and (max-width: ${media.lg - 1}px)` });

export const useMediaOnlySm = () =>
  useMediaQuery({
    query: `all and (min-width: ${media.sm}px) and (max-width: ${
      media.md - 1
    }px)`,
  });

export const useMediaOnlyMd = () =>
  useMediaQuery({
    query: `all and (min-width: ${media.md}px) and (max-width: ${
      media.lg - 1
    }px)`,
  });

export const useMediaDown = (width: number) =>
  useMediaQuery({ query: `all and (max-width: ${width}px)` });
