import { Box, Button } from '@chakra-ui/react';

import backgroundDesktop from './assets/background.jpg';
import backgroundMobile from './assets/background-mobile.jpg';
import starsLeft from './assets/stars-left.png';
import starsRight from './assets/stars-right.png';

export const SpaceWeekBanner = () => {
  return (
    <Box overflow="hidden" backgroundColor="black" color="white">
      <Box
        as="a"
        href="/spaceweek"
        display="grid"
        alignItems="center"
        gridTemplateColumns={{ base: 'auto', sm: '1fr 175px' }}
        gap="4px"
        padding={{
          base: '8px 16px 12px',
          sm: '12px 16px',
          lg: '12px 16px 12px 191px',
        }}
        backgroundImage={{
          base: `url(${backgroundMobile})`,
          md: `url(${backgroundDesktop})`,
        }}
        backgroundPosition="50% 0"
        backgroundRepeat="no-repeat"
        backgroundSize={{ base: 'cover', md: 'contain' }}
        letterSpacing="-0.02em"
        textDecoration="none"
        color="inherit"
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Box textAlign={{ base: 'center', sm: 'left', lg: 'center' }}>
          <Box
            display="inline-block"
            padding={{ base: '4px 0', lg: '4px 88px 4px 110px' }}
            background={{
              lg: `url(${starsLeft}) no-repeat 0 50% / contain, url(${starsRight}) no-repeat 100% 50% / contain`,
            }}
          >
            It’s{' '}
            <Box as="span" color="#F4FFAE">
              Space Week:
            </Box>{' '}
            learn about investing in space + win a trip to a rocket launch
          </Box>
        </Box>
        <Button
          height="auto"
          py="9px"
          background="none"
          color="inherit"
          fontSize="14px"
          borderRadius="3px"
          boxShadow="inset 0 0 0 1px rgba(255,255,255, 0.4)"
          _hover={{ boxShadow: 'inset 0 0 0 1px white' }}
          variant="republic-default"
          size="md"
        >
          3 2 1.. Lift off! 🚀
        </Button>
      </Box>
    </Box>
  );
};
