import { createIcon } from '@chakra-ui/react';

export const MailLetterIcon = createIcon({
  displayName: 'MailLetterIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6ZM5.51859 6L12 11.6712L18.4814 6H5.51859ZM20 7.32877L12.6585 13.7526C12.2815 14.0825 11.7185 14.0825 11.3415 13.7526L4 7.32877V18H20V7.32877Z"
      fill="currentColor"
    />
  ),
});
