/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import * as Types from '@r-client/data/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IAdminMenuQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IAdminMenuQuery = {
  __typename?: 'Query';
  adminMenu: Array<{
    __typename?: 'AdminMenu';
    link?: string | null | undefined;
    title: string;
    items?:
      | Array<{
          __typename?: 'AdminMenu';
          link?: string | null | undefined;
          title: string;
          items?:
            | Array<{
                __typename?: 'AdminMenu';
                link?: string | null | undefined;
                title: string;
                items?:
                  | Array<{
                      __typename?: 'AdminMenu';
                      link?: string | null | undefined;
                      title: string;
                    }>
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
  }>;
};

export const AdminMenuDocument = gql`
  query AdminMenu {
    adminMenu {
      items {
        link
        title
        items {
          items {
            link
            title
          }
          link
          title
        }
      }
      link
      title
    }
  }
`;
export type AdminMenuQueryResult = Apollo.QueryResult<
  IAdminMenuQuery,
  IAdminMenuQueryVariables
>;
