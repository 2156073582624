import CN from 'clsx';
import capitalize from 'lodash/capitalize';

import { Avatar, Box, Link } from '@r-client/shared/ui/core';
import { FlexItem } from '@r-client/shared/ui/flex';

import {
  E_AUTOSUGGEST_ITEMS_LIST,
  E_OFFERING_STATUS_TYPES,
} from '../../constants';
import tagIcon from '../../images/tag.svg';
import { AutosuggestItemData, TAutosuggestItem } from '../../types';

import styles from './autosuggest-item.module.scss';

export interface IAutosuggestItemProps {
  className?: string;
  data: TAutosuggestItem;
  currentUserSlug?: string;
  setAutosuggestItemClicked?: (data: AutosuggestItemData) => void;
}

export const AutosuggestItem = ({
  className,
  data,
  currentUserSlug,
  setAutosuggestItemClicked,
}: IAutosuggestItemProps) => {
  const { type, url, text, slug } = data;

  function onItemClick() {
    setAutosuggestItemClicked?.({ type, value: slug });
  }

  const AutosuggestItemAvatar = () => {
    switch (type) {
      case E_AUTOSUGGEST_ITEMS_LIST.TAG:
        return (
          <Avatar url={tagIcon} size={'xSmall'} round={true} border={false} />
        );
      case E_AUTOSUGGEST_ITEMS_LIST.OFFERING:
        return (
          <Avatar
            url={data?.imageURL}
            url2x={data.imageURL2x}
            size={'xSmall'}
          />
        );
      case E_AUTOSUGGEST_ITEMS_LIST.USER:
        return (
          <Avatar
            url={data?.imageURL}
            url2x={data.imageURL2x}
            size={'xSmall'}
            round={true}
          />
        );
      default:
        return null;
    }
  };

  const AutosuggestItemBadge = () => {
    if (type === E_AUTOSUGGEST_ITEMS_LIST.OFFERING && data.campaignStatus) {
      return (
        <Box
          className={CN(styles.badge, {
            [styles.live]: data.campaignStatus === E_OFFERING_STATUS_TYPES.live,
            [styles.comingSoon]:
              data.campaignStatus === E_OFFERING_STATUS_TYPES.comingSoon,
          })}
        >
          {capitalize(data.campaignStatus).replace('-', ' ')}
        </Box>
      );
    } else if (
      type === E_AUTOSUGGEST_ITEMS_LIST.USER &&
      currentUserSlug === slug
    ) {
      return <Box className={CN(styles.badge, styles.currentUser)}>You</Box>;
    } else {
      return null;
    }
  };

  return (
    <Link
      href={url}
      className={CN(styles.suggestion, className)}
      onClick={onItemClick}
      data-testid="auto-suggest-item"
      data-entity-type={data.type}
    >
      <AutosuggestItemAvatar />
      <FlexItem grow={1} className={styles.title}>
        {text}
      </FlexItem>
      <AutosuggestItemBadge />
    </Link>
  );
};
