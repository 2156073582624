import { Flex, FlexProps, Text } from '@chakra-ui/react';

import { CheckMarkIcon } from './icons';

export interface IRegionSwitcherItemProps extends FlexProps {
  title: string;
  isActive?: boolean;
}

export const RegionSwitcherItem = ({
  title,
  isActive,
  ...props
}: IRegionSwitcherItemProps) => {
  return (
    <Flex
      color={isActive ? 'grey.500' : undefined}
      cursor={isActive ? 'default' : 'pointer'}
      alignContent="space-between"
      alignItems="center"
      _hover={{ bg: isActive ? undefined : 'grey.50' }}
      {...props}
    >
      <Text textStyle="md" flexGrow="10" color="inherit">
        {title}
      </Text>
      {isActive ? (
        <CheckMarkIcon key="CheckMarkIcon" color="grey.200" boxSize="6" />
      ) : null}
    </Flex>
  );
};
