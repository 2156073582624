import { useAnalytics } from '@r-client/shared/data/analytics';
import { Box, Button, Link, Text } from '@r-client/shared/ui/core';
import { Flex, FlexItem } from '@r-client/shared/ui/flex';
import { ArrowCircleRight, SvgIconLight } from '@r-client/shared/ui/icons';

import styles from './state-change-confirmation-banner.module.scss';

export interface IStateChangeConfirmationBannerProps {
  waitlist?: boolean;
  companyName: string;
  offeringSlug: string;
  daysLeft: number;
}

export function StateChangeConfirmationBanner({
  waitlist,
  companyName,
  offeringSlug,
  daysLeft,
}: IStateChangeConfirmationBannerProps) {
  const analytics = useAnalytics();

  const viewAndConfirmHandler = (
    button_name: 'action_required' | 'view_and_confirm'
  ) => {
    analytics.track({
      name: 'website_banner_clicked',
      params: {
        banner_name: 'StateChangeConfirmationBanner',
        button_name,
        extra_data: {
          daysLeft,
          companyName,
          offeringSlug,
          waitlist,
        },
      },
    });
  };
  const title = ` to confirm ${
    waitlist === true ? 'waitlist spot for' : 'investment in'
  } ${companyName}`;
  const daysLeftTitle = `${daysLeft} ${daysLeft > 1 ? 'days' : 'day'} left`;
  return (
    <Link href={`/portfolio/${offeringSlug}`} className={styles.bannerLink}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        direction={{ xs: 'column', sm: 'row' }}
      >
        <Button
          danger
          type="primary"
          size="tiny"
          className={styles.actionRequiredBtn}
          onClick={() => viewAndConfirmHandler('action_required')}
        >
          Action required
        </Button>
        <Text
          weight="500"
          type="bodyMedium"
          m="0 1brm"
          className={styles.bannerTxt}
        >
          <Box as="span" m="0 1brm 0 0" hidden={{ xs: true, sm: true }}>
            <Text as="span" m="0 1brm 0 0">
              Don't lose your spot
            </Text>
            <span className={styles.verticalLine} />
          </Box>
          <Text as="span">
            <Box m="0 4px 0 0" as="span">
              <SvgIconLight
                icon={ArrowCircleRight}
                height={18}
                legacyVerticalAlignment
              />
            </Box>
            <Text as="span" weight="600" color="blue">
              {daysLeftTitle}
            </Text>
            {title}
          </Text>
          <Box as="span" hidden={{ sm: true, md: true, lg: true }}>
            <Text as="span">. Don't lose your spot!</Text>
          </Box>
        </Text>
        <FlexItem alignSelf={{ xs: 'stretch' }} m={{ xs: '0.5brm 0 0 0' }}>
          <Button
            type="primary"
            size="small"
            fullWidth
            onClick={() => viewAndConfirmHandler('view_and_confirm')}
          >
            View and confirm
          </Button>
        </FlexItem>
      </Flex>
    </Link>
  );
}
