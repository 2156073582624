import { observer } from 'mobx-react-lite';

import { useAnalytics } from '@r-client/shared/data/analytics';
import { useAuth } from '@r-client/shared/feature/auth';
import { Link } from '@r-client/shared/ui/core';

import { ReactComponent as LogoBlack } from './images/logo_black.svg';
import { ReactComponent as LogoWhite } from './images/logo_white.svg';
import { ReactComponent as RLogo } from './images/r-logo.svg';
import { ReactComponent as RLogoBlue } from './images/r-logo-blue.svg';
import { ReactComponent as RLogoSmall } from './images/r-logo-small.svg';
import { ReactComponent as RLogoWhite } from './images/r-logo-white.svg';
import { ReactComponent as RLogoWhiteFull } from './images/r-logo-white-full.svg';

export enum E_LOGO_TYPES {
  Black,
  White,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  RLogo,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  RLogoSmall,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  RLogoWhite,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  RLogoBlue,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  RLogoWhiteFull,
}

const LOGOS = {
  [E_LOGO_TYPES.Black]: LogoBlack,
  [E_LOGO_TYPES.White]: LogoWhite,
  [E_LOGO_TYPES.RLogo]: RLogo,
  [E_LOGO_TYPES.RLogoSmall]: RLogoSmall,
  [E_LOGO_TYPES.RLogoWhite]: RLogoWhite,
  [E_LOGO_TYPES.RLogoWhiteFull]: RLogoWhiteFull,
  [E_LOGO_TYPES.RLogoBlue]: RLogoBlue,
};

export interface IRepublicLogoProps {
  type: E_LOGO_TYPES;
  logoProps?: React.SVGProps<SVGSVGElement>;
}

export const RepublicLogo = observer(function RepublicLogo({
  type,
  logoProps = {},
}: IRepublicLogoProps) {
  const Logo = LOGOS[type];

  const isDashboardEnabled =
    useAuth().viewer?.info?.investorProfile?.hasActiveRepublicSubscription;
  const analytics = useAnalytics();

  const handleLogoClick = () => {
    analytics.track({
      name: 'header_logo_clicked',
      params: {
        destination: isDashboardEnabled ? 'dashboard' : 'homepage',
        platform: 'web',
      },
    });
  };

  return (
    <Link
      href={isDashboardEnabled ? '/dashboard' : '/'}
      onClick={handleLogoClick}
    >
      <Logo {...logoProps} />
    </Link>
  );
});
