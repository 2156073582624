import { MouseEvent } from 'react';

import { Box as ChakraBox } from '@chakra-ui/react';

import { useAnalytics } from '@r-client/shared/data/analytics';
import { Box, Link, List, Spacer, Text } from '@r-client/shared/ui/core';

import styles from './footer-links.module.scss';

export interface IFooterLinksProps {
  label: string;
  url?: string;
  links: Array<{
    label: string;
    url?: string;
    target?: string;
    onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  }>;
}

export function FooterLinks(props: IFooterLinksProps) {
  const analytics = useAnalytics();
  const { label, links, url } = props;

  return (
    // @TODO: https://linear.app/republic/issue/USMKT-431/switch-back-the-crypto-column-in-the-footer-on-r-client-and-monolith
    <ChakraBox px={4} flexGrow={1}>
      <Box m={Box.bottom(0.75)} p={Box.bottom(0.75)} as="h5">
        <Text weight="500" color="gray60" className={styles.footerText}>
          {url ? <Link href={url}></Link> : label}
        </Text>
      </Box>
      <List>
        {links.map((link) => (
          <List.Item key={link.label}>
            <Text
              weight="500"
              color="gray-e0"
              lineHeight="1.4"
              className={styles.footerText}
            >
              <Link
                href={link.url}
                target={link.target ?? '_self'}
                inherit
                onClick={(e) => {
                  analytics.track({
                    name: 'bottom_block_menu_click',
                    params: {
                      parentCategory: label.toLowerCase().replace(' ', '_'),
                      category: link.label.toLowerCase().replace(' ', '_'),
                    },
                  });
                  link.onClick?.(e);
                }}
              >
                {link.label}
              </Link>
            </Text>
          </List.Item>
        ))}
      </List>
      <Spacer size="1.5brm" />
    </ChakraBox>
  );
}
