import { observer } from 'mobx-react-lite';

import {
  getError,
  handleChange,
  handleTouched,
  IFieldBase,
} from '../field-helpers';
import { IInputProps, Input } from '../input';
import { InputGroup } from '../input-group';
import {
  ITooltipForInputProps,
  TooltipForInput,
} from '../input-with-tooltip/tooltip';
import { E_VALIDATION_STATUS } from '../types';

export interface IInputFieldProps
  extends IInputProps,
    IFieldBase<string | undefined> {
  tooltipProps?: ITooltipForInputProps;
  showMessage?: boolean;
  enableStatus?: boolean;
  forwardedRef?: React.MutableRefObject<HTMLInputElement | undefined>;
}

/**
 * @deprecated
 * please use Input from @chakra-ui/react instead.
 * team is migrating form interaction to react-hook-form
 */
export const InputField = observer<IInputFieldProps>(function InputField({
  htmlId,
  onChange,
  onBlur,
  onClick,
  inputGroupProps,
  tooltipProps,
  field,
  showMessage = true,
  enableStatus = true,
  forwardedRef,
  ...props
}) {
  const Body = (
    <Input
      onChange={handleChange(field, onChange)}
      onBlur={handleTouched(field, onBlur)}
      onClick={onClick}
      htmlId={htmlId}
      value={field.value || ''}
      forwardedRef={forwardedRef}
      status={
        enableStatus && getError(field) ? E_VALIDATION_STATUS.error : undefined
      }
      {...props}
    />
  );

  const Content =
    tooltipProps === undefined ? (
      Body
    ) : (
      <TooltipForInput {...tooltipProps}>{Body}</TooltipForInput>
    );

  return (
    <InputGroup
      showMessage={showMessage}
      htmlFor={htmlId}
      error={getError(field)}
      {...inputGroupProps}
    >
      {Content}
    </InputGroup>
  );
});
