import { createIcon } from '@chakra-ui/icons';

export const InfoSquareIcon = createIcon({
  displayName: 'InfoSquareIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    role: 'info-icon',
  },
  path: (
    <>
      <rect width="16" height="16" rx="3" fill="black" fillOpacity="0.06" />
      <path
        d="M8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4Z"
        fill="#7C7C7C"
      />
      <path d="M9 12V7H7V12H9Z" fill="#7C7C7C" />
    </>
  ),
});
