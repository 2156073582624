import get from 'lodash/get';

import { BannerQueryResult } from '../graphql/types';

export const getAnalyticsBannerData = (data: BannerQueryResult['data']) => {
  const companyName =
    get(data?.banner, 'offeringClosingFirst.companyName') ??
    get(data?.banner, 'investment.offering.companyName');
  const offeringSlug =
    get(data?.banner, 'offeringClosingFirst.slug') ??
    get(data?.banner, 'investment.offering.slug');
  const waitlist = get(data?.banner, 'waitlist');
  const daysLeft =
    get(data?.banner, 'timeLeft') ?? get(data?.banner, 'investment.timeLeft');
  return {
    banner_name: data?.banner?.__typename,
    extra_data: { companyName, waitlist, offeringSlug, daysLeft },
  };
};
