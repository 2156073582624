import { IBannerTypeEnum } from '@r-client/data/graphql-types';

const supportedBannerTypes = new Set(Object.values(IBannerTypeEnum));
const isValidBannerType = (bannerType: IBannerTypeEnum) => {
  return supportedBannerTypes.has(bannerType);
};

export class DismissedBannersResource {
  storage: Storage | undefined;

  constructor(storage: Storage | undefined) {
    this.storage = storage;
  }

  getAll = () => {
    try {
      const bannersString = this.storage?.getItem('dismissedBanners') || '[]';
      const parsed = JSON.parse(bannersString);
      if (!Array.isArray(parsed)) return [];
      return (parsed as IBannerTypeEnum[]).filter(isValidBannerType);
    } catch {
      return [];
    }
  };

  add = (bannerType: IBannerTypeEnum) => {
    const banners = this.getAll();
    if (banners.includes(bannerType)) return;

    banners.push(bannerType);
    this.setBanners(banners);
  };

  remove = (bannerType: IBannerTypeEnum) => {
    const banners = this.getAll().filter((banner) => banner !== bannerType);
    this.setBanners(banners);
  };

  private setBanners = (bannerTypes: IBannerTypeEnum[]) => {
    this.storage?.setItem('dismissedBanners', JSON.stringify(bannerTypes));
  };
}
