import React from 'react';

import { Box } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { useAnalytics } from '@r-client/shared/data/analytics';
import { useAuth } from '@r-client/shared/feature/auth';
import { useHasWallet } from '@r-client/shared/feature/wallet';
import { Link } from '@r-client/shared/ui/core';
import { Dropdown } from '@r-client/shared/ui/dropdown';
import { BasicMenu, BasicMenuItem } from '@r-client/shared/ui/menu';

import { EditableEntities } from '../../editable-entities/editable-entities';
import { userNavItems } from '../../navigation-data';
import { TEditableEntities } from '../../types';
import {
  getUserTeasers,
  teasersToEditableEntities,
} from '../../utilities/teasers-to-editable-entities';

import styles from './user.module.scss';

export interface IUserDropdownProps {
  isFounder?: boolean;
  userSlug: string;
  triggerElement: React.ReactElement;
  editableOfferings?: TEditableEntities;
}

export const UserDropdown: React.FC<
  React.PropsWithChildren<IUserDropdownProps>
> = observer(function UserDropdown({
  userSlug,
  editableOfferings,
  triggerElement,
  isFounder,
}) {
  const { viewer, signOut } = useAuth();
  const teasers = teasersToEditableEntities(getUserTeasers(viewer));
  const { hasWallet } = useHasWallet();
  const analytics = useAnalytics();

  const handleLogout = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    return signOut({ redirectTo: '/logout' });
  };

  return (
    <Dropdown
      arrow
      placement="bottom-end"
      trigger="hover"
      content={
        <div className={styles.menuWrapper}>
          <BasicMenu>
            <EditableEntities offerings={editableOfferings} teasers={teasers} />
            {userNavItems({
              userSlug,
              isFounder,
              mobile: false,
              hasWallet,
            })
              .filter(Boolean)
              .map((item) => {
                return (
                  <BasicMenuItem key={item?.href}>
                    <Link
                      className={styles.listItem}
                      variant={Link.variants.unstyled}
                      href={item?.href}
                      onClick={() => {
                        analytics.track({
                          name: 'user_menu_clicked',
                          params: {
                            label: `user_menu_${item?.title
                              .toLowerCase()
                              .replace(' ', '_')}`,
                            ...item?.trackingParams,
                          },
                        });

                        return item?.href === '/logout'
                          ? handleLogout
                          : undefined;
                      }}
                    >
                      <Box minW="100%">{item?.title}</Box>
                    </Link>
                  </BasicMenuItem>
                );
              })}
          </BasicMenu>
        </div>
      }
    >
      <div>{triggerElement}</div>
    </Dropdown>
  );
});
