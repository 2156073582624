import classNames from 'clsx';

import { IBoxProps } from '../box';
import { Img } from '../img';
import { ReactComponent as IssuerDefault } from './images/issuer-default.svg';
import { ReactComponent as AdminBadge } from './images/r-logo.svg';
import { ReactComponent as UserDefault } from './images/user-default.svg';

import styles from './avatar.module.scss';

type TSize =
  | 'large'
  | 'medium'
  | 'small'
  | 'xSmall'
  | 'xxSmall'
  | 'offeringCard';

type TAvatarType = 'user' | 'issuer';

function defaultAvatarRender(type: TAvatarType) {
  switch (type) {
    case 'issuer':
      return <IssuerDefault />;
    case 'user':
    default:
      return <UserDefault />;
  }
}

// "avatarType" and badge: "admin" are both Republic App concerns.
// We should consider wrapping this component with a RepublicAvatar
// which implements those props instead of this agnostic UI component.
export interface IAvatarProps {
  title?: string;
  url?: string;
  url2x?: string;
  alt?: string;
  border?: boolean;
  borderRadius?: IBoxProps['borderRadius'];
  className?: string;
  round?: boolean;
  outline?: boolean;
  size?: TSize;
  mobileSize?: TSize;
  avatarType?: TAvatarType;
  badge?:
    | 'admin'
    | React.FC<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>;
}

export const Avatar = ({
  alt,
  border,
  className,
  round,
  size,
  title,
  url,
  url2x,
  mobileSize,
  borderRadius,
  avatarType = 'user',
  badge,
  outline = true,
}: IAvatarProps) => {
  const Badge = badge;
  return (
    <div
      data-testid="avatar"
      className={classNames(
        className,
        styles.avatar,
        border && styles.border,
        size && styles[size],
        mobileSize && styles[`${mobileSize}Mobile`]
      )}
    >
      <div className={classNames(styles.container, round && styles.round)}>
        {url ? (
          <Img
            className={classNames(styles.avatarImg, {
              [styles.outline]: outline,
            })}
            title={title}
            src={url}
            src2x={url2x}
            alt={alt}
            borderRadius={borderRadius}
          />
        ) : (
          defaultAvatarRender(avatarType)
        )}
      </div>
      {!!Badge && (
        <div className={styles.badge}>
          {Badge === 'admin' && <AdminBadge />}
          {Badge !== 'admin' && <Badge />}
        </div>
      )}
    </div>
  );
};

Avatar.defaultProps = {
  border: false,
  round: false,
};
