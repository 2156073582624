import { Icon, IconProps } from '@chakra-ui/icons';

export const GlobeIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 22C6.516 22 2 17.484 2 12 2 6.525 6.516 2 11.99 2 17.475 2 22 6.525 22 12c0 5.484-4.516 10-10 10M6.209 6.104c.46.316 1.026.584 1.687.795.441-1.179 1.017-2.147 1.707-2.828A8.45 8.45 0 0 0 6.21 6.104m8.197-2.023c.681.68 1.266 1.649 1.697 2.818.662-.21 1.228-.47 1.688-.786-.94-.93-2.1-1.63-3.385-2.032m-1.706.393v2.972q1.067-.043 2.004-.202c-.518-1.37-1.228-2.387-2.004-2.77m-3.404 2.77c.623.106 1.295.173 2.004.202V4.474c-.777.383-1.486 1.4-2.004 2.77M3.745 11.3h3.327c.048-1.103.192-2.138.422-3.078-.873-.278-1.62-.642-2.186-1.073A8.2 8.2 0 0 0 3.745 11.3m13.183 0h3.327a8.2 8.2 0 0 0-1.563-4.142c-.565.422-1.313.786-2.186 1.064.23.94.374 1.976.422 3.078m-8.428 0h2.8V8.855a17 17 0 0 1-2.406-.278c-.211.844-.346 1.764-.394 2.723m4.2 0h2.8a14.2 14.2 0 0 0-.394-2.723c-.747.144-1.562.24-2.406.278zm-8.955 1.4a8.26 8.26 0 0 0 1.582 4.18c.566-.422 1.304-.776 2.176-1.054a15 15 0 0 1-.431-3.126zm4.755 0c.048.968.183 1.908.394 2.77.757-.143 1.562-.239 2.406-.277V12.7zm4.2 2.493c.844.038 1.649.134 2.406.278.211-.863.346-1.803.393-2.771h-2.8zm3.797.633c.872.277 1.61.632 2.176 1.054a8.26 8.26 0 0 0 1.582-4.18h-3.327a15 15 0 0 1-.431 3.126m-7.182.977c.509 1.343 1.218 2.35 1.985 2.742v-2.953c-.71.03-1.371.106-1.985.212m3.385-.21v2.953c.767-.394 1.476-1.4 1.985-2.742a15 15 0 0 0-1.985-.211m3.394.546c-.432 1.16-1.007 2.11-1.678 2.78a8.3 8.3 0 0 0 3.356-2.003c-.46-.307-1.026-.566-1.678-.777m-9.856.777c.93.92 2.08 1.61 3.346 2.003-.671-.67-1.247-1.62-1.678-2.78-.652.211-1.218.47-1.668.777"
    />
  </Icon>
);
