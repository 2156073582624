import { ReactNode, useEffect, useRef } from 'react';

import ReactDOM from 'react-dom';

export interface IOverlayProps {
  children: ReactNode;
}

export function OverlayPortal({ children }: IOverlayProps) {
  const node = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!node.current) {
      node.current = document.createElement('div');
      document.body.appendChild(node.current);
    }

    return () => {
      if (node.current) {
        document.body.removeChild(node.current);
      }

      node.current = null;
    };
  }, []);

  if (node.current) {
    return ReactDOM.createPortal(children, node.current);
  }

  return null;
}
