import { createIcon } from '@chakra-ui/react';

export const NotificationsIcon = createIcon({
  displayName: 'NotificationsIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M19.8777 9.60009C19.7946 9.43518 19.6674 9.29649 19.5103 9.19942C19.3532 9.10234 19.1723 9.05065 18.9877 9.05009H13.9877V3.05009C13.9984 2.83078 13.9366 2.61402 13.8119 2.43329C13.6872 2.25256 13.5065 2.1179 13.2977 2.05009C13.0969 1.98404 12.8803 1.98329 12.6791 2.04797C12.4779 2.11265 12.3023 2.23942 12.1777 2.41009L4.17765 13.4101C4.07742 13.555 4.01723 13.7237 4.00319 13.8993C3.98914 14.0749 4.02173 14.2511 4.09765 14.4101C4.16758 14.5918 4.28904 14.7492 4.44714 14.8629C4.60525 14.9766 4.7931 15.0417 4.98765 15.0501H9.98765V21.0501C9.98781 21.261 10.0546 21.4664 10.1785 21.637C10.3025 21.8076 10.4772 21.9347 10.6777 22.0001C10.7781 22.0312 10.8825 22.0481 10.9877 22.0501C11.1454 22.0505 11.3011 22.0136 11.4419 21.9423C11.5826 21.8711 11.7046 21.7675 11.7977 21.6401L19.7977 10.6401C19.9054 10.4909 19.9699 10.3148 19.984 10.1313C19.9981 9.94785 19.9613 9.76402 19.8777 9.60009ZM11.9877 17.9701V14.0501C11.9877 13.7849 11.8823 13.5305 11.6948 13.343C11.5072 13.1554 11.2529 13.0501 10.9877 13.0501H6.98765L11.9877 6.13009V10.0501C11.9877 10.3153 12.093 10.5697 12.2805 10.7572C12.4681 10.9447 12.7224 11.0501 12.9877 11.0501H16.9877L11.9877 17.9701Z"
      fill="currentColor"
    />
  ),
});
