/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseQueryModel,
  IQueryModelOpts,
  useQuery,
  useGqlClient,
} from '@r-client/shared/data/client';
import {
  IGlobalSearchQuery,
  IGlobalSearchQueryVariables,
  GlobalSearchDocument,
} from './types';
import { IGlobalSearchBase } from '@r-client/data/graphql-types';
export type { IGlobalSearchBase };

export type IGlobalSearchQueryOpts =
  IQueryModelOpts<IGlobalSearchQueryVariables>;

export class GlobalSearchQuery extends BaseQueryModel<
  IGlobalSearchQuery,
  IGlobalSearchQueryVariables
> {
  // This is fine. Here we explicitly change interface for query
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(opts: IGlobalSearchQueryOpts) {
    super(opts);
  }

  get query() {
    return GlobalSearchDocument;
  }
}
export function useGlobalSearchQuery(
  opts?: Omit<IGlobalSearchQueryOpts, 'client'>
) {
  const client = useGqlClient();
  return useQuery(
    () => new GlobalSearchQuery({ ...opts, client }),
    opts?.variables
  );
}
