import { useCallback, useEffect, useRef } from 'react';

import cn from 'clsx';

import { OverlayPortal } from './overlay-portal';

import styles from './overlay.module.scss';

export interface IOverlayProps {
  isActive: boolean;
  onClose: (value: boolean) => void;
}

enum E_KEY_CODES {
  ESCAPE = 'Escape',
}

function lockScroll(state: boolean) {
  if (typeof document === 'undefined') {
    return;
  }

  document.body.style.overflow = state ? 'hidden' : '';
}

export function Overlay({ isActive, onClose }: IOverlayProps) {
  const ref = useRef<HTMLDivElement | null>(null);

  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === E_KEY_CODES.ESCAPE && isActive) {
        onClose(false);
      }
    },
    [isActive, onClose]
  );

  const handleOutsideMouseClick = useCallback(
    (e: MouseEvent) => {
      if (!isActive) {
        return;
      }

      if (
        !ref.current ||
        ref.current !== e.target ||
        (e.button && e.button !== 0)
      ) {
        return;
      }

      onClose(false);
    },
    [isActive, onClose]
  );

  useEffect(() => {
    document.documentElement.addEventListener('keydown', handleKeydown);
    document.addEventListener('click', handleOutsideMouseClick);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
      document.documentElement.removeEventListener(
        'click',
        handleOutsideMouseClick
      );
    };
  }, [handleKeydown, handleOutsideMouseClick]);

  if (isActive) {
    lockScroll(true);
  } else {
    lockScroll(false);
  }

  return (
    <OverlayPortal>
      <div
        ref={ref}
        className={cn(styles.overlay, {
          [styles.isActive]: isActive,
        })}
      />
    </OverlayPortal>
  );
}
