/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import * as Types from '@r-client/data/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IBannerQueryVariables = Types.Exact<{
  path: Types.Scalars['String']['input'];
  dismissedBanners?: Types.InputMaybe<
    Array<Types.IBannerTypeEnum> | Types.IBannerTypeEnum
  >;
}>;

export type IBannerQuery = {
  __typename?: 'Query';
  banner?:
    | {
        __typename: 'ActionRequiredBanner';
        investment?:
          | {
              __typename?: 'InvestmentActionRequired';
              id: string;
              state: Types.IInvestmentStateEnum;
              investmentType: Types.IInvestmentTypeEnum;
              offering: {
                __typename?: 'Offering';
                id: string;
                logoUrl?: string | null | undefined;
                slug: string;
                type?: string | null | undefined;
                companyName: string;
              };
            }
          | null
          | undefined;
      }
    | { __typename: 'AvaWatzBanner' }
    | {
        __typename: 'ClosingSoonBanner';
        timeLeft: number;
        offeringClosingFirst: {
          __typename?: 'Offering';
          id: string;
          slug: string;
          companyName: string;
        };
        offerings: Array<{
          __typename?: 'Offering';
          id: string;
          logoUrl?: string | null | undefined;
          slug: string;
          companyName: string;
        }>;
      }
    | {
        __typename: 'ConfirmEmailBanner';
        user: {
          __typename?: 'User';
          id: string;
          unconfirmedEmail?: string | null | undefined;
          shouldPromptToResendConfirmationEmail?: boolean | null | undefined;
        };
      }
    | { __typename: 'FilmBanner' }
    | {
        __typename: 'IdentityVerificationBanner';
        user: {
          __typename?: 'User';
          id: string;
          email?: string | null | undefined;
          investorProfile?:
            | {
                __typename?: 'InvestorProfile';
                id: string;
                cognitoFlowCustomerReference?: string | null | undefined;
              }
            | null
            | undefined;
        };
      }
    | { __typename: 'ModeMobileBanner' }
    | {
        __typename: 'NoteDistributionBanner';
        user: { __typename?: 'User'; id: string };
      }
    | { __typename: 'NoteLaunchBanner' }
    | { __typename: 'NoteRewardClaimBanner'; timeLeft: number }
    | { __typename: 'PrivacyUpdateBanner' }
    | { __typename: 'RepublicWalletLaunchBanner' }
    | { __typename: 'SpaceWeekBanner' }
    | {
        __typename: 'StateChangeConfirmationBanner';
        waitlist: boolean;
        investment?:
          | {
              __typename?: 'Investment';
              id: string;
              timeLeft?: number | null | undefined;
              offering: {
                __typename?: 'Offering';
                id: string;
                logoUrl?: string | null | undefined;
                slug: string;
                type?: string | null | undefined;
                companyName: string;
              };
            }
          | null
          | undefined;
      }
    | { __typename: 'WatfordBanner' }
    | null
    | undefined;
};

export type IUserEmailConfirmationResendMutationVariables = Types.Exact<{
  clientMutationId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type IUserEmailConfirmationResendMutation = {
  __typename?: 'Mutation';
  userEmailConfirmationResend?:
    | {
        __typename?: 'UserEmailConfirmationResendPayload';
        user?: { __typename?: 'User'; id: string } | null | undefined;
        errors?:
          | Array<{ __typename?: 'ValidationError'; message: string }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const BannerDocument = gql`
  query Banner($path: String!, $dismissedBanners: [BannerTypeEnum!]) {
    banner(path: $path, dismissedBanners: $dismissedBanners) {
      __typename
      ... on NoteDistributionBanner {
        user {
          id
        }
      }
      ... on NoteRewardClaimBanner {
        timeLeft
      }
      ... on StateChangeConfirmationBanner {
        waitlist
        investment {
          id
          timeLeft
          offering {
            id
            logoUrl
            slug
            type
            companyName
          }
        }
      }
      ... on ConfirmEmailBanner {
        user {
          id
          unconfirmedEmail
          shouldPromptToResendConfirmationEmail
        }
      }
      ... on ActionRequiredBanner {
        investment {
          id
          state
          investmentType
          offering {
            id
            logoUrl
            slug
            type
            companyName
          }
        }
      }
      ... on IdentityVerificationBanner {
        user {
          id
          email
          investorProfile {
            id
            cognitoFlowCustomerReference
          }
        }
      }
      ... on ClosingSoonBanner {
        offeringClosingFirst {
          id
          slug
          companyName
        }
        timeLeft
        offerings {
          id
          logoUrl
          slug
          companyName
        }
      }
    }
  }
`;
export type BannerQueryResult = Apollo.QueryResult<
  IBannerQuery,
  IBannerQueryVariables
>;
export const UserEmailConfirmationResendDocument = gql`
  mutation UserEmailConfirmationResend($clientMutationId: String) {
    userEmailConfirmationResend(
      input: { clientMutationId: $clientMutationId }
    ) {
      user {
        id
      }
      errors {
        message
      }
    }
  }
`;
export type IUserEmailConfirmationResendMutationFn = Apollo.MutationFunction<
  IUserEmailConfirmationResendMutation,
  IUserEmailConfirmationResendMutationVariables
>;
export type UserEmailConfirmationResendMutationResult =
  Apollo.MutationResult<IUserEmailConfirmationResendMutation>;
export type UserEmailConfirmationResendMutationOptions =
  Apollo.BaseMutationOptions<
    IUserEmailConfirmationResendMutation,
    IUserEmailConfirmationResendMutationVariables
  >;
