import { format, parseISO } from 'date-fns';

export type TUtcTime = 'hourMin' | 'hourMinSec' | 'dateTime';
export const DATE_FORMAT = {
  short: 'MM/dd/yy',
  shortWithYearsFull: 'MM/dd/yyyy',
  medium: 'MMMM d, yyyy',
  mediumTwoDigitsDay: 'MMM dd, yyyy',
  messageReceivedToday: 'hh:mm a',
  messageReceivedEarlier: "MMM dd yyyy 'at' hh:mm a",
  numericDateCommaTime: 'MM/dd/yyyy, hh:mm aaa',
  dateDotTimeWithMonthAbbreviation: 'MMM d, yyyy · hh:mm z',
  dateDotTimeWithMonthAbbreviationAndDay: 'EEEE, MMM d, yyyy · hh:mm z',
};

export const fromISOToShortDate = (isoDate: string): string => {
  if (isoDate === '') {
    return '';
  }

  const result = isoDate.split('T')[0];

  if (parseISO(result).toString() === 'Invalid Date') {
    throw new Error('Invalid time value');
  }

  return result;
};

export const fromShortDateToISO = (shortDate: string): string => {
  if (shortDate === '') {
    return '';
  }
  return new Date(shortDate).toISOString();
};

export const formatDate = (
  date?: number | Date,
  template = DATE_FORMAT.short
) => {
  if (date === undefined) {
    return '';
  }

  if (typeof date === 'number') {
    const time = new Date(date * 1000);

    return format(time, template);
  }

  return format(date, template);
};
