'use client';

import { useCallback, useEffect, useRef, useState } from 'react';

import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';

import {
  Autosuggest,
  AutosuggestItemData,
  handleSendSuggestionItemsAnalyticsEvent,
  Overlay,
  PopularSuggestions,
  Search,
  SearchIconButton,
  useGlobalSearchQuery,
} from '@r-client/republic/feature/search';
import { useAnalytics } from '@r-client/shared/data/analytics';
import { useAuth } from '@r-client/shared/feature/auth';
import { Box } from '@r-client/shared/ui/core';
import {
  isRepublicEmail,
  useDebounce,
  useMediaUpLg,
} from '@r-client/shared/util/core';
import { FieldModel } from '@r-client/shared/util/model';

import { getPopperConfig } from './get-popper-config';

import styles from './global-search.module.scss';

export const GlobalSearch = observer(function GlobalSearch() {
  const fieldRef = useRef(new FieldModel<string>());
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isUnmounted, setIsUnmounted] = useState<boolean>(false);
  const [autosuggestItemClicked, setAutosuggestItemClicked] =
    useState<AutosuggestItemData | null>(null);
  const field = fieldRef.current;
  const debouncedValue = useDebounce<string>(field.value || '', 500);
  const analytics = useAnalytics();
  const isDesktop = useMediaUpLg();
  const { viewer } = useAuth();

  const { data, isRejected, isLoading } = useGlobalSearchQuery({
    variables: { q: debouncedValue },
    notifyOnNetworkStatusChange: true,
  });
  const globalSearch = data?.globalSearch;
  const isPopular = globalSearch?.isPopular;

  const showPopular = isPopular || (isLoading && !debouncedValue);
  const showAutosuggest = !showPopular && !isLoading;

  const popperConfig = useCallback(
    () => getPopperConfig(isDesktop),
    [isDesktop]
  );

  // TODO: refactor global search animations https://linear.app/republic/issue/INV-140/review-global-search-behaviour
  // What we have here currently, is too complex and hard to maintain
  const tabletOffset = 180;
  const { getTooltipProps, setTriggerRef, setTooltipRef, visible } =
    usePopperTooltip(
      {
        visible: isVisible,
        trigger: 'focus',
        offset: [isDesktop ? 100 : tabletOffset, -40],
      },
      { ...popperConfig }
    );

  useEffect(() => {
    if (!isLoading && analytics && data && field.value !== undefined) {
      analytics.track({
        name: 'global_search_submitted',
        params: {
          is_employee: isRepublicEmail(viewer?.info?.email),
          platform: 'web',
          search_term: field.value,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, analytics, isLoading]);

  useEffect(() => {
    // TODO: https://linear.app/republic/issue/INV-684/global-search-dropdown-events-not-tracked
    if (autosuggestItemClicked) {
      handleSendSuggestionItemsAnalyticsEvent(
        autosuggestItemClicked,
        analytics,
        globalSearch,
        field.value
      );
    }
  }, [autosuggestItemClicked, analytics, field.value, globalSearch, viewer?.info?.id]);

  function handleClose() {
    setIsUnmounted(true);
  }

  function handleSearchClick() {
    setIsVisible(true);
  }

  const handleAnimationEnd = useCallback(() => {
    if (isUnmounted) {
      setIsVisible(false);
      setIsUnmounted(false);
    }
  }, [isUnmounted]);

  if (!viewer) {
    return null;
  }

  return (
    <div className={styles.searchWrapper} ref={setTriggerRef}>
      {isDesktop ? (
        <Search
          data-testid="search-bar"
          onClick={handleSearchClick}
          classNames={{
            search: styles.fakeSearch,
          }}
          isLoading={visible && isLoading}
          isActive={visible}
          field={field}
          autoFocus={false}
        />
      ) : (
        <SearchIconButton
          data-testid="search-icon-button"
          onClick={handleSearchClick}
          isLoading={visible && isLoading}
          mx={2}
        />
      )}

      {visible &&
        createPortal(
          <div
            {...getTooltipProps({
              ref: setTooltipRef,
            })}
            className={styles.dropdown}
            data-popper-escaped={true}
          >
            <Search
              classNames={{
                search: styles.search,
                inputField: cn(
                  isDesktop
                    ? styles.inputFieldDesktop
                    : styles.inputFieldNoDashboard,
                  {
                    [styles.isFocused]: visible,
                    [styles.isUnmounted]: isUnmounted,
                  }
                ),
                addon: cn(!isDesktop ? styles.addonNoDashboard : undefined, {
                  [styles.isFocused]: visible,
                  [styles.isUnmounted]: isUnmounted,
                }),
              }}
              isLoading={visible && isLoading}
              isActive={visible}
              field={field}
              autoFocus
            />
            {!isRejected && (
              <Box>
                {showPopular && (
                  <PopularSuggestions
                    {...globalSearch}
                    isNoResult={!!debouncedValue}
                    className={cn(styles.popularSuggestions, {
                      [styles.isUnmounted]: isUnmounted,
                    })}
                    onAnimationEnd={handleAnimationEnd}
                  />
                )}
                {showAutosuggest && (
                  <Autosuggest
                    setAutosuggestItemClicked={setAutosuggestItemClicked}
                    className={cn(styles.autosuggest, {
                      [styles.isUnmounted]: isUnmounted,
                    })}
                    onAnimationEnd={handleAnimationEnd}
                    {...globalSearch}
                  />
                )}
              </Box>
            )}
          </div>,
          document.body
        )}
      <Overlay isActive={visible} onClose={handleClose} />
    </div>
  );
});
