import { FunctionComponent } from 'react';

import CL from 'clsx';

import {
  createStringGetter,
  getClassName,
  TMedia,
  TSizes,
} from '@r-client/shared/util/core';

import styles from './divider.module.scss';

export type TSize = 'normal' | 'small' | 'extra-small';
export type TMargin =
  | '0'
  | '0.5'
  | '1'
  | '1.5'
  | '2'
  | '2.5'
  | '3'
  | '3.5'
  | '4';
export type TComplexMarginProp = {
  top: TMedia<TMargin>;
  bottom: TMedia<TMargin>;
};

const getMarginStyle = (
  name: TMargin,
  size: TSizes,
  pos?: 'top' | 'bottom'
) => {
  const key = getClassName(
    `margin${pos ? `-${pos}` : ''}-${name.replace('.', '_')}` as const,
    size
  );
  return styles[key];
};

const getters = {
  getMargin: createStringGetter<TMargin>((name, size) => {
    return getMarginStyle(name, size);
  }),
  getMarginTop: createStringGetter<TMargin>((name, size) => {
    return getMarginStyle(name, size, 'top');
  }),
  getMarginBottom: createStringGetter<TMargin>((name, size) => {
    return getMarginStyle(name, size, 'bottom');
  }),
  getSize: createStringGetter<TSize>((name, size) => {
    const key = getClassName(`size-${name}` as const, size);
    return styles[key];
  }),
};

export interface IDividerProps {
  className?: string;
  hint?: string;
  size?: TMedia<TSize>;
  left?: boolean;
  color?: 'default' | 'gray-e0' | 'gray20' | 'gray-6';
  margin?: TMedia<TMargin | TComplexMarginProp>;
  children?: never;
}

/** @deprecated
 * Please use Chakra UI's Divider component instead.
 */
export const Divider: FunctionComponent<
  React.PropsWithChildren<IDividerProps>
> = function Divider({
  className,
  hint,
  size = 'normal',
  left,
  color = 'default',
  margin = '1',
}) {
  const marginClasses =
    typeof margin === 'string'
      ? [getters.getMargin(margin)]
      : [
          getters.getMarginTop((margin as TComplexMarginProp).top),
          getters.getMarginBottom((margin as TComplexMarginProp).bottom),
        ];
  return (
    <hr
      className={CL(
        styles.divider,
        className,
        {
          [styles[`color-${color}`]]: color,
          [styles['align-left']]: left,
        },
        getters.getSize(size),
        ...marginClasses
      )}
      data-hint={hint}
    />
  );
};
