import { createIcon } from '@chakra-ui/react';

export const ChevronDownIcon = createIcon({
  displayName: 'ChevronDownIcon',
  viewBox: '0 0 18 18',
  defaultProps: {
    fill: 'none',
    stroke: '#959595',
  },
  path: (
    <path
      d="M15.5 6L9 12.5L2.5 6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
