import React, { FunctionComponent } from 'react';

import { ITooltipProps, Tooltip } from '@r-client/shared/ui/core';
import { useMediaUpMd } from '@r-client/shared/util/core';

type TTooltipProps = Partial<Pick<ITooltipProps, 'content'>>;

export type ITooltipForInputProps = Omit<ITooltipProps, 'content'> &
  TTooltipProps;

export const TooltipForInput: FunctionComponent<
  React.PropsWithChildren<ITooltipForInputProps>
> = ({
  placement,
  content,
  trigger = 'focus',
  closeOnOutsideClick = false,
  ...props
}) => {
  const isMediaAtLeastMd = useMediaUpMd();
  const getPlacement = () => {
    if (isMediaAtLeastMd) {
      return placement;
    }
    // for smaller screens
    return placement === 'left' ? 'bottom' : 'top';
  };

  return (
    <Tooltip
      {...props}
      content={content!}
      trigger={trigger}
      width="big"
      closeOnOutsideClick={closeOnOutsideClick}
      placement={getPlacement()}
    />
  );
};

TooltipForInput.defaultProps = {
  content: '',
  interactive: false,
  placement: 'right',
  theme: 'dark',
};
