import { IBannerTypeEnum } from '@r-client/data/graphql-types';
import { BaseQueryModel } from '@r-client/shared/data/client';
import { Box } from '@r-client/shared/ui/core';

import { BannerQueryResult, IBannerQuery } from '../../graphql/types';
import { ActionRequiredBanner } from '../action-required-banner/action-required-banner';
import { AvaWatzBanner } from '../avawatz-banner/avawatz-banner';
import { ClosingSoonBanner } from '../closing-soon-banner/closing-soon-banner';
import { ConfirmEmailBanner } from '../confirm-email-banner/confirm-email-banner';
import { FilmBanner } from '../film-banner/film-banner';
import { IdentityVerificationBanner } from '../identity-verification-banner/identity-verification-banner';
import { ModeMobileBanner } from '../mode-mobile-banner/mode-mobile-banner';
import { NoteLaunchBanner } from '../note-launch-banner/note-launch-banner';
import { SpaceWeekBanner } from '../space-week-banner/space-week-banner';
import { StateChangeConfirmationBanner } from '../state-change-confirmation-banner/state-change-confirmation-banner';
import { WalletLaunchBanner } from '../wallet-launch-banner/wallet-launch-banner';
import { WatfordBanner } from '../watford-banner/watford-banner';

type TDismissUserBannerHandler = (bannerType: IBannerTypeEnum) => Promise<void>;
export interface IBannerDisplayerProps {
  data: BannerQueryResult['data'];
  onDismissUserBanner: TDismissUserBannerHandler;
  refetch: BaseQueryModel<IBannerQuery>['refetch'];
}

function bannerSwitcher(
  banner: IBannerQuery['banner'],
  onDismissUserBanner: TDismissUserBannerHandler,
  refetch: BaseQueryModel<IBannerQuery>['refetch']
) {
  switch (banner?.__typename) {
    case 'StateChangeConfirmationBanner':
      return (
        <StateChangeConfirmationBanner
          waitlist
          companyName="Lambs"
          offeringSlug="lambs"
          daysLeft={348}
        />
      );
    case 'ConfirmEmailBanner':
      return (
        <ConfirmEmailBanner
          shouldPromptToResendConfirmationEmail={
            banner.user.shouldPromptToResendConfirmationEmail
          }
          unconfirmedEmail={banner.user.unconfirmedEmail}
          refetch={refetch}
        />
      );
    case 'ActionRequiredBanner':
      return <ActionRequiredBanner investment={banner.investment} />;
    case 'ClosingSoonBanner':
      return (
        <ClosingSoonBanner
          offeringClosingFirst={banner.offeringClosingFirst.id}
          offerings={banner.offerings}
          timeLeftInSec={banner.timeLeft}
          onClose={onDismissUserBanner}
        />
      );
    case 'IdentityVerificationBanner':
      return <IdentityVerificationBanner />;
    case 'FilmBanner':
      return <FilmBanner />;
    case 'SpaceWeekBanner':
      return <SpaceWeekBanner />;
    case 'ModeMobileBanner':
      return <ModeMobileBanner />;
    case 'AvaWatzBanner':
      return <AvaWatzBanner />;
    case 'WatfordBanner':
      return <WatfordBanner />;
    case 'NoteLaunchBanner':
      return <NoteLaunchBanner />;
    case 'RepublicWalletLaunchBanner':
      return <WalletLaunchBanner />;
    case 'NoteDistributionBanner':
    case 'NoteRewardClaimBanner':
    default:
      return (
        <Box bg="light-blue" p="1brm">
          <h1>{banner?.__typename}</h1>
        </Box>
      );
  }
}

export const BannerDisplayer = ({
  data,
  onDismissUserBanner,
  refetch,
}: IBannerDisplayerProps) => {
  return <>{bannerSwitcher(data?.banner, onDismissUserBanner, refetch)}</>;
};
