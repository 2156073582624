import React from 'react';

import CN from 'clsx';
import { TriggerType, usePopperTooltip } from 'react-popper-tooltip';
import { CSSTransition } from 'react-transition-group';

import styles from './dropdown.module.scss';

export interface IDropdownProps {
  visible?: boolean;
  onVisibleChange?: (value: boolean) => void;
  trigger: TriggerType;
  delayHide?: number;
  placement?: 'bottom' | 'bottom-start' | 'bottom-end';
  content?: React.ReactElement | React.ReactNode;
  interactive?: boolean;
  arrow?: boolean;
  underModal?: boolean;
  offset?: [number, number];
  children: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | React.ReactElement<React.PropsWithRef<any>>
    | React.ReactHTMLElement<HTMLElement>;
}

const ANIMATION_TIMEOUT = 200;
const ANIMATION_CLASSES = {
  enter: styles.overlayEnter,
  enterActive: styles.overlayEnterActive,
  exit: styles.overlayExit,
  exitActive: styles.overlayExitActive,
};

const popperConfig = {
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['bottom', 'bottom-start', 'bottom-end'],
      },
    },
    {
      name: 'offset',
      options: {
        offset: [0, 12],
      },
    },
  ],
};

export function Dropdown({
  visible: visibleProp,
  onVisibleChange,
  trigger,
  interactive = true,
  placement = 'bottom',
  delayHide = 100,
  children,
  content,
  arrow,
  underModal,
  offset,
}: IDropdownProps) {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(
    {
      trigger,
      placement,
      delayHide,
      interactive,
      visible: visibleProp,
      onVisibleChange,
      offset,
    },
    {
      ...popperConfig,
      modifiers: [
        ...popperConfig.modifiers,
        {
          name: 'offset',
          options: {
            offset: offset || [0, 12],
          },
        },
      ],
    }
  );

  return (
    <>
      {React.cloneElement(children, { ref: setTriggerRef })}

      <div
        {...getTooltipProps({
          ref: setTooltipRef,
          className: CN(styles.overlay, {
            [styles.overlayUnderModal]: underModal,
          }),
        })}
      >
        <CSSTransition
          in={visible}
          timeout={ANIMATION_TIMEOUT}
          unmountOnExit
          classNames={ANIMATION_CLASSES}
        >
          <div className={styles.overlayContent}>{content}</div>
        </CSSTransition>

        {arrow && (
          <div {...getArrowProps({ className: styles.arrow })}>
            <CSSTransition
              in={visible}
              timeout={ANIMATION_TIMEOUT}
              unmountOnExit
              classNames={ANIMATION_CLASSES}
            >
              <div className={styles.arrowContent} />
            </CSSTransition>
          </div>
        )}
      </div>
    </>
  );
}
