import { useEffect, useState } from 'react';

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const calculateDateRender = (timeLeftInSec: number) => {
  let result = '';

  // Uncertain if we should render anything here when timer runs out. Should we force a refresh or query so the banner updates to the next closing deal?
  if (timeLeftInSec <= 0) return 'DEAL CLOSED';

  const days = Math.floor(timeLeftInSec / DAY);
  const hours = Math.floor((timeLeftInSec % DAY) / HOUR);
  const minutes = Math.floor((timeLeftInSec % HOUR) / MINUTE);
  const seconds = timeLeftInSec % 60;

  if (days >= 1) result += `${days}d `;
  if (hours >= 0) result += `${hours}h `;
  if (minutes >= 0) result += `${minutes}m `;

  result += `${seconds}s`;
  return result;
};

export interface ICountdownProps {
  timeLeftInSec: number;
}

export function Countdown({ timeLeftInSec }: ICountdownProps) {
  const [timeLeftCount, setTimeLeftCount] = useState(
    calculateDateRender(timeLeftInSec)
  );

  useEffect(() => {
    let secondCount = timeLeftInSec;
    const interval = setInterval(function () {
      secondCount -= 1;
      const result = calculateDateRender(secondCount);
      setTimeLeftCount(result);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timeLeftInSec]);

  return <>{timeLeftCount}</>;
}
