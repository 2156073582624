import React from 'react';

import CN from 'clsx';

import styles from './img.module.scss';

export interface IHoverHandleProps extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  /** HTML tag override for element; 'div' by default */
  as?: keyof JSX.IntrinsicElements; // tag 'div' by default
}

export const HoverHandle: React.FC<React.PropsWithChildren<IHoverHandleProps>> =
  function HoverHandle({ children, className, as = 'div', ...props }) {
    return React.createElement(
      as,
      { className: CN(className, styles.hoverHandle), ...props },
      children
    );
  };
