import { createIcon } from '@chakra-ui/icons';

export const PortfolioIcon = createIcon({
  displayName: 'PortfolioIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91074 3.78564C8.66524 3.78564 7.57957 4.63331 7.2775 5.84162L6.89863 7.35707H5.14369C3.72353 7.35707 2.57227 8.50834 2.57227 9.9285V17.6428C2.57227 19.0629 3.72353 20.2142 5.14369 20.2142H9.64369H18.858C20.2781 20.2142 21.4294 19.063 21.4294 17.6428V9.9285C21.4294 8.50834 20.2781 7.35707 18.858 7.35707H17.103L16.7242 5.84162C16.4221 4.63331 15.3364 3.78564 14.0909 3.78564H9.91074ZM15.0415 7.35707L14.7839 6.32669L15.754 6.08416L14.7839 6.32669C14.7044 6.00871 14.4187 5.78564 14.0909 5.78564H9.91074C9.58298 5.78564 9.29728 6.00871 9.21778 6.32669L8.96019 7.35707H15.0415ZM5.14369 9.35707H18.858C19.1736 9.35707 19.4294 9.61291 19.4294 9.9285V17.6428C19.4294 17.9584 19.1736 18.2142 18.858 18.2142H9.64369H5.14369C4.8281 18.2142 4.57227 17.9584 4.57227 17.6428V9.9285C4.57227 9.61291 4.8281 9.35707 5.14369 9.35707Z"
      fill="currentColor"
    />
  ),
});
