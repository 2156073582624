import React, { FunctionComponent } from 'react';

import cn from 'clsx';

import { Box, IBoxProps } from '../box';
import { HoverHandle } from './hover-handle';

import styles from './img.module.scss';

type TImg = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'srcSet'>;
type ImgElementStyle = NonNullable<JSX.IntrinsicElements['img']['style']>;

export interface IImgProps extends TImg {
  fit?: ImgElementStyle['objectFit'];
  className?: string;
  src?: string;
  src2x?: string;
  hoverEffect?: 'zoom' | 'none';
  borderRadius?: IBoxProps['borderRadius'];
  fullWidth?: boolean;
  m?: IBoxProps['m'];
  h?: IBoxProps['h'];
  aspectRatio?: string;
}

const RE_SVG = /\.svg/g;

export const Img: FunctionComponent<React.PropsWithChildren<IImgProps>> & {
  HoverHandle: typeof HoverHandle;
} = function Img({
  src,
  src2x,
  alt,
  className,
  hoverEffect,
  borderRadius,
  fullWidth,
  m: margin,
  h: height,
  fit,
  aspectRatio,
  ...props
}) {
  const isImgSvg = src && RE_SVG.test(src);
  const retinaPath = isImgSvg || !src2x ? null : `${src2x} 2x`;
  const srcSet = [src, retinaPath].join(',\n');

  return (
    <Box
      as="figure"
      className={cn(styles.wrapper, className)}
      borderRadius={borderRadius}
      m={margin}
      h={height}
    >
      <img
        className={cn(styles.image, {
          [styles['hoverEffectZoom']]: hoverEffect === 'zoom',
          [styles.fullWidth]: fullWidth,
        })}
        {...props}
        style={{ objectFit: fit, aspectRatio }}
        alt={alt}
        src={src}
        srcSet={srcSet}
      />
    </Box>
  );
};

Img.HoverHandle = HoverHandle;
