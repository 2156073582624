/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseQueryModel,
  IQueryModelOpts,
  useQuery,
  useGqlClient,
} from '@r-client/shared/data/client';
import {
  IAdminMenuQuery,
  IAdminMenuQueryVariables,
  AdminMenuDocument,
} from './types';
import { IAdminMenu } from '@r-client/data/graphql-types';
export type { IAdminMenu };

export type IAdminMenuQueryOpts = IQueryModelOpts<IAdminMenuQueryVariables>;

export class AdminMenuQuery extends BaseQueryModel<
  IAdminMenuQuery,
  IAdminMenuQueryVariables
> {
  // This is fine. Here we explicitly change interface for query
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(opts: IAdminMenuQueryOpts) {
    super(opts);
  }

  get query() {
    return AdminMenuDocument;
  }
}
export function useAdminMenuQuery(opts?: Omit<IAdminMenuQueryOpts, 'client'>) {
  const client = useGqlClient();
  return useQuery(
    () => new AdminMenuQuery({ ...opts, client }),
    opts?.variables
  );
}
