import { E_LOGO_TYPES } from '@r-client/republic/feature/brand';

import { HEADER_THEMES } from '../types';

export const getLogo = (theme: HEADER_THEMES): E_LOGO_TYPES => {
  switch (theme) {
    case 'dark':
      return E_LOGO_TYPES.White;
    case 'light':
    case 'r-note-teaser':
    default:
      return E_LOGO_TYPES.Black;
  }
};
