import { IFieldModel } from '@r-client/shared/util/model';

import { IInputGroupProps } from './input-group';

export interface IFieldBase<T> {
  field: IFieldModel<T>;
  inputGroupProps?: Partial<IInputGroupProps>;
}

export function getError<T>(field: IFieldModel<T>) {
  return field.touched && !field.changing ? field.error : undefined;
}

export function handleChange<T, TEvent>(
  field: IFieldModel<T>,
  onChange: undefined | ((e: TEvent) => void),
  getValue: (e: TEvent) => T = (e: any) => e.target.value,
  validateImmediately?: boolean
) {
  return (e: TEvent) => {
    field.value = getValue(e);
    if (!validateImmediately) field.changing = true;
    onChange?.(e);
  };
}

export function handleTouched<T, TEvent>(
  field: IFieldModel<T>,
  baseHandler: undefined | ((e: TEvent) => void),
  isTouched?: (e: TEvent) => boolean | Promise<boolean>
) {
  return (e: TEvent) => {
    const touched = isTouched?.(e);
    if (touched === undefined) {
      field.touched = true;
    }
    if (typeof touched === 'boolean') {
      if (touched) {
        field.touched = true;
      }
    } else {
      touched?.then((isValue) => {
        if (isValue) {
          field.touched = true;
        }
      });
    }
    field.changing = false;
    baseHandler?.(e);
  };
}
