import { memo } from 'react';

import cn from 'clsx';

import { NewTag } from '@r-client/republic/ui/core';
import { Avatar, Box, E_VARIANTS, Link, Text } from '@r-client/shared/ui/core';
import { Flex } from '@r-client/shared/ui/flex';
import { SadFace, SvgIconLight } from '@r-client/shared/ui/icons';

import { IGlobalSearchQuery } from '../../graphql/types';

import styles from './popular-suggestions.module.scss';

export interface IPopularSuggestionsProps
  extends Pick<IGlobalSearchQuery['globalSearch'], 'tags' | 'offerings'> {
  isNoResult: boolean;
  className?: string;
  onAnimationEnd?: VoidFunction;
}

export const PopularSuggestions = memo(
  ({
    className,
    offerings,
    tags,
    isNoResult,
    onAnimationEnd,
  }: IPopularSuggestionsProps) => {
    return (
      <Box
        onAnimationEnd={onAnimationEnd}
        className={cn(styles.main, className)}
        p={{ xs: '24px 20px 40px', md: '20px', lg: '20px' }}
        w={{ lg: '682px', md: '682px', sm: '100%', xs: '100%' }}
      >
        {isNoResult && (
          <Box
            className={styles.noResult}
            p="16px 20px 12px 20px"
            m={{
              xs: '-8px 0 20px',
              sm: '-8px -8px 20px',
              md: '-8px -8px 20px',
              lg: '-8px -8px 20px',
            }}
          >
            <SvgIconLight
              height={22}
              width={22}
              className={styles.noResultIcon}
              icon={SadFace}
            />
            <Text
              className={styles.noResultText}
              type="bodyMedium"
              weight="600"
              p="0 0 0 30px"
            >
              Oops! We couldn’t find any results...
            </Text>
            <Text color="gray8" type="bodySmall" lineHeight="1.3" m="0 0 6px 0">
              Can’t find a deal? Try{' '}
              <Link
                href="/companies"
                variant={E_VARIANTS.underline}
                className={styles.link}
              >
                advanced search
              </Link>
            </Text>
            <Text color="gray8" type="bodySmall" lineHeight="1.3">
              Is something missing? Add your suggestion{' '}
              <Link
                href="https://republic-co.typeform.com/to/xombuUWi"
                variant={E_VARIANTS.underline}
                className={styles.link}
              >
                here
              </Link>
            </Text>
          </Box>
        )}
        {!!offerings?.length && !isNoResult && (
          <Box>
            <Flex alignItems="baseline">
              <Text className={cn(styles.sectionName)} weight="600">
                Most traction
              </Text>
            </Flex>
            <Box className={cn(styles.offeringsList)} p="10px 0 12px">
              {offerings.map((offering) => (
                <Link
                  className={cn(styles.offering)}
                  variant={E_VARIANTS.inherit}
                  key={offering.slug}
                  href={`/${offering.slug}`}
                >
                  <Flex justifyContent="flex-start">
                    <Avatar
                      className={cn(styles.avatar)}
                      size="small"
                      url={offering.cardPopularImageUrl || ''}
                      url2x={offering.cardPopularImage2xUrl || ''}
                    />
                    <Box m="0 0 0 12px">
                      <Text
                        className={cn(styles.offeringName)}
                        type="bodyBig"
                        weight="600"
                      >
                        {offering.companyName}
                      </Text>
                      <Text
                        className={cn(styles.offeringDescription)}
                        color="gray8"
                        type="bodySmall"
                        lineHeight="1.3"
                      >
                        {offering.description}
                      </Text>
                    </Box>
                  </Flex>
                </Link>
              ))}
            </Box>
          </Box>
        )}
        {!!tags?.length && (
          <Box className={styles.sectionTags}>
            <Text className={cn(styles.sectionName)} weight="600">
              Popular categories
            </Text>
            <Flex p="12px 0 0" gap="8px" wrap="wrap">
              {tags.map((tag) => (
                <Link key={tag.id} href={`/tag/${tag.slug}`}>
                  <NewTag
                    className={styles.tag}
                    tagColor={tag.colorHex || ''}
                    size="medium"
                  >
                    {tag.displayName}
                  </NewTag>
                </Link>
              ))}
            </Flex>
          </Box>
        )}
      </Box>
    );
  }
);
