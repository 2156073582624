/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import * as Types from '@r-client/data/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGetHeaderSpotlightQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type IGetHeaderSpotlightQuery = {
  __typename?: 'Query';
  offeringsSpotlightDeals: {
    __typename?: 'OfferingConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'OfferingEdge';
      node: {
        __typename?: 'Offering';
        id: string;
        slug: string;
        companyName: string;
        description?: string | null | undefined;
        spotlight?: boolean | null | undefined;
        cardImageUrl?: string | null | undefined;
      };
    }>;
  };
};

export const GetHeaderSpotlightDocument = gql`
  query GetHeaderSpotlight {
    offeringsSpotlightDeals {
      edges {
        node {
          id
          slug
          companyName
          description
          spotlight
          cardImageUrl
        }
      }
      totalCount
    }
  }
`;
export type GetHeaderSpotlightQueryResult = Apollo.QueryResult<
  IGetHeaderSpotlightQuery,
  IGetHeaderSpotlightQueryVariables
>;
