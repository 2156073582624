import { forwardRef } from 'react';

import CN from 'clsx';

import { InfoGray, SvgIconLight } from '@r-client/shared/ui/icons';

import styles from './info-icon.module.css';

export interface ITooltipInfoIcon {
  className?: string;
  withLeftMargin?: boolean;
}
export const TooltipInfoIcon = forwardRef<HTMLElement, ITooltipInfoIcon>(
  function TooltipInfoIcon({ className, withLeftMargin, ...props }, ref) {
    return (
      <SvgIconLight
        ref={ref}
        icon={InfoGray}
        className={CN(className, {
          [styles.withLeftMargin]: withLeftMargin,
        })}
        {...props}
      />
    );
  }
);
