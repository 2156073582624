import { HEADER_THEMES } from '../types';

export const getBackgroundColor = (theme: HEADER_THEMES) => {
  switch (theme) {
    case 'dark':
    case 'r-note-teaser':
      return 'transparent';
    default:
      return 'white';
  }
};
