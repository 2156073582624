/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseQueryModel,
  IQueryModelOpts,
  useQuery,
  useGqlClient,
} from '@r-client/shared/data/client';
import {
  IGetUpcomingEventQuery,
  IGetUpcomingEventQueryVariables,
  GetUpcomingEventDocument,
} from './types';
import { IEventsEventConnection } from '@r-client/data/graphql-types';
export type { IEventsEventConnection };

export type IGetUpcomingEventQueryOpts =
  IQueryModelOpts<IGetUpcomingEventQueryVariables>;

export class GetUpcomingEventQuery extends BaseQueryModel<
  IGetUpcomingEventQuery,
  IGetUpcomingEventQueryVariables
> {
  // This is fine. Here we explicitly change interface for query
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(opts: IGetUpcomingEventQueryOpts) {
    super(opts);
  }

  get query() {
    return GetUpcomingEventDocument;
  }
}
export function useGetUpcomingEventQuery(
  opts?: Omit<IGetUpcomingEventQueryOpts, 'client'>
) {
  const client = useGqlClient();
  return useQuery(
    () => new GetUpcomingEventQuery({ ...opts, client }),
    opts?.variables
  );
}
