import { memo, ReactNode } from 'react';

import { Box, Text } from '@r-client/shared/ui/core';

import { AutosuggestItemData } from '../../types';

export interface IDropdownSectionProps {
  children: ReactNode;
  title: string;
  setAutosuggestItemClicked?: (data: AutosuggestItemData) => void;
}

export const DropdownSection = memo(
  ({ title, children }: IDropdownSectionProps) => {
    return (
      <Box>
        <Text
          as="h3"
          color="gray8"
          weight="600"
          type="bodySmall"
          data-testid="dropdown-section-title"
        >
          {title}
        </Text>
        <Box p="0 0 0.5brm">{children}</Box>
      </Box>
    );
  }
);
