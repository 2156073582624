import { Link, Text } from '@chakra-ui/react';

import { useAnalytics } from '@r-client/shared/data/analytics';
import { MailLetterIcon } from '@r-client/shared/ui/chakra-ui-icons';

import { HEADER_THEMES } from '../../types';
import { getTextColor } from '../../utils/get-text-color';

interface IMessagesMenuProps {
  unreadMessages?: number;
  theme: HEADER_THEMES;
}

export const MessagesMenu = ({ unreadMessages, theme }: IMessagesMenuProps) => {
  const analytics = useAnalytics();

  const trackMessagesIconClick = () => {
    analytics.track({
      name: 'header_icon_clicked',
      params: { label: 'messages', platform: 'web' },
    });
  };

  return unreadMessages ? (
    <Link
      href="/messages"
      position="relative"
      onClick={trackMessagesIconClick}
      data-testid="unread-messages-menu"
    >
      <MailLetterIcon color={getTextColor(theme)} boxSize="6" />
      <Text
        as="span"
        textStyle="sm"
        color="white"
        position="absolute"
        bottom="-5px"
        right="-10px"
        textAlign="center"
        p="1px"
        fontWeight="bold"
        background="red.500"
        borderRadius="xl"
        height="17px"
        width="22px"
      >
        {unreadMessages}
      </Text>
    </Link>
  ) : null;
};
