import { HEADER_THEMES } from '../types';

export const getTextColor = (
  theme: HEADER_THEMES,
  shade: '600' | '900' = '900'
) => {
  switch (theme) {
    case 'dark':
      return 'white';
    case 'light':
    case 'r-note-teaser':
    default:
      return `grey.${shade}`;
  }
};
