import { memo } from 'react';

import cn from 'clsx';

import { useAuth } from '@r-client/shared/feature/auth';
import { Box } from '@r-client/shared/ui/core';

import { AutosuggestItem, TAutosuggestType } from '../..';
import { IGlobalSearchQuery } from '../../graphql/types';
import { AutosuggestItemData, TAutosuggestItem } from '../../types';
import {
  getAutosuggestOpportunityData,
  getAutosuggestTagData,
  getAutosuggestUserData,
} from '../../utils/helpers';
import { DropdownSection } from '../dropdown-section';

import styles from './autosuggest.module.scss';

export type IAutosuggestProps = Pick<
  IGlobalSearchQuery['globalSearch'],
  'tags' | 'offerings' | 'users'
> & {
  className?: string;
  currentUserSlug?: string;
  setAutosuggestItemClicked?: (data: AutosuggestItemData) => void;
  onAnimationEnd?: VoidFunction;
};

const TAG_TITLE_TYPE = {
  tag: 'Categories',
  offering: 'Deals',
  user: 'Users',
};

export const Autosuggest = memo(
  ({
    className,
    users,
    offerings,
    tags,
    setAutosuggestItemClicked,
    onAnimationEnd,
  }: IAutosuggestProps) => {
    const { viewer } = useAuth();
    const sections: {
      type: TAutosuggestType;
      data: TAutosuggestItem[];
    }[] = [
      {
        type: 'offering',
        data: getAutosuggestOpportunityData(offerings),
      },
      {
        type: 'tag',
        data: getAutosuggestTagData(tags),
      },
      {
        type: 'user',
        data: getAutosuggestUserData(users),
      },
    ];

    return (
      <Box
        p="1.25brm"
        className={cn(styles.autosuggest, className)}
        data-testid="search-autosuggest"
        onAnimationEnd={onAnimationEnd}
      >
        {sections.map(
          ({ type, data }, index) =>
            !!data.length && (
              <DropdownSection
                setAutosuggestItemClicked={setAutosuggestItemClicked}
                key={index}
                title={TAG_TITLE_TYPE[type]}
              >
                {data.map((item) => {
                  return (
                    <AutosuggestItem
                      key={item.slug}
                      data={item}
                      currentUserSlug={viewer?.info?.slug || ''}
                    />
                  );
                })}
              </DropdownSection>
            )
        )}
      </Box>
    );
  }
);
