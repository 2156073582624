import { Avatar, Box } from '@r-client/shared/ui/core';
import { Dropdown } from '@r-client/shared/ui/dropdown';

import { IssuerProfileContent } from '../../issuer-profile-content/issuer-profile-content';
import { TIssuerOffering } from '../../types';

export interface IIssuerProfileDropdown {
  url: string;
  companyName: string;
  onboardingCompleteness: number;
  offerings: TIssuerOffering[];
}

export const IssuerProfileDropdown: React.FC<
  React.PropsWithChildren<IIssuerProfileDropdown>
> = ({ url, companyName, onboardingCompleteness, offerings }) => {
  return (
    <Dropdown
      arrow
      placement="bottom-end"
      trigger="hover"
      content={
        <IssuerProfileContent
          companyName={companyName}
          onboardingCompleteness={onboardingCompleteness}
          offerings={offerings}
        />
      }
    >
      <div data-testid="header-avatar-issuer">
        <Box m="0 20px 0 0">
          <Avatar url={url} avatarType="issuer" border={false} size="xSmall" />
        </Box>
      </div>
    </Dropdown>
  );
};
