/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import * as Types from '@r-client/data/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGlobalSearchQueryVariables = Types.Exact<{
  q?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type IGlobalSearchQuery = {
  __typename?: 'Query';
  globalSearch: {
    __typename?: 'GlobalSearchBase';
    isPopular: boolean;
    tags?:
      | Array<{
          __typename?: 'GlobalSearchTag';
          displayName: string;
          keywords?: Array<string> | null | undefined;
          parentName?: string | null | undefined;
          slug: string;
          id: string;
          colorHex?: string | null | undefined;
        }>
      | null
      | undefined;
    suggestedTags?:
      | Array<{
          __typename?: 'GlobalSearchTag';
          displayName: string;
          keywords?: Array<string> | null | undefined;
          parentName?: string | null | undefined;
          slug: string;
        }>
      | null
      | undefined;
    offerings?:
      | Array<{
          __typename?: 'GlobalSearchOffering';
          campaignStatus: string;
          logoImageUrl?: string | null | undefined;
          companyName: string;
          slug: string;
          state: string;
          description?: string | null | undefined;
          id: string;
          logoImageUrl2x?: string | null | undefined;
          cardPopularImageUrl?: string | null | undefined;
          cardPopularImage2xUrl?: string | null | undefined;
        }>
      | null
      | undefined;
    users?:
      | Array<{
          __typename?: 'GlobalSearchUser';
          avatarUrl?: string | null | undefined;
          firstName?: string | null | undefined;
          investmentsQuantity: number;
          lastName?: string | null | undefined;
          slug: string;
          id: string;
          avatarUrl2x?: string | null | undefined;
        }>
      | null
      | undefined;
    errors?:
      | {
          __typename?: 'GlobalSearchErrorElastic';
          message: string;
          path?: Array<string> | null | undefined;
          extensions: {
            __typename?: 'Extensions';
            code: Types.ICodeEnum;
            exception?: Record<string, unknown> | null | undefined;
            field?: string | null | undefined;
          };
          locations?:
            | Array<{ __typename?: 'Location'; col: number; line: number }>
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export const GlobalSearchDocument = gql`
  query GlobalSearch($q: String) {
    globalSearch(q: $q) {
      isPopular
      tags {
        displayName
        keywords
        parentName
        slug
        id
        colorHex
      }
      suggestedTags {
        displayName
        keywords
        parentName
        slug
      }
      offerings {
        campaignStatus
        logoImageUrl(resize: { width: 36, height: 36, type: fit })
        logoImageUrl2x: logoImageUrl(
          resize: { width: 72, height: 72, type: fit }
        )
        cardPopularImageUrl: logoImageUrl(
          resize: { width: 48, height: 48, type: fit }
        )
        cardPopularImage2xUrl: logoImageUrl(
          resize: { width: 96, height: 96, type: fit }
        )
        companyName
        slug
        state
        description
        id
      }
      users {
        avatarUrl(resize: { width: 36, height: 36, type: auto })
        avatarUrl2x: avatarUrl(resize: { width: 72, height: 72, type: auto })
        firstName
        investmentsQuantity
        lastName
        slug
        id
      }
      errors {
        extensions {
          code
          exception
          field
        }
        locations {
          col
          line
        }
        message
        path
      }
    }
  }
`;
export type GlobalSearchQueryResult = Apollo.QueryResult<
  IGlobalSearchQuery,
  IGlobalSearchQueryVariables
>;
