import React from 'react';

import { Box } from '@chakra-ui/react';

import { translateRegulationType } from '@r-client/shared/data/translation';
import { Avatar, Divider, Link, Text } from '@r-client/shared/ui/core';
import { Flex } from '@r-client/shared/ui/flex';
import { BasicMenu, BasicMenuItem } from '@r-client/shared/ui/menu';
import { getOrdinal } from '@r-client/shared/util/core';

import { TIssuerOffering } from '../types';

import styles from './issuer-profile-content.module.scss';

const OfferingPath = (
  offering: TIssuerOffering,
  companyName: string,
  hasManyOfferings: boolean
) => (
  <BasicMenuItem>
    <Link
      target="_blank"
      rel="nofollow noopener"
      variant={Link.variants.unstyled}
      href={`/${offering.slug}`}
    >
      {hasManyOfferings ? (
        <Flex justifyContent="space-between">
          <Flex alignItems="center">
            <Avatar
              url={offering.logoUrl || ''}
              avatarType="issuer"
              border
              size="xSmall"
            />
            <Flex direction="column" m="0 1brm 0 .5brm">
              <Text lineHeight="1.2">{companyName}</Text>
              <Text color="gray40" lineHeight="1.2">
                {translateRegulationType(offering.regulation)}
              </Text>
            </Flex>
          </Flex>
          <Flex alignItems="center">
            <Text as="span">{offering.indexInAllOfferings}</Text>
            <Text as="span" m="-10px 0 0 0" type="bodySmall">
              {getOrdinal(offering.indexInAllOfferings!)}
            </Text>
          </Flex>
        </Flex>
      ) : (
        'View campaign page'
      )}
    </Link>
  </BasicMenuItem>
);

const PublishedLinks = (slug: TIssuerOffering['slug']) => (
  <>
    <BasicMenuItem>
      <Link
        target="_blank"
        rel="nofollow noopener"
        variant={Link.variants.unstyled}
        href={`/${slug}/manage`}
      >
        Manage
      </Link>
    </BasicMenuItem>
    <BasicMenuItem>
      <Link
        target="_blank"
        rel="nofollow noopener"
        variant={Link.variants.unstyled}
        href={`/${slug}/messages`}
      >
        Message investors
      </Link>
    </BasicMenuItem>
  </>
);

const OnboardingLinks = (
  offering: TIssuerOffering,
  onboardingCompleteness: number
) => (
  <BasicMenuItem>
    {onboardingCompleteness === 100 ? (
      <Link
        target="_blank"
        rel="nofollow noopener"
        variant={Link.variants.unstyled}
        href={`/${offering.slug}/onboarding`}
      >
        Campaign onboarding
      </Link>
    ) : (
      <Link
        target="_blank"
        rel="nofollow noopener"
        variant={Link.variants.unstyled}
        href={`/${offering.slug}/onboarding/company-profile`}
      >
        Onboarding dashboard
      </Link>
    )}
  </BasicMenuItem>
);

export interface IIssuerProfileContent {
  companyName: string;
  onboardingCompleteness: number;
  offerings: TIssuerOffering[];
}

export function IssuerProfileContent({
  companyName,
  onboardingCompleteness,
  offerings,
}: IIssuerProfileContent) {
  const hasManyOfferings = offerings?.length > 1;

  return (
    <BasicMenu className={styles.issuerProfileMenu}>
      {offerings?.map((offering) => {
        return (
          <React.Fragment key={offering.indexInAllOfferings}>
            {offering.indexInAllOfferings! > 1 && (
              <Divider margin={{ bottom: '0', top: '0' }} />
            )}
            <Box fontSize="lg">
              {OfferingPath(offering, companyName, hasManyOfferings)}
              {offering.hasPublished
                ? PublishedLinks(offering.slug)
                : OnboardingLinks(offering, onboardingCompleteness)}
            </Box>
          </React.Fragment>
        );
      })}
    </BasicMenu>
  );
}
