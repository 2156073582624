import { useEffect, useRef } from 'react';

export const useIsUnmounted = () => {
  const isUnmountedRef = useRef(false);
  useEffect(() => {
    return () => {
      isUnmountedRef.current = true;
    };
  }, []);
  return isUnmountedRef;
};
