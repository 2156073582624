import React, {
  Children,
  cloneElement,
  FunctionComponent,
  isValidElement,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type { Placement as PopperJSPlacement } from '@popperjs/core';
import CN from 'clsx';
import { createPortal } from 'react-dom';
import { Config, TriggerType, usePopperTooltip } from 'react-popper-tooltip';
import { CSSTransition } from 'react-transition-group';

import { ClientRender } from '@r-client/shared/util/components';
import { getCN, getDocument, typeCast } from '@r-client/shared/util/core';

import { Box } from '../box/box';
import { useDelayedSwitcher } from './hooks';
import { TooltipInfoIcon } from './info-icon';

import stylesCSS from './tooltip.module.scss';

export interface ITooltipProps extends Pick<Config, 'delayHide' | 'delayShow'> {
  placement?: PopperJSPlacement;
  content: ReactNode;
  theme?: 'dark' | 'warn' | 'light';
  width?: 'tiny' | 'default' | 'big' | 'large';
  interactive?: boolean;
  delayHide?: number;
  delayShow?: number;
  disabled?: boolean;
  alwaysVisible?: boolean;
  showOnMount?: boolean;
  closeOnOutsideClick?: boolean;
  trigger?: TriggerType;
  appendTo?: HTMLElement;
  followCursor?: boolean;
  zIndex?: number;
  className?:
    | string
    | {
        main?: string;
        wrap?: string;
      };
}

export const HOVER_DELAY = 500;

const TooltipComponent: FunctionComponent<
  React.PropsWithChildren<ITooltipProps>
> = ({
  placement = 'top',
  content,
  theme = 'dark',
  width = 'default',
  interactive = false,
  children,
  disabled = false,
  showOnMount = false,
  alwaysVisible = false,
  closeOnOutsideClick = true,
  trigger = 'hover',
  appendTo,
  followCursor = false,
  zIndex,
  className,
  delayHide,
  delayShow,
}) => {
  const [isHover, setHover] = useDelayedSwitcher(false, {
    delayOut: delayHide ?? HOVER_DELAY,
  });
  const [isControlledVisible, setControlledVisible] = useState<boolean>(
    alwaysVisible || showOnMount || isHover || false
  );

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    update,
  } = usePopperTooltip(
    {
      closeOnOutsideClick,
      defaultVisible: alwaysVisible,
      visible: isControlledVisible,
      trigger: disabled ? null : trigger,
      interactive,
      placement,
      followCursor,
      delayHide: delayHide ?? 0,
      delayShow: delayShow ?? 0,
      onVisibleChange: setControlledVisible,
    },
    {
      modifiers: [
        {
          name: 'computeStyles',
          options: {
            adaptive: false,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 12],
          },
        },
      ],
    }
  );

  useEffect(() => {
    if (appendTo) {
      setTriggerRef(appendTo);
    }
  }, [appendTo, setTriggerRef]);

  useEffect(() => {
    if (isHover) {
      update?.();
    }
  }, [isHover, update]);

  const onMouseEnterHandler = useCallback(
    (event: React.MouseEvent) => {
      if (disabled) {
        event.stopPropagation();
      } else {
        setHover(true);
      }
    },
    [disabled, setHover]
  );

  const onMouseLeaveHandler = useCallback(() => {
    setHover(false);
    if (showOnMount) {
      setControlledVisible(false);
    }
  }, [showOnMount, setHover]);

  const body = getDocument()?.body;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const container = useMemo(() => appendTo || body, [appendTo]);

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child as React.ReactElement, { ref: setTriggerRef });
    }

    return child;
  });

  return (
    <>
      <span
        className={CN(stylesCSS.target, getCN(className, 'wrap'))}
        onMouseEnter={trigger === 'hover' ? onMouseEnterHandler : undefined}
        onMouseLeave={onMouseLeaveHandler}
      >
        {childrenWithProps}
      </span>
      {!!container && (
        <ClientRender>
          {createPortal(
            <CSSTransition
              in={visible || isHover}
              timeout={300}
              classNames={{
                enterActive: stylesCSS.appearActive,
                enterDone: stylesCSS.appearDone,
              }}
            >
              <div
                {...getTooltipProps({
                  ref: setTooltipRef,
                  className: CN(
                    stylesCSS.tooltip,
                    stylesCSS.content,
                    {
                      [stylesCSS.themeDark]: theme === 'dark',
                      [stylesCSS.themeWarn]: theme === 'warn',
                      [stylesCSS.themeLight]: theme === 'light',
                      [stylesCSS.appearActive]: visible,
                      [stylesCSS.appearDone]: visible,
                      [stylesCSS.sizeTiny]: width === 'tiny',
                      [stylesCSS.sizeBig]: width === 'big',
                      [stylesCSS.sizeLarge]: width === 'large',
                    },
                    getCN(className)
                  ),
                  style: {
                    ...(zIndex && { zIndex }),
                  },
                  onMouseEnter: interactive ? onMouseEnterHandler : undefined,
                  onMouseLeave: interactive ? onMouseLeaveHandler : undefined,
                })}
              >
                <Box>{content}</Box>
                <div
                  {...getArrowProps({
                    className: stylesCSS.arrow,
                  })}
                ></div>
              </div>
            </CSSTransition>,
            container!
          )}
        </ClientRender>
      )}
    </>
  );
};

export const Tooltip = typeCast<
  typeof TooltipComponent & {
    InfoIcon: typeof TooltipInfoIcon;
  }
>(memo(TooltipComponent));

Tooltip.InfoIcon = TooltipInfoIcon;
