/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseQueryModel,
  IQueryModelOpts,
  useQuery,
  useGqlClient,
} from '@r-client/shared/data/client';
import {
  IGetNotificationsQuery,
  IGetNotificationsQueryVariables,
  GetNotificationsDocument,
} from './types';
import { INotificationConnection } from '@r-client/data/graphql-types';
export type { INotificationConnection };

//export interface IGetNotificationsQueryOpts extends Omit<IQueryModelOpts<IGetNotificationsQueryVariables>, 'variables'> {
export interface IGetNotificationsQueryOpts
  extends IQueryModelOpts<IGetNotificationsQueryVariables> {
  variables: IGetNotificationsQueryVariables;
}

export class GetNotificationsQuery extends BaseQueryModel<
  IGetNotificationsQuery,
  IGetNotificationsQueryVariables
> {
  // This is fine. Here we explicitly change interface for query
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(opts: IGetNotificationsQueryOpts) {
    super(opts);
  }

  get query() {
    return GetNotificationsDocument;
  }
}
export function useGetNotificationsQuery(
  opts: Omit<IGetNotificationsQueryOpts, 'client'>
) {
  const client = useGqlClient();
  return useQuery(
    () => new GetNotificationsQuery({ ...opts, client }),
    opts?.variables
  );
}
