export enum E_VALIDATION_STATUS {
  error = 'error',
  warning = 'warning',
  success = 'success',
}

export interface IOption {
  value: string | number | string[] | undefined;
  label: string;
  disabled?: boolean;
  data?: any;
  checked?: boolean;
}
