import React from 'react';

import { List } from '@r-client/shared/ui/core';

import styles from './basic-menu.module.scss';

export interface IBasicMenuProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
}

export const BasicMenu = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IBasicMenuProps>
>(function BasicMenu({ children, className, ...restProps }, ref) {
  return (
    <div ref={ref} className={className} {...restProps}>
      <List className={styles.menu}>{children}</List>
    </div>
  );
});
