import { PropsWithChildren, useState } from 'react';

import { Box, Button, Flex } from '@chakra-ui/react';

interface IDisclaimerContainerProps extends PropsWithChildren {
  maxHeight: string;
  showLabel?: string;
}

export const DisclaimerContainer = ({
  children,
  maxHeight,
  showLabel = 'Show disclaimers',
}: IDisclaimerContainerProps) => {
  const [showFull, setShowFull] = useState(false);

  const handleShow = () => setShowFull(!showFull);

  return (
    <Box
      h={showFull ? 'auto' : maxHeight}
      overflowY="clip"
      position="relative"
      transition="height 0.1s linear"
      sx={{
        interpolateSize: 'allow-keywords',
      }}
      mb="1"
    >
      {children}

      {!showFull && (
        <Flex
          position="absolute"
          bottom="0"
          alignItems="flex-end"
          justifyContent="center"
          w="full"
          h="160px"
          bg="linear-gradient(0, #161d2d 0%, rgba(22, 29, 45, 0%) 100%)"
        >
          <Button
            variant="republic-secondary"
            py="2"
            px="4"
            color="white"
            bgColor="transparent"
            borderColor="grey.200"
            onClick={handleShow}
            _hover={{
              borderColor: 'grey.300',
              color: 'grey.300',
            }}
          >
            {showLabel}
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export type { IDisclaimerContainerProps };
