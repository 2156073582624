import { Img, Link, Text } from '@r-client/shared/ui/core';
import { Flex, FlexItem } from '@r-client/shared/ui/flex';
import { BasicMenuItem } from '@r-client/shared/ui/menu';

import { TEditableEntity } from '../types';
import dummy from './assets/dummy.svg';

import styles from './editable-entities.module.scss';

interface IEditableEntityProps {
  entity: TEditableEntity;
  entityType?: 'teaser' | 'offering';
}

export const EditableEntity = ({
  entity,
  entityType = 'offering',
}: IEditableEntityProps) => {
  return (
    <BasicMenuItem key={entity.id} data-testid="editable-entities-section">
      <Link
        target="_blank"
        rel="nofollow noopener"
        variant={Link.variants.unstyled}
        href={
          entityType === 'offering'
            ? `/${entity.slug}`
            : `/coming-soon/first-look/${entity.slug}/dashboard`
        }
      >
        <Flex alignItems="center" gap="8px">
          <FlexItem>
            <Img
              src={(entity.logoUrl || entity.logoImageUrl) ?? dummy}
              src2x={entity.logoUrl2x ?? dummy}
              className={styles.offeringImage}
            />
          </FlexItem>
          <FlexItem>
            <Text lineHeight="1">{entity.companyName}</Text>
          </FlexItem>
        </Flex>
      </Link>
    </BasicMenuItem>
  );
};
