import { useEffect, useRef, useState } from 'react';

import cn from 'clsx';
import CN from 'clsx';
import { observer } from 'mobx-react-lite';

import {
  Autosuggest,
  AutosuggestItemData,
  handleSendSuggestionItemsAnalyticsEvent,
  PopularSuggestions,
  Search,
  useGlobalSearchQuery,
} from '@r-client/republic/feature/search';
import { useAnalytics } from '@r-client/shared/data/analytics';
import { useAuth } from '@r-client/shared/feature/auth';
import { Box } from '@r-client/shared/ui/core';
import { Button } from '@r-client/shared/ui/core';
import { Flex } from '@r-client/shared/ui/flex';
import { BigCross, SvgIconLight } from '@r-client/shared/ui/icons';
import {
  isRepublicEmail,
  useDebounce,
  useNoPageScroll,
} from '@r-client/shared/util/core';
import { FieldModel } from '@r-client/shared/util/model';

import styles from './mobile-search.module.scss';

export interface IMobileSearchProps {
  onSearchOpen?: () => void;
  onSearchClose?: () => void;
}

export const MobileSearch = observer(function GlobalSearch({
  onSearchOpen,
  onSearchClose,
}: IMobileSearchProps) {
  const fieldRef = useRef(new FieldModel<string>());
  const [autosuggestItemClicked, setAutosuggestItemClicked] =
    useState<AutosuggestItemData | null>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [isOpening, setIsOpening] = useState<boolean>(false);
  const field = fieldRef.current;
  const debouncedValue = useDebounce<string>(field.value || '', 500);
  const { disablePageScroll, enablePageScroll } = useNoPageScroll();
  const analytics = useAnalytics();
  const { viewer } = useAuth();

  const { data, isRejected, isLoading } = useGlobalSearchQuery({
    variables: { q: debouncedValue },
    notifyOnNetworkStatusChange: true,
  });
  const globalSearch = data?.globalSearch;
  const isPopular = globalSearch?.isPopular;

  const showPopular =
    (isPopular || (isLoading && !debouncedValue)) && isFocused;
  const showAutosuggest = !showPopular && !isLoading && isFocused;

  const openMobileSearch = () => {
    setIsOpening(true);
    onSearchOpen && onSearchOpen();
  };

  function handleFocus(value: boolean) {
    setIsFocused(value);
    openMobileSearch();
    disablePageScroll();
  }

  async function handleClose() {
    setIsOpening(false);
    setIsClosing(true);
    await new Promise((r) => setTimeout(r, 250));
    setIsFocused(false);
    setIsClosing(false);
    onSearchClose && onSearchClose();
    enablePageScroll();
  }

  useEffect(() => {
    if (!isLoading && analytics && data && field.value !== undefined) {
      analytics.track({
        name: 'global_search_submitted',
        params: {
          is_employee: isRepublicEmail(viewer?.info?.email),
          platform: 'web',
          search_term: field.value,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, analytics, isLoading]);

  useEffect(() => {
    // TODO: https://linear.app/republic/issue/INV-684/global-search-dropdown-events-not-tracked
    if (autosuggestItemClicked) {
      handleSendSuggestionItemsAnalyticsEvent(
        autosuggestItemClicked,
        analytics,
        globalSearch,
        field.value
      );
    }
  }, [
    analytics,
    autosuggestItemClicked,
    field.value,
    globalSearch,
    viewer?.info?.id,
  ]);

  return (
    <Box
      className={CN(styles.searchWrapper, {
        [styles.isFocused]: isFocused,
        [styles.isClosing]: isClosing,
        [styles.isOpening]: isOpening,
      })}
    >
      <Flex>
        <Search
          isLoading={isFocused && isLoading}
          isActive={isFocused}
          field={field}
          onFocus={handleFocus}
          autoFocus={false}
          data-testid="mobile-search-input"
        />
        {isFocused && (
          <Box m="0 0 0 0.5brm">
            <Button
              className={styles.closeButton}
              onClick={handleClose}
              type="grayBackgroundLink"
              shape="circle"
            >
              <SvgIconLight icon={BigCross} />
            </Button>
          </Box>
        )}
      </Flex>

      <Box className={styles.dropdown}>
        {!isRejected && (
          <Box>
            {showPopular && (
              <PopularSuggestions
                {...globalSearch}
                isNoResult={!!debouncedValue}
                className={cn(styles.popularSuggestions)}
              />
            )}
            {showAutosuggest && (
              <Autosuggest
                setAutosuggestItemClicked={setAutosuggestItemClicked}
                className={styles.autosuggest}
                {...globalSearch}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
});
