/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseMutationModel,
  IMutationModelOpts,
  useGqlClient,
} from '@r-client/shared/data/client';
import { useLocalObservable } from 'mobx-react-lite';
import {
  IMarkHeaderNotificationsViewedMutation,
  MarkHeaderNotificationsViewedDocument,
  IMarkHeaderNotificationsViewedMutationVariables,
} from './types';
import { INotificationMarkHeaderViewedPayload } from '@r-client/data/graphql-types';
export type { INotificationMarkHeaderViewedPayload };

export class MarkHeaderNotificationsViewedMutation extends BaseMutationModel<
  IMarkHeaderNotificationsViewedMutation,
  IMarkHeaderNotificationsViewedMutationVariables
> {
  get mutation() {
    return MarkHeaderNotificationsViewedDocument;
  }
}
export function useMarkHeaderNotificationsViewedMutation(
  opts?: Omit<
    IMutationModelOpts<IMarkHeaderNotificationsViewedMutationVariables>,
    'client'
  >
) {
  const client = useGqlClient();
  return useLocalObservable(
    () => new MarkHeaderNotificationsViewedMutation({ ...opts, client })
  );
}
