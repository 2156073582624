import {
  IEventsOrderEnum,
  IEventsStateEnum,
} from '@r-client/data/graphql-types';

import { useGetUpcomingEventQuery } from '../graphql/get-upcoming-event-query';

export type { TGetUpcomingEvent as ILatestUpcomingEvent } from '../types';

export function useGetLatestUpcomingEventQuery() {
  return useGetUpcomingEventQuery({
    variables: {
      timeFilters: {
        future: true,
      },
      order: IEventsOrderEnum.BeginAtAsc,
      first: 1,
      states: IEventsStateEnum.Published,
    },
  });
}
