import React, { FunctionComponent } from 'react';

import classNames from 'clsx';

import styles from './list.module.scss';

export enum E_LIST {
  disc = 'disc',
  circle = 'circle',
}
export type IItemProps = React.HTMLAttributes<HTMLLIElement>;

const Item: FunctionComponent<React.PropsWithChildren<IItemProps>> = ({
  children,
  ...props
}) => {
  return <li {...props}>{children}</li>;
};

export interface IListProps extends React.HTMLAttributes<HTMLUListElement> {
  type?: E_LIST;
  large?: boolean;
}

const List$: FunctionComponent<React.PropsWithChildren<IListProps>> = ({
  className,
  children,
  type,
  large,
  ...props
}) => {
  const typeClass = type ? styles[type] : undefined;
  return (
    <ul
      className={classNames(styles.main, typeClass, className, {
        [styles.large]: large,
      })}
      {...props}
    >
      {children}
    </ul>
  );
};

export interface IOrderedListProps
  extends React.HTMLAttributes<HTMLOListElement> {
  large?: boolean;
}

const OrderedList$: FunctionComponent<
  React.PropsWithChildren<IOrderedListProps>
> = ({ className, children, large, ...props }) => {
  return (
    <ol
      className={classNames(styles.olElement, className, {
        [styles.large]: large,
      })}
      {...props}
    >
      {children}
    </ol>
  );
};

export const List: typeof List$ & { Item: typeof Item; type: typeof E_LIST } =
  List$ as any;
List.displayName = 'List';
List.Item = Item;
List.type = E_LIST;

export const OrderedList: typeof OrderedList$ & { Item: typeof Item } =
  OrderedList$ as any;
OrderedList.displayName = 'OrderedList';
OrderedList.Item = Item;
