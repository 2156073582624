import { getNavigationGlobal } from './navigation-global';
import { getNavigationUs } from './navigation-us';
import { INavigationData } from './types';

export const getNavigationData = ({
  region,
  hasWallet,
}: {
  region: 'us' | 'global';
  hasWallet: boolean;
}): INavigationData[] => {
  if (region === 'us') {
    return getNavigationUs(hasWallet);
  }
  if (region === 'global') return getNavigationGlobal(hasWallet);

  return [] as never;
};
