import { createIcon } from '@chakra-ui/react';

export const CheckMarkIcon = createIcon({
  displayName: 'CheckMarkIcon',
  viewBox: '0 0 20 21',
  path: (
    <path
      fill="#CCC"
      d="M15.592 6.493a.833.833 0 0 0-1.184 0L8.2 12.71l-2.608-2.617a.851.851 0 0 0-1.184 1.225l3.2 3.2a.833.833 0 0 0 1.184 0l6.8-6.8a.834.834 0 0 0 0-1.225"
    />
  ),
});
