import { Box, Image, Link, Text } from '@chakra-ui/react';
import { format, isToday, isTomorrow } from 'date-fns';

import { ILatestUpcomingEvent } from '@r-client/republic/feature/events';
import { useAnalytics } from '@r-client/shared/data/analytics';

import fallbackImage from './assets/event-fallback.svg';

interface IWhatsNewSectionProps {
  latestEvent?: ILatestUpcomingEvent | null;
}

export const WhatsNewSection = ({ latestEvent }: IWhatsNewSectionProps) => {
  const analytics = useAnalytics();
  if (!latestEvent) {
    return null;
  }

  const href = `/events/${latestEvent.slug}`;

  const trackHeaderEventClick = () => {
    analytics.track({
      name: 'header_event_clicked',
      params: {
        current_event_title: latestEvent.title,
        current_event_id: latestEvent.id,
        platform: 'web',
      },
    });
  };

  return (
    <Box py="12">
      <Box mb="5" mt="1">
        <Text textStyle="md" fontWeight="semibold" lineHeight="16px">
          What's new
        </Text>
      </Box>
      <Link
        href={href}
        role="group"
        _hover={{ textDecoration: 'none' }}
        onClick={trackHeaderEventClick}
      >
        <Box mb="3">
          <Image
            src={latestEvent.coverPhotoUrl || fallbackImage}
            width="100%"
            borderRadius="2xl"
          />
        </Box>
        <Box>
          <Text mb="1">{latestEvent.title}</Text>
          <Text
            textStyle="sm"
            color="grey.500"
            _groupHover={{ color: 'grey.900' }}
          >
            {formatEventDates(latestEvent)}
          </Text>
        </Box>
      </Link>
    </Box>
  );
};

function formatEventDates(event: ILatestUpcomingEvent) {
  return `${formatEventDay(event)} · ${formatEventTime(event)}`;
}

function formatEventDay(event: ILatestUpcomingEvent) {
  const date = new Date(event.beginAt);

  if (isToday(date)) {
    return 'Today';
  } else if (isTomorrow(date)) {
    return 'Tomorrow';
  } else {
    return format(date, 'EEE, LLL d');
  }
}

function formatEventTime(event: ILatestUpcomingEvent) {
  const formatTime = (date: Date) => format(date, 'h:mmaaa');
  const startDate = new Date(event.beginAt);
  let result = formatTime(startDate);

  if (event.endAt) {
    const endDate = new Date(event.endAt);
    result += `-${formatTime(endDate)}`;
  }

  return result;
}
