import { createIcon } from '@chakra-ui/icons';

export const ArrowUpIcon = createIcon({
  displayName: 'ArrowUpIcon',
  viewBox: '0 0 28 28',
  defaultProps: {
    fill: 'currentColor',
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.48494 13.8182C8.09441 14.2087 7.46125 14.2087 7.07072 13.8182C6.6802 13.4277 6.6802 12.7945 7.07072 12.404L13.2929 6.18178C13.4805 5.99425 13.7348 5.88889 14.0001 5.88889C14.2653 5.88889 14.5196 5.99425 14.7072 6.18178L20.9294 12.404C21.3199 12.7945 21.3199 13.4277 20.9294 13.8182C20.5389 14.2087 19.9057 14.2087 19.5152 13.8182L14.8887 9.19177L14.8887 21.1111C14.8887 21.602 14.4908 22 13.9998 22C13.5089 22 13.1109 21.602 13.1109 21.1111L13.1109 9.19221L8.48494 13.8182Z"
    />
  ),
});
