import React, { forwardRef } from 'react';

import CN from 'clsx';

import { Img } from '@r-client/shared/ui/core';

import styles from './tag.module.scss';

export interface INewTagProps extends React.HTMLAttributes<HTMLLabelElement> {
  as?: 'label' | 'span';
  className?: string;
  size?: 'small' | 'medium' | 'big';
  tagColor?: string;
  variant?: 'interest' | 'base' | 'catalogTag';
  closeIcon?: React.ReactNode;
  icon?: React.ReactNode;
  iconUrl?: string;
  isActive?: boolean;
  dataTestId?: string;
}

const RX_COLOR = /^[0-9a-fA-F|#]{6,7}$/;

export const NewTag = forwardRef<HTMLLabelElement, INewTagProps>(
  function NewTag(
    {
      as = 'span',
      variant = 'base',
      className,
      children,
      tagColor,
      size = 'small',
      closeIcon,
      icon,
      iconUrl,
      isActive = false,
      dataTestId,
      onClick,
      ...props
    },
    ref
  ) {
    const [isHovered, setIsHovered] = React.useState<boolean>(false);
    const [isSelected, setIsSelected] = React.useState<boolean>(isActive);
    const backgroundColor = isSelected && tagColor ? tagColor : '#959595';

    const classNames = CN(styles.main, className, {
      [styles.catalogTag]: variant === 'catalogTag',
      [styles.interest]: variant === 'interest',
      [styles.base]: variant === 'base',
      [styles.canClose]: !!closeIcon,
      [styles.small]: size === 'small',
      [styles.medium]: size === 'medium',
      [styles.big]: size === 'big',
    });

    const baseStyle = {
      backgroundColor:
        tagColor && RX_COLOR.test(tagColor)
          ? `#${tagColor.replace('#', '')}`
          : `#9595951A`,
    };

    const style = {
      backgroundColor:
        backgroundColor && RX_COLOR.test(backgroundColor)
          ? `#${backgroundColor}${isHovered ? '26' : '1A'}`
          : `${backgroundColor}${isHovered ? '26' : '1A'}`,
      color:
        backgroundColor && RX_COLOR.test(backgroundColor)
          ? '#' + backgroundColor
          : '#959595',
    };

    const catalogTagChildren = (
      <>
        <span className={styles.icon}>{icon}</span>
        <span className={styles.tagText}>{children}</span>
        <span className={styles.close} onClick={onClick}>
          {closeIcon}
        </span>
      </>
    );

    if (variant === 'catalogTag' && icon) {
      return React.createElement(as, {
        ...props,
        className: classNames,
        ref,
        children: catalogTagChildren,
      });
    }

    const interestTagChildren = (
      <>
        {iconUrl && <Img src={iconUrl} className={styles.icon} />}
        <span className={styles.tagText}>{children}</span>
      </>
    );

    if (variant === 'interest') {
      return React.createElement(as, {
        ...props,
        style,
        className: classNames,
        ref,
        'data-testid': dataTestId,
        children: interestTagChildren,
        onClick: () => {
          setIsSelected(!isSelected);
        },
        onMouseEnter: () => {
          setIsHovered(true);
        },
        onMouseLeave: () => {
          setIsHovered(false);
        },
      });
    }

    return React.createElement(
      as,
      {
        ...props,
        style: baseStyle,
        className: classNames,
        'data-testid': dataTestId,
        ref,
        onMouseEnter: () => {
          setIsHovered(true);
        },
        onMouseLeave: () => {
          setIsHovered(false);
        },
      },
      children
    );
  }
);
