import { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { useGqlClient } from '@r-client/shared/data/client';
import { useAuth } from '@r-client/shared/feature/auth';

import {
  GetCryptoWalletsDocument,
  IGetCryptoWalletsQuery,
} from '../../graphql/types';

export const useHasWallet = () => {
  const { viewer } = useAuth();
  const client = useGqlClient();

  const [
    getCryptoWallets,
    {
      data: cryptoWalletData,
      loading: cryptoWalletIsLoading,
      error: cryptoWalletQueryErrors,
    },
  ] = useLazyQuery<IGetCryptoWalletsQuery>(GetCryptoWalletsDocument, {
    client,
  });

  useEffect(() => {
    if (viewer?.isAuthenticated) {
      getCryptoWallets({
        variables: { investorProfileId: viewer?.info?.investorProfile?.id },
      });
    }
  }, [
    viewer?.isAuthenticated,
    viewer?.info?.investorProfile?.id,
    getCryptoWallets,
  ]);

  const hasWallet =
    !cryptoWalletQueryErrors &&
    !cryptoWalletIsLoading &&
    !!cryptoWalletData?.cryptoWallets?.nodes.length;

  // A user may have an external wallet. We want to be sure there is a Republic wallet.
  const hasRepublicWallet = !!cryptoWalletData?.cryptoWallets?.nodes.some(
    (wallet) => wallet.republicWallet
  );

  const hasExternalWallet = !!cryptoWalletData?.cryptoWallets?.nodes.some(
    (wallet) => !wallet.republicWallet
  );

  return {
    hasWallet,
    hasRepublicWallet,
    hasExternalWallet,
    cryptoWalletIsLoading,
  };
};
