import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { IRegionSwitcherSettings } from './types';

export interface IRegionSwitcherDialogProps {
  variant: 'modal' | 'drawer';
  targetRegion?: string | null;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (variantId: string) => void;
  regionalSwitcherSettings: IRegionSwitcherSettings;
}

export const RegionSwitcherDialog = ({
  variant,
  targetRegion,
  isOpen,
  onClose,
  onConfirm,
  regionalSwitcherSettings,
}: IRegionSwitcherDialogProps) => {
  const variants = regionalSwitcherSettings.variants;

  const { name } = variants.find((v) => v.id === targetRegion) ?? variants[0];

  const content = (
    <Box>
      <Heading size="republic-h3" my="4">
        Change region to {name}?
      </Heading>
      <Text>
        You are going to the {name} site. You may need to create a new account
        to&nbsp;invest.
      </Text>
    </Box>
  );

  const controls = (
    <ButtonGroup>
      <Button
        variant="republic-primary"
        size="republic-default"
        onClick={() => {
          if (targetRegion) {
            onConfirm(targetRegion);
          }
          onClose();
        }}
      >
        Yes, continue
      </Button>

      <Button
        variant={
          variant === 'modal' ? 'republic-secondary' : 'republic-tertiary'
        }
        size="republic-default"
        minW="40"
        onClick={onClose}
      >
        No
      </Button>
    </ButtonGroup>
  );

  return variant === 'modal' ? (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size="republic-sm"
      isCentered
      variant="republic-dls"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>{content}</ModalBody>
        <ModalFooter justifyContent="start">{controls}</ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody>{content}</DrawerBody>
        <DrawerFooter justifyContent="start">{controls}</DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
