import { ModifierPhases } from '@popperjs/core';

export const getPopperConfig = (isDesktop?: boolean) => ({
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [100, -43],
      },
    },
    {
      name: 'coords',
      enabled: true,
      phase: 'beforeWrite' as ModifierPhases,
      requires: ['computeStyles'],
      fn: ({ state }: { state: any }) => {
        state.styles.popper.transform = `translate3d(${
          isDesktop ? 320 : 202
        }px, ${Math.round(state.rects.reference.y)}px, 0 )`;
      },
    },
  ],
});
