import { SVGProps } from 'react';

import { Email, Flash, Gear } from '@r-client/shared/ui/icons';

import Accredited from './images/logo-accredited.svg';
import Crypto from './images/logo-crypto.svg';
import NoteLogoFull from './images/logo-r-note.svg';
import NoteLogo from './images/logo-r-note-small.svg';
import RealEstate from './images/logo-real-estate.svg';
import VideoGames from './images/logo-video-games.svg';
import Wallet from './images/logo-wallet.svg';
import Startup from './images/startup.svg';

export type TNavigationItem =
  | {
      href: string;
      title: string;
      image?: string;
      fullImage?: string;
      description?: string;
      icon?: React.FC<React.PropsWithChildren<SVGProps<SVGSVGElement>>>;
      nested?: boolean;
      items?: TNavigationItem[];
      trackingParams?: Record<string, string>;
    }
  | undefined;

export const investNavItems = (): TNavigationItem[] => [
  {
    href: '/companies',
    title: 'Startups',
    image: Startup,
    description: 'Invest in vetted private companies',
  },
  {
    href: '/real-estate',
    title: 'Real estate',
    image: RealEstate,
    description: 'Build a global real estate portfolio',
  },
  {
    href: '/culture',
    title: 'Culture',
    image: VideoGames,
    description: 'Invest in what inspires you',
  },
  {
    href: '/crypto',
    title: 'Crypto',
    image: Crypto,
    description: 'Invest in blockchain tech and digital assets',
  },
  {
    href: '/accredited',
    title: 'Deal Room',
    image: Accredited,
    description: 'Access the deal room for accredited investors',
  },
  {
    href: '/wallet',
    title: 'Wallet',
    image: Wallet,
    description: 'Store and manage your investments',
    trackingParams: {
      menu_entry: 'wallet',
      platform: 'web',
    },
  },
  {
    href: '/note',
    title: 'Republic Note',
    image: NoteLogo,
    fullImage: NoteLogoFull,
  },
];

export const featuredInvestorsNavItem: TNavigationItem = {
  href: '/featured-investors',
  title: 'Featured investors',
};

export const secondariesMarketNavItem = {
  href: '/market',
  title: 'Buy & sell assets on the secondary market',
  mobileTitle: 'Buy & sell assets on the secondary market',
};

export const learnNavItems: TNavigationItem[] = [
  {
    href: '/about',
    title: 'About',
  },
  {
    href: '/learn/investors/how-it-works',
    title: 'How it works',
  },
  {
    href: '/investors/why-invest',
    title: 'Why invest',
  },
  {
    href: '/help/category/investing',
    title: 'FAQ',
  },
  {
    href: '/blog',
    nested: true,
    title: 'Blog',
    items: [
      {
        href: '/blog/newest',
        title: 'Newest',
      },
      {
        href: '/blog/superheroes',
        title: 'Superheroes',
      },
      {
        href: '/blog/crypto',
        title: 'Crypto',
      },
      {
        href: '/blog/investor-education',
        title: 'Investor education',
      },
      {
        href: '/blog/founder-resources',
        title: 'Founder resources',
      },
      {
        href: '/blog/real-estate',
        title: 'Real estate',
      },
    ],
  },
  {
    href: '/events',
    title: 'Events',
  },
];

export const raiseCapitalNavItems: TNavigationItem[] = [
  {
    href: '/raise',
    title: 'Raise capital',
  },
  {
    href: '/tokenization',
    title: 'Tokenization',
  },
  {
    href: '/crypto/services',
    title: 'Advisory services',
  },
  {
    href: '/sharedrops',
    title: `Sharedrops™`,
  },
  {
    href: '/partnerships',
    title: 'Partner network',
  },
  {
    href: '/blog/founder-resources',
    title: 'Blog',
  },
];

export const userNavItems = ({
  userSlug,
  mobile,
  isFounder,
  hasWallet,
}: {
  userSlug: string;
  mobile: boolean;
  isFounder?: boolean;
  hasWallet?: boolean;
  republicWalletCta?: boolean;
}): TNavigationItem[] => [
  {
    href: `/@${userSlug}`,
    title: 'My profile',
  },
  isFounder
    ? {
        href: '/campaigns',
        title: 'My campaigns',
      }
    : undefined,
  {
    href: `/portfolio`,
    title: 'My portfolio',
  },
  hasWallet
    ? {
        href: '/portfolio/crypto/assets',
        title: 'My wallet',
      }
    : {
        href: '/wallet',
        title: 'My wallet',
        trackingParams: {
          menu_entry: 'wallet',
          platform: 'web',
        },
      },
  {
    href: `/saved`,
    title: 'Saved deals',
  },
  {
    href: `/invite`,
    title: 'Invite friends',
  },
  {
    href: `/autopilot`,
    title: 'Autopilot',
  },
  ...(mobile
    ? [
        {
          href: `/notifications`,
          title: 'Notifications',
          icon: Flash,
        },
      ]
    : []),
  {
    href: `/messages`,
    title: 'Messages',
    icon: Email,
  },
  {
    href: `/settings`,
    title: 'Settings',
    icon: Gear,
  },
  {
    href: '/logout',
    title: 'Logout',
  },
];
