import type { ReactNode } from 'react';

import { typeCast } from './utils';

export type TPropsRender<T> =
  | undefined
  | false
  | ((model: T) => ReactNode)
  | ReactNode;
export function propsHelper<T>(
  content: TPropsRender<T>,
  model: T,
  def: ReactNode | (() => ReactNode)
): ReactNode {
  if (content === undefined) {
    return def instanceof Function ? def() : def;
  }
  if (content === false) {
    return null;
  }
  if (content instanceof Function) {
    return content(model);
  }
  return content;
}

export function getCN<T extends { main?: string }>(
  props?: string | T,
  name?: keyof T
): string | undefined {
  if (!props) {
    return undefined;
  }
  const n = name ?? 'main';
  if (typeof props === 'string') {
    if (n === 'main') {
      return props;
    }
  }
  return (props as any)[n];
}

export function mergeCN<T1 extends { main?: string }>(
  dest: Partial<Omit<T1, 'main'>>, // make main optional
  source?: undefined | string | T1
): T1 {
  const result =
    source === undefined
      ? dest
      : typeof source === 'string'
      ? { main: source, ...dest }
      : { ...source, ...dest };
  return typeCast(result);
}
