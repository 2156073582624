import React from 'react';

import { compareDesc } from 'date-fns';

import type { IPageInfo } from '@r-client/data/graphql-types';
import { useGqlClient } from '@r-client/shared/data/client';
import { Box, Button, Divider, Text } from '@r-client/shared/ui/core';
import { Flex } from '@r-client/shared/ui/flex';
import { Spinner } from '@r-client/shared/ui/spinner';
import { CHROMATIC_IGNORE } from '@r-client/shared/util/core';

import { NotificationsMarkAsSeenMutation } from '../../graphql/notifications-mark-as-seen-mutation';
import { INotification } from '../../types';
import { groupNotificationsByDate } from '../../utils/group-notifications-by-date';
import { NotificationRow } from '../notification-row/notification-row';

import styles from './notification-list.module.scss';

export interface INotificationListProps {
  notifications: INotification[];
  pageInfo?: IPageInfo;
  grouped?: boolean;
  compact?: boolean;
  loading?: boolean;
  onLoadMore?: () => void;
}

export function NotificationList({
  notifications,
  pageInfo,
  loading,
  onLoadMore,
  grouped,
  compact,
}: INotificationListProps) {
  const client = useGqlClient();

  const onNotificationClick = async (id: string) => {
    const notificationUpdateMutation = new NotificationsMarkAsSeenMutation({
      variables: {
        ids: [id],
      },
      client,
    });
    await notificationUpdateMutation.submit();
  };

  if (loading) {
    return <Spinner size="large" fullscreen />;
  }

  // TODO remove (!notifications) when handler queryError RC-251 https://linear.app/republic/issue/RC-251/handle-error-in-client-watchquery
  if (!notifications || notifications?.length === 0) {
    return (
      <Box p="3brm 1brm" className={styles.borderBox}>
        <Text
          type="h4"
          align="center"
          color="gray60"
          weight="300"
          m="0 0 1.5brm 0"
        >
          You don't have any notifications
        </Text>
        <Text
          align="center"
          color="gray60"
          m="0 auto"
          lineHeight="1.4"
          className={styles.noNotificationsDescription}
        >
          You'll receive notifications when someone follows you, replies or
          likes your posts, or when someone you follow is active on Republic
        </Text>
      </Box>
    );
  }

  return (
    <div>
      {grouped ? (
        <>
          {Object.entries(groupNotificationsByDate(notifications))
            .sort(([dateA], [dateB]) =>
              compareDesc(new Date(dateA), new Date(dateB))
            )
            .map(([date, groupedNotifications], i) => (
              <Box key={i} m="2brm 0 0">
                <Box p="0 1brm" m="1brm 0">
                  <Text
                    className={CHROMATIC_IGNORE}
                    color="gray40"
                    type="bodySmall"
                  >
                    {date.toUpperCase()}
                  </Text>
                </Box>
                {groupedNotifications.map((notification, idx) => (
                  <React.Fragment key={notification.id}>
                    <NotificationRow
                      key={notification.id}
                      notification={notification}
                      onClick={onNotificationClick}
                    />
                    {idx + 1 !== groupedNotifications.length && (
                      <Divider margin="0" />
                    )}
                  </React.Fragment>
                ))}
              </Box>
            ))}
        </>
      ) : (
        <>
          {notifications.map((notification, idx) => (
            <React.Fragment key={notification.id}>
              <NotificationRow
                notification={notification}
                onClick={onNotificationClick}
                compact={compact}
              />
              {idx + 1 !== notifications.length && <Divider margin="0" />}
            </React.Fragment>
          ))}
        </>
      )}
      {!!pageInfo?.hasNextPage && (
        <Flex p="2brm 0 0" justifyContent="center">
          <Button onClick={onLoadMore} loading={loading}>
            View More
          </Button>
        </Flex>
      )}
    </div>
  );
}
