import { memo, useEffect, useRef, useState } from 'react';

import CN from 'clsx';

import { useAnalytics } from '@r-client/shared/data/analytics';
import { useAuth } from '@r-client/shared/feature/auth';
import { InputField } from '@r-client/shared/ui/form';
import {
  CircleCrossSmall,
  SearchNew2,
  SvgIconLight,
} from '@r-client/shared/ui/icons';
import { Spinner } from '@r-client/shared/ui/spinner';
import { isRepublicEmail } from '@r-client/shared/util/core';
import { FieldModel } from '@r-client/shared/util/model';

import styles from './search.module.scss';

type TClassNames = {
  search?: string;
  inputField?: string;
  addon?: string;
};

export interface ISearchProps {
  classNames?: TClassNames;
  isLoading?: boolean;
  autoFocus?: boolean;
  isActive?: boolean;
  field: FieldModel<string>;
  onFocus?: (value: boolean) => void;
  onClick?: VoidFunction;
  'data-testid'?: string;
}

export const Search = memo(function Search({
  classNames,
  isLoading,
  isActive,
  autoFocus = true,
  field,
  onFocus,
  onClick,
  'data-testid': dataTestId,
}: ISearchProps) {
  const [isFocused, setFocus] = useState(isActive);
  const inputRef = useRef<HTMLInputElement>();
  const analytics = useAnalytics();
  const { viewer } = useAuth();

  useEffect(() => {
    !isActive && setFocus(false);
    !isActive && inputRef?.current?.blur();
  }, [isActive]);

  function handleClick() {
    onClick?.();
    analytics.track({
      name: 'global_search_clicked',
      params: {
        is_employee: isRepublicEmail(viewer?.info?.email),
        platform: 'web',
      },
    });
  }

  function handleFocus() {
    setFocus(true);
    onFocus?.(true);
  }

  function onClear() {
    field.value = '';
    inputRef.current?.focus();
  }

  return (
    <InputField
      field={field}
      className={CN(styles.search, classNames?.search, {
        [styles.isFocused]: isFocused,
      })}
      forwardedRef={inputRef}
      inputClassName={CN(
        styles.inputField,
        {
          [styles.isFocused]: isFocused,
        },
        classNames?.inputField
      )}
      addonClassName={CN(styles.addon, classNames?.addon)}
      placeholder={'Search'}
      onFocus={handleFocus}
      onClick={handleClick}
      shadow="withShadow"
      size="xl"
      autoComplete="off-1"
      addon={
        isLoading ? (
          <Spinner animatedSize="tiny" size="tiny" theme="gray" />
        ) : (
          <SvgIconLight
            className={CN(styles.searchIcon, {
              [styles.searchIcon__focused]: isFocused,
            })}
            icon={SearchNew2}
          />
        )
      }
      addonAfter={
        !!field.value &&
        isFocused && (
          <button
            data-testid="clear-button"
            className={CN(styles.crossWrapper)}
            onClick={onClear}
            id="clearButton"
          >
            <SvgIconLight icon={CircleCrossSmall} />
          </button>
        )
      }
      autoFocus={autoFocus}
      data-testid={dataTestId}
    />
  );
});
