import { useCallback, useEffect, useState } from 'react';

import cn from 'clsx';

import { Button } from '@r-client/shared/ui/core';
import { ChevronUp, SvgIconLight } from '@r-client/shared/ui/icons';

import styles from './scroll-top-button.module.scss';

export function ScrollTopButton() {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleVisible = useCallback(() => {
    const scrolled =
      document.body.scrollTop >= document.body.clientHeight / 2.5;

    if (scrolled) {
      setIsVisible(true);
    }

    if (isVisible && !scrolled) {
      setIsVisible(false);
    }
  }, [isVisible]);

  useEffect(() => {
    document.body.addEventListener('scroll', toggleVisible);

    return () => {
      document.body.removeEventListener('scroll', toggleVisible);
    };
  }, [toggleVisible]);

  function handleScrollTop() {
    document.body.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <Button
      data-testid="button"
      shape="circle"
      className={cn(styles.button, {
        [styles.isVisible]: isVisible,
      })}
      onClick={handleScrollTop}
    >
      <SvgIconLight className={styles.icon} icon={ChevronUp} />
    </Button>
  );
}
