import React from 'react';

import CN from 'clsx';

import { IMenuItemProps, List } from '@r-client/shared/ui/core';

import styles from './basic-menu-item.module.scss';

export interface IBasicMenuItemProps extends IMenuItemProps {
  children: React.ReactElement<{
    className?: string;
  }>;
}

export const BasicMenuItem = ({
  children,
  className,
  ...restProps
}: IBasicMenuItemProps) => {
  return (
    <List.Item {...restProps} className={CN(styles.menuItem, className)}>
      {React.cloneElement(children, {
        className: CN(styles.menuItemInner, children.props.className),
      })}
    </List.Item>
  );
};
