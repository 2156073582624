/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseMutationModel,
  IMutationModelOpts,
  useGqlClient,
} from '@r-client/shared/data/client';
import { useLocalObservable } from 'mobx-react-lite';
import {
  INotificationsMarkAsSeenMutation,
  NotificationsMarkAsSeenDocument,
  INotificationsMarkAsSeenMutationVariables,
} from './types';
import { INotificationMarkAsSeenPayload } from '@r-client/data/graphql-types';
export type { INotificationMarkAsSeenPayload };

export class NotificationsMarkAsSeenMutation extends BaseMutationModel<
  INotificationsMarkAsSeenMutation,
  INotificationsMarkAsSeenMutationVariables
> {
  get mutation() {
    return NotificationsMarkAsSeenDocument;
  }
}
export function useNotificationsMarkAsSeenMutation(
  opts?: Omit<
    IMutationModelOpts<INotificationsMarkAsSeenMutationVariables>,
    'client'
  >
) {
  const client = useGqlClient();
  return useLocalObservable(
    () => new NotificationsMarkAsSeenMutation({ ...opts, client })
  );
}
