import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Text,
} from '@chakra-ui/react';

import { ChevronDownIcon, ChevronUpIcon, GlobeIcon } from './icons';
import { RegionSwitcherDialog } from './region-switcher-dialog';
import { RegionSwitcherItem } from './region-switcher-item';
import { IRegionSwitcherSettings } from './types';
import { useRegionSwitcher } from './use-region-switcher';

export interface IRegionSwitcherMobileProps {
  regionalSwitcherSettings: IRegionSwitcherSettings;
}

export const RegionSwitcherMobile = ({
  regionalSwitcherSettings,
}: IRegionSwitcherMobileProps) => {
  const {
    variants,
    activeVariantID,
    onSetVariant,
    onModalClose,
    onConfirmVariant,
    isModalOpen,
    proposedVariant,
  } = useRegionSwitcher(regionalSwitcherSettings);

  const { inlineName } =
    variants.find((v) => v.id === activeVariantID) ?? variants[0];

  return (
    <Box>
      <Accordion allowToggle>
        <AccordionItem p="0">
          {({ isExpanded }) => (
            <>
              <AccordionButton
                _hover={{ bg: 'white' }}
                fontWeight="semibold"
                px="4"
                py="3.5"
              >
                <Center boxSize="9" borderRadius="md" bg="grey.50">
                  <GlobeIcon boxSize="6" />
                </Center>
                <Text
                  as="span"
                  ml="3"
                  textStyle="sm"
                  color="grey.600"
                  fontWeight="normal"
                >
                  You are viewing the {inlineName} site
                </Text>
                <Box ml="auto">
                  {isExpanded ? (
                    <ChevronUpIcon color="grey.600" boxSize="5" />
                  ) : (
                    <ChevronDownIcon color="grey.600" boxSize="5" />
                  )}
                </Box>
              </AccordionButton>
              <AccordionPanel px="0" py="2">
                <Box px="4">
                  <Text textStyle="sm" mb="1">
                    Region preference
                  </Text>
                  <Box>
                    {variants.map((variant) => (
                      <RegionSwitcherItem
                        key={variant.id}
                        mx="-4"
                        pl="6"
                        pr="4"
                        py="2.5"
                        title={variant.name}
                        isActive={variant.id === activeVariantID}
                        onClick={() => onSetVariant(variant)}
                      />
                    ))}
                  </Box>
                </Box>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
      <RegionSwitcherDialog
        isOpen={!!isModalOpen}
        onClose={onModalClose}
        onConfirm={onConfirmVariant}
        variant={'drawer'}
        targetRegion={proposedVariant}
        regionalSwitcherSettings={regionalSwitcherSettings}
      />
    </Box>
  );
};
