/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import * as Types from '@r-client/data/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGetCryptoWalletsQueryVariables = Types.Exact<{
  investorProfileId: Types.Scalars['ID']['input'];
}>;

export type IGetCryptoWalletsQuery = {
  __typename?: 'Query';
  cryptoWallets: {
    __typename?: 'CryptoWalletTypeConnection';
    nodes: Array<{
      __typename?: 'CryptoWalletType';
      id: string;
      userSlug: string;
      wallabyWalletId?: string | null | undefined;
      wallabyUserId?: string | null | undefined;
      republicWallet?: boolean | null | undefined;
      hasPositiveAlgoBalanceForOptin?: boolean | null | undefined;
      zenledgerRedirectUrl?: string | null | undefined;
      deviceFingerprintsToClear?: Array<string> | null | undefined;
      cryptoWalletTokens?:
        | Array<{
            __typename?: 'CryptoWalletTokenType';
            id: string;
            name?: string | null | undefined;
            blockchainId?: string | null | undefined;
            formattedAvailableBalance?: string | null | undefined;
            formattedTotalBalance?: string | null | undefined;
            formattedPendingDistributionAmount?: string | null | undefined;
            formattedLockedBalance?: string | null | undefined;
            primaryAddress?: string | null | undefined;
            tokenLogoUrl?: string | null | undefined;
            createdAt?: string | null | undefined;
            wallabyId?: string | null | undefined;
            xTokenStandard?: string | null | undefined;
            wallabyAssetId?: string | null | undefined;
            symbol?: string | null | undefined;
            isSecondariesTradeEnabled?: boolean | null | undefined;
            isOptinRequired?: boolean | null | undefined;
            cryptoTokenId?: string | null | undefined;
            blockchainTokenId?: string | null | undefined;
            wallabyUserId?: string | null | undefined;
            isNative?: boolean | null | undefined;
          }>
        | null
        | undefined;
    }>;
  };
};

export type IUpdateCryptoWalletsMutationVariables = Types.Exact<{
  input: Types.ICryptoWalletUpdateInput;
}>;

export type IUpdateCryptoWalletsMutation = {
  __typename?: 'Mutation';
  cryptoWalletsUpdate?:
    | {
        __typename?: 'CryptoWalletUpdatePayload';
        wallet?:
          | { __typename?: 'CryptoWalletType'; id: string }
          | null
          | undefined;
        errors?:
          | Array<{ __typename?: 'ValidationError'; message: string }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type IUserMfaEnableMutationVariables = Types.Exact<{
  secretKey: Types.Scalars['String']['input'];
  code: Types.Scalars['String']['input'];
}>;

export type IUserMfaEnableMutation = {
  __typename?: 'Mutation';
  userMfaEnable?:
    | {
        __typename?: 'UserMfaEnablePayload';
        jwt?: string | null | undefined;
        errors?:
          | Array<{ __typename?: 'ValidationError'; message: string }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const GetCryptoWalletsDocument = gql`
  query GetCryptoWallets($investorProfileId: ID!) {
    cryptoWallets(investorProfileId: $investorProfileId) {
      nodes {
        id
        userSlug
        wallabyWalletId
        wallabyUserId
        republicWallet
        hasPositiveAlgoBalanceForOptin
        zenledgerRedirectUrl
        deviceFingerprintsToClear
        cryptoWalletTokens {
          id
          name
          blockchainId
          formattedAvailableBalance
          formattedTotalBalance
          formattedPendingDistributionAmount
          formattedLockedBalance
          primaryAddress
          tokenLogoUrl
          createdAt
          wallabyId
          xTokenStandard
          wallabyAssetId
          symbol
          isSecondariesTradeEnabled
          isOptinRequired
          cryptoTokenId
          blockchainTokenId
          wallabyUserId
          isNative
        }
      }
    }
  }
`;
export type GetCryptoWalletsQueryResult = Apollo.QueryResult<
  IGetCryptoWalletsQuery,
  IGetCryptoWalletsQueryVariables
>;
export const UpdateCryptoWalletsDocument = gql`
  mutation UpdateCryptoWallets($input: CryptoWalletUpdateInput!) {
    cryptoWalletsUpdate(input: $input) {
      wallet {
        id
      }
      errors {
        message
      }
    }
  }
`;
export type IUpdateCryptoWalletsMutationFn = Apollo.MutationFunction<
  IUpdateCryptoWalletsMutation,
  IUpdateCryptoWalletsMutationVariables
>;
export type UpdateCryptoWalletsMutationResult =
  Apollo.MutationResult<IUpdateCryptoWalletsMutation>;
export type UpdateCryptoWalletsMutationOptions = Apollo.BaseMutationOptions<
  IUpdateCryptoWalletsMutation,
  IUpdateCryptoWalletsMutationVariables
>;
export const UserMfaEnableDocument = gql`
  mutation UserMfaEnable($secretKey: String!, $code: String!) {
    userMfaEnable(input: { secretKey: $secretKey, code: $code }) {
      errors {
        message
      }
      jwt
    }
  }
`;
export type IUserMfaEnableMutationFn = Apollo.MutationFunction<
  IUserMfaEnableMutation,
  IUserMfaEnableMutationVariables
>;
export type UserMfaEnableMutationResult =
  Apollo.MutationResult<IUserMfaEnableMutation>;
export type UserMfaEnableMutationOptions = Apollo.BaseMutationOptions<
  IUserMfaEnableMutation,
  IUserMfaEnableMutationVariables
>;
