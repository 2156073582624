import { FunctionComponent } from 'react';

import { Button } from '@chakra-ui/react';

import { IInvestmentStateEnum } from '@r-client/data/graphql-types';
import { useAnalytics } from '@r-client/shared/data/analytics';
import { Text } from '@r-client/shared/ui/core';
import { Flex, FlexItem } from '@r-client/shared/ui/flex';
import { SvgIconLight, Warning } from '@r-client/shared/ui/icons';

import { IBannerQuery } from '../../graphql/types';

import styles from './action-required-banner.module.scss';

export interface IActionRequiredBannerProps {
  className?: string;
  daysLeft?: number;
  // TODO: https://linear.app/republic/issue/RGQL-293/investment-type-needs-state-value
  investment?: Extract<
    IBannerQuery['banner'],
    { __typename: 'ActionRequiredBanner' }
  >['investment'];
}

export const ActionRequiredBanner: FunctionComponent<
  React.PropsWithChildren<IActionRequiredBannerProps>
> = ({ daysLeft, investment }) => {
  const analytics = useAnalytics();
  let daysLeftMessage = '';
  let actionMessage = '';

  if (investment && (daysLeft || daysLeft === 0) && daysLeft <= 7) {
    if (daysLeft <= 0) {
      daysLeftMessage = "Time's Up!!!";
    } else {
      daysLeftMessage = `${daysLeft} days left`;
    }
  } else {
    daysLeftMessage = 'Action required';
  }

  if (!investment) {
    actionMessage = 'Your action is required to complete your verification';
  } else if (investment?.state === IInvestmentStateEnum.Reserved) {
    actionMessage = `Your reservation in ${investment.offering.companyName} requires your attention`;
  } else if ((daysLeft || daysLeft === 0) && daysLeft <= 7) {
    actionMessage =
      'Your action is required to avoid cancellation of your investments';
  } else {
    actionMessage = `Important action item related to your investment in ${investment.offering.companyName}`;
  }

  const analyticsTrackBannerClicked = () => {
    analytics.track({
      name: 'website_banner_clicked',
      params: {
        banner_name: 'ActionRequiredBanner',
        button_name: 'action_required',
        extra_data: {
          companyName: investment?.offering.companyName ?? undefined,
          offeringSlug: investment?.offering.slug ?? undefined,
          daysLeft,
        },
      },
    });
  };

  return (
    <Flex
      direction="column"
      alignItems={{
        xs: 'center',
        sm: 'flex-start',
        md: 'center',
        lg: 'center',
      }}
      justifyContent="center"
      bg="gray-f2"
      p="1.5brm 1brm"
      className={styles.actionWrapper}
    >
      <FlexItem grow={1}>
        <Flex textAlign="center" justifyContent={{ md: 'center' }}>
          <Text
            bold
            color="error"
            m={{
              xs: '0 0 1brm 0',
              sm: '0 0.5brm 0 0',
              md: '0 0.5brm 0 0',
              lg: '0 0.5brm 0 0',
            }}
          >
            <SvgIconLight icon={Warning} />
            <Text as="span" className={styles.daysLeftMessage}>
              {daysLeftMessage}
            </Text>
          </Text>
          <Text weight="400" m={{ xs: '0 0 1brm 0', sm: '0' }}>
            {actionMessage}
          </Text>
        </Flex>
      </FlexItem>
      <Button
        as="a"
        href={
          investment ? `/portfolio/${investment?.offering.slug}` : '/settings'
        }
        className={styles.action}
        onClick={analyticsTrackBannerClicked}
        variant="republic-default"
        size="md"
      >
        View {investment ? `${investment.offering.companyName}` : 'Settings'}
      </Button>
    </Flex>
  );
};
