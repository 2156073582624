import React, { forwardRef, useEffect } from 'react';

import CN from 'clsx';

import {
  MONITORING_EXCLUDE_CLASS,
  typeCast,
  useTheme,
} from '@r-client/shared/util/core';

import { getInputMetadata } from '../helpers';
import { E_VALIDATION_STATUS } from '../types';

import styles from './input.module.scss';

type ReactInput = React.InputHTMLAttributes<HTMLInputElement>;

export interface IInputProps extends Omit<ReactInput, 'size' | 'autoFocus'> {
  className?: string;
  addon?: React.ReactNode | React.ReactNode[];
  addonClassName?: string;
  addonPosition?: 'begin' | 'end';
  addonAfter?: React.ReactNode | React.ReactNode[];
  autoComplete?: string;
  htmlId?: string;
  inputClassName?: string;
  status?: E_VALIDATION_STATUS | undefined;
  name?: string;
  size?: 'small' | 'xl' | 'large' | 'regular';
  squareCorners?: 'left' | 'right';
  shadow?: 'default' | 'withShadow';
  autoFocus?: boolean | number; // true - should be focused immediately, number - timeout for focus
  disableMonitoring?: boolean; // true - should be hidden from monitoring services like FullStory
  disableWebkitAutofillColor?:
    | `rgb(${number}, ${number}, ${number})`
    | `#${string}`;
  forwardedRef?: React.MutableRefObject<HTMLInputElement | undefined>;
}

export const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      addon,
      addonPosition = 'begin',
      addonAfter,
      className,
      htmlId,
      inputClassName,
      addonClassName,
      size = 'regular',
      squareCorners,
      autoFocus,
      status,
      disableMonitoring,
      disableWebkitAutofillColor,
      style,
      shadow = 'default',
      forwardedRef,
      ...props
    },
    ref
  ) => {
    const { theme } = useTheme();
    const inputRef = React.useRef<HTMLInputElement>();
    const conditionalRef = forwardedRef || inputRef;
    React.useImperativeHandle(ref, () => conditionalRef.current!);

    useEffect(() => {
      if ((autoFocus || autoFocus === 0) && conditionalRef) {
        let timeoutId: number | undefined = typeCast(
          setTimeout(
            () => {
              conditionalRef.current?.focus();
              timeoutId = undefined;
            },
            typeof autoFocus === 'number' ? autoFocus : 0
          )
        );
        return () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        };
      }
    }, []);
    const newStyles = disableWebkitAutofillColor
      ? { ...style, '--disable-autofill-color': disableWebkitAutofillColor }
      : style;

    return (
      <div
        className={CN(
          className,
          styles.textInputWrap,
          {
            [styles.end]: addonPosition === 'end',
            [styles.begin]: addonPosition === 'begin',
          },
          !!size && [styles[size]]
        )}
      >
        {addon ? (
          <div
            className={CN(styles.addon, addonClassName, {
              [styles.end]: addonPosition === 'end',
              [styles.begin]: addonPosition === 'begin',
            })}
          >
            {addon}
          </div>
        ) : null}
        <input
          id={htmlId}
          ref={conditionalRef}
          className={CN(
            disableMonitoring ? MONITORING_EXCLUDE_CLASS : undefined,
            inputClassName,
            styles.textInput,
            {
              [styles.disableAutofill]: !!disableWebkitAutofillColor,
              [styles.wl]: theme === 'WL',
              [styles.regular]: size === 'regular',
              [styles.large]: size === 'large',
              [styles.small]: size === 'small',
              [styles.xl]: size === 'xl',
              [styles.statusSuccess]: status === E_VALIDATION_STATUS.success,
              [styles.statusWarning]: status === E_VALIDATION_STATUS.warning,
              [styles.statusError]: status === E_VALIDATION_STATUS.error,
              [styles.withAddon]: addon,
              [styles.squareCornersLeft]: squareCorners === 'left',
              [styles.squareCornersRight]: squareCorners === 'right',
              [styles.withShadow]: shadow === 'withShadow',
            }
          )}
          style={newStyles}
          {...getInputMetadata(status === E_VALIDATION_STATUS.error)}
          {...props}
        />
        {addonAfter}
      </div>
    );
  }
);

Input.displayName = 'Input';
