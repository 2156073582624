import { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import { IBannerTypeEnum } from '@r-client/data/graphql-types';
import { useAnalytics } from '@r-client/shared/data/analytics';
import { useAuth } from '@r-client/shared/feature/auth';
import { SlideAnimation } from '@r-client/shared/ui/animation';
import { getSessionStorage } from '@r-client/shared/util/core';

import { DismissedBannersResource } from '../../data/dismissed-banners-resource';
import { useBannerQuery } from '../../graphql/banner-query';
import { useBannerContext } from '../../providers/banner-provider';
import { getAnalyticsBannerData } from '../../utils/util';
import { BannerDisplayer } from '../banner-displayer/banner-displayer';

enum E_BATCH_GROUP {
  Banners = 'BANNERS',
}

export const BannerWrapper = observer(function BannerWrapper() {
  const { bannerLoaded, setBannerLoaded } = useBannerContext();
  const [duration, setDuration] = useState(500);
  const router = useRouter();
  const analytics = useAnalytics();
  const { isLoading } = useAuth();

  const bannerQuery = useBannerQuery({
    variables: {
      path: router.pathname,
      dismissedBanners: new DismissedBannersResource(
        getSessionStorage()
      ).getAll(),
    },
    context: {
      batchGroup: E_BATCH_GROUP.Banners,
    },
    lazy: true,
  });

  const handleDismissUserBanner = async (bannerType: IBannerTypeEnum) => {
    const dismissedBannersResource = new DismissedBannersResource(
      getSessionStorage()
    );
    dismissedBannersResource.add(bannerType);

    const eventData = getAnalyticsBannerData(bannerQuery.data);
    analytics.track({
      name: 'website_banner_closed',
      params: { ...eventData },
    });

    await bannerQuery.refetch({
      dismissedBanners: dismissedBannersResource.getAll(),
    });
  };

  useEffect(() => {
    // Wait to query until we are confirmed we have a user or not to prevent "flashing"
    if (!isLoading) {
      bannerQuery.subscribe();
    } else {
      bannerQuery.refetch();
    }
    if (bannerLoaded) {
      setDuration(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (!bannerQuery.isLoading && bannerQuery.data?.banner?.__typename) {
      const eventData = getAnalyticsBannerData(bannerQuery.data);
      analytics.track({
        name: 'website_banner_shown',
        params: { ...eventData },
      });
    }
  }, [analytics, bannerQuery.data, bannerQuery.isLoading]);

  return (
    <SlideAnimation
      show={
        !!bannerQuery.data?.banner &&
        !bannerQuery.isLoading &&
        !bannerQuery.isRejected
      }
      duration={duration}
      onAnimationEnd={() => {
        if (duration === 500) {
          setDuration(0);
          setBannerLoaded(true);
        }
      }}
    >
      <BannerDisplayer
        data={bannerQuery.data}
        onDismissUserBanner={handleDismissUserBanner}
        refetch={bannerQuery.refetch}
      />
    </SlideAnimation>
  );
});
