import { useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { getDocument } from '@r-client/shared/util/core';

import { IRegion, IRegionSwitcherSettings } from './types';

export function useRegionSwitcher(
  regionalSwitcherSettings: IRegionSwitcherSettings
) {
  const location = getDocument()?.location;
  const { variants, activeVariantID } = regionalSwitcherSettings;
  const [proposedVariant, setVariant] = useState<string | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSetVariant = (variant: IRegion) => {
    if (variant.id === activeVariantID) {
      return;
    }
    if (variant.needsConfirmation) {
      setVariant(variant.id);
      onOpen();
    } else {
      onConfirmVariant(variant.id);
    }
  };

  const onConfirmVariant = (variantId: string) => {
    const { url, onChange } =
      variants.find((v) => v.id === variantId) ?? variants[0];
    if (onChange) {
      onChange();
    }
    if (location && url) {
      location.href = url;
    }
  };

  return {
    variants,
    isModalOpen: !!proposedVariant && isOpen,
    proposedVariant,
    activeVariantID,

    onSetVariant,
    onModalClose: onClose,
    onConfirmVariant,
  };
}
