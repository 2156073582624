/* eslint-disable max-lines */
import { useCallback, useState } from 'react';

import CN from 'clsx';

import {
  IBannerTypeEnum,
  IClosingSoonBanner,
  IOffering,
} from '@r-client/data/graphql-types';
import { useAnalytics } from '@r-client/shared/data/analytics';
import { Avatar, Box, Button, Link, Text } from '@r-client/shared/ui/core';
import { Flex, FlexItem } from '@r-client/shared/ui/flex';
import { CrossMedium, SvgIconLight } from '@r-client/shared/ui/icons';
import { useIsUnmounted } from '@r-client/shared/util/core';

import { Countdown } from '../countdown/countdown';

import styles from './closing-soon-banner.module.scss';

export interface IClosingSoonBannerProps {
  offeringClosingFirst: IClosingSoonBanner['offeringClosingFirst']['id'];
  offerings: Pick<IOffering, 'id' | 'logoUrl' | 'companyName' | 'slug'>[];
  timeLeftInSec: IClosingSoonBanner['timeLeft'];
  onClose: (bannerType: IBannerTypeEnum) => Promise<void>;
}

export function ClosingSoonBanner({
  offeringClosingFirst,
  offerings,
  timeLeftInSec,
  onClose,
}: IClosingSoonBannerProps) {
  const analytics = useAnalytics();
  const numOfferings = offerings?.length;
  const closingFirstOffering = offerings.filter(
    (offering) => offering.id === offeringClosingFirst
  )[0];
  const [bannerIsDismissing, setBannerIsDismissing] = useState(false);
  const isUnmounted = useIsUnmounted();

  const viewAndConfirmHandler = () => {
    analytics.track({
      name: 'website_banner_clicked',
      params: {
        banner_name: 'ClosingSoonBanner',
        button_name: 'view_and_confirm',
        extra_data: {
          companyName: closingFirstOffering.companyName ?? undefined,
          offeringSlug: closingFirstOffering.slug ?? undefined,
          daysLeft: Math.floor(timeLeftInSec / (3600 * 24)),
        },
      },
    });
  };

  const handleClose = useCallback(async () => {
    setBannerIsDismissing(true);
    await onClose(IBannerTypeEnum.ClosingSoon);
    if (!isUnmounted.current) {
      setBannerIsDismissing(false);
    }
  }, [onClose, isUnmounted]);

  const closeButton = (
    <SvgIconLight
      icon={CrossMedium}
      className={CN(styles.closeIcon, {
        [styles.closeIconDisabled]: bannerIsDismissing,
      })}
      dataTestId="close-banner"
      onClick={bannerIsDismissing ? undefined : handleClose}
    />
  );

  const MobileContents = (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      className={styles.bannerContainer}
      hidden={{ sm: false, lg: true }}
      data-testid="closing-soon-banner-mobile"
    >
      <FlexItem basis="95%">
        <Flex alignItems="center" justifyContent="center">
          {numOfferings === 1 && (
            <>
              <Text className={styles.fireEmoji}>🔥</Text>
              <Link
                onClick={viewAndConfirmHandler}
                href={
                  closingFirstOffering.slug
                    ? `/${closingFirstOffering.slug}`
                    : '/companies?sorting=closing-soon'
                }
                className={styles.noDecoration}
              >
                <Text as="span" className={styles.dealsLink}>
                  {closingFirstOffering.companyName} closing in&nbsp;
                </Text>
              </Link>
              <Text weight="600">
                <Countdown timeLeftInSec={timeLeftInSec!} />
              </Text>
            </>
          )}
          {numOfferings > 1 && (
            <>
              <Link
                onClick={viewAndConfirmHandler}
                href="/companies?sorting=closing-soon"
                className={styles.noDecoration}
              >
                <Flex alignItems="center">
                  <Text className={styles.fireEmoji}>🔥 </Text>
                  <Text className={styles.closingText}>
                    {numOfferings} deals closing in&nbsp;
                  </Text>
                </Flex>
              </Link>
              <Text weight="600">
                <Countdown timeLeftInSec={timeLeftInSec!} />
              </Text>
            </>
          )}
        </Flex>
      </FlexItem>

      {closeButton}
    </Flex>
  );

  const TabletDesktopContents = (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      className={styles.bannerContainer}
      hidden={{ sm: true }}
      data-testid="closing-soon-banner-desktop"
    >
      <Flex alignItems="center">
        <Text className={styles.fireEmoji}>🔥</Text>
        <Text className={styles.closingText}>Deals closing soon!</Text>
      </Flex>
      <Flex alignItems="center" justifyContent="flex-end">
        {numOfferings === 1 && (
          <>
            <Text weight="600">
              <Countdown timeLeftInSec={timeLeftInSec!} />
            </Text>
            &nbsp;left to invest in&nbsp;
            <Box>
              <Link
                onClick={viewAndConfirmHandler}
                href={
                  closingFirstOffering.slug
                    ? `/${closingFirstOffering.slug}`
                    : '/companies?sorting=closing-soon'
                }
              >
                <Avatar
                  url={closingFirstOffering.logoUrl || undefined}
                  size="xSmall"
                  className={styles.avatar}
                  outline={false}
                />
              </Link>
            </Box>
            <Link
              onClick={viewAndConfirmHandler}
              href={
                closingFirstOffering.slug
                  ? `/${closingFirstOffering.slug}`
                  : '/companies?sorting=closing-soon'
              }
              className={styles.noDecoration}
            >
              <Text className={styles.dealsLink}>
                {closingFirstOffering.companyName}
              </Text>
            </Link>
          </>
        )}
        {numOfferings > 1 && (
          <>
            {/* This container reverses the row and mapped array to render first avatar on top and subsequent avatars under without using z-index */}
            <Flex direction="row-reverse">
              {offerings
                .slice(0, 5)
                .map((offering) => {
                  return (
                    <Avatar
                      outline={false}
                      url={offering.logoUrl || undefined}
                      size="xSmall"
                      className={styles.avatarStack}
                      key={offering.companyName}
                    />
                  );
                })
                .reverse()}
            </Flex>
            <Link
              onClick={viewAndConfirmHandler}
              href="/companies?sorting=closing-soon"
              className={styles.noDecoration}
            >
              <Text weight="600">
                <Countdown timeLeftInSec={timeLeftInSec} />
              </Text>
            </Link>
            &nbsp;left to invest in&nbsp;
            <Link
              href="/companies?sorting=closing-soon"
              variant={[Link.variants['noUnderline']]}
            >
              <Text className={styles.dealsLink}>{numOfferings} deals</Text>
            </Link>
          </>
        )}
      </Flex>
      <FlexItem>
        <Button
          onClick={viewAndConfirmHandler}
          type="default-thin"
          className={styles.button}
          href={
            numOfferings === 1
              ? `/${closingFirstOffering.slug}`
              : '/companies?sorting=closing-soon'
          }
        >
          {numOfferings === 1
            ? `View ${closingFirstOffering.companyName}`
            : `View closing soon`}
        </Button>

        {closeButton}
      </FlexItem>
    </Flex>
  );
  return (
    <>
      {/* Query from server will return between 1 and 5 offerings */}
      {MobileContents}
      {TabletDesktopContents}
    </>
  );
}
