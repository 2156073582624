import React, { FC } from 'react';

import classNames from 'clsx';
import NextLink from 'next/link';

import { dataRole, isSpaUrl, useTheme } from '@r-client/shared/util/core';

import styles from './link.module.scss';

export enum E_VARIANTS {
  underline = 'underline',
  unstyled = 'unstyled',
  lite = 'lite',
  liteBlue = 'liteBlue',
  inherit = 'inherit',
  inheritedUnderline = 'inheritedUnderline',
  rightArrow = 'rightArrow',
  rightArrowHover = 'rightArrowHover',
  inheritedActiveColor = 'inheritedActiveColor',
  muted = 'muted',
  white = 'white',
  dark = 'dark',
  noUnderline = 'noUnderline',
}

export interface ILinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: E_VARIANTS | E_VARIANTS[];
  inherit?: boolean;
  role?: string;
}

const LinkComponent: FC<React.PropsWithChildren<ILinkProps>> = ({
  children,
  className,
  href,
  variant,
  inherit,
  role,
  ...props
}) => {
  const { theme } = useTheme();
  const variantClasses =
    !!variant &&
    (Array.isArray(variant)
      ? variant.map((variantName) => styles[variantName])
      : styles[variant]);
  const validHref = !href ? '/' : href;
  const isSPA = isSpaUrl(validHref);
  const anchorComponent = (
    <a
      {...props}
      className={classNames(className, styles.link, variantClasses, {
        [styles.inherit]: inherit,
        [styles.wl]: theme === 'WL',
      })}
      href={validHref}
      {...dataRole(role)}
    >
      {children}
    </a>
  );

  // TODO: add ticket to migrate it later
  return isSPA ? (
    <NextLink legacyBehavior href={validHref}>
      {anchorComponent}
    </NextLink>
  ) : (
    <>{anchorComponent}</>
  );
};

export const Link = LinkComponent as typeof LinkComponent & {
  variants: typeof E_VARIANTS;
};
Link.variants = E_VARIANTS;
Link.displayName = 'Link';
