import React from 'react';

import cn from 'clsx';

import styles from './layout.module.scss';

export type TLayoutConfig = {
  noMinHeight?: boolean;
  withGrayLightBg?: boolean;
};

export interface ILayoutProps {
  header: React.ReactNode;
  footer: React.ReactNode;
  config?: TLayoutConfig;
}

export const Layout: React.FC<React.PropsWithChildren<ILayoutProps>> = (
  props
) => {
  return (
    <div
      className={cn(styles.main, {
        [styles.withGrayLightBg]: props.config?.withGrayLightBg,
      })}
    >
      <main className={!props?.config?.noMinHeight ? styles.content : ''}>
        {props.header}
        {props.children}
      </main>
      {props.footer}
    </div>
  );
};
