import { useEffect, useState } from 'react';

import { typeCast } from '@r-client/shared/util/core';

export const useDelayedSwitcher = (
  value: boolean,
  {
    delayIn = 0,
    delayOut = 0,
  }: {
    delayIn?: number;
    delayOut?: number;
  }
): [boolean, (value: boolean) => void] => {
  const [controlledValue, setControlledValue] = useState<boolean>(value);
  const [resultValue, setResultValue] = useState<boolean>(value);

  const setValue = (newValue: boolean, delay: number): number | undefined => {
    if (delay > 0) {
      return typeCast(setTimeout(() => setResultValue(newValue), delay));
    } else {
      setResultValue(newValue);
      return;
    }
  };

  useEffect(() => {
    let timerIn: number | undefined;
    let timerOut: number | undefined;
    if (controlledValue) {
      timerIn = setValue(controlledValue, delayIn);
    } else {
      timerOut = setValue(controlledValue, delayOut);
    }
    return () => {
      timerIn && clearTimeout(timerIn);
      timerOut && clearTimeout(timerOut);
    };
  }, [controlledValue, delayIn, delayOut]);

  return [resultValue, setControlledValue];
};
