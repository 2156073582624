import { Box, Flex } from '@chakra-ui/react';
import { AnimatePresence, useAnimate } from 'framer-motion';

import { RepublicLogo } from '@r-client/republic/feature/brand';
import { MaybeValue, Viewer } from '@r-client/shared/feature/auth';

import { INavigationData } from '../../config/types';
import { HEADER_THEMES } from '../../types';
import { getBackgroundColor } from '../../utils/get-background-color';
import { getLogo } from '../../utils/get-logo';
import { getTextColor } from '../../utils/get-text-color';
import { GlobalSearch } from '../global-search/';
import { IRegionSwitcherSettings } from '../region-switcher/types';
import { UserMenu } from '../user-menu/user-menu';
import { PopoverMenu } from './popover-menu/popover-menu';
import { useNavigationEntity } from './use-navigation-entity';

interface IDesktopHeaderProps {
  navigationData: INavigationData[];
  theme: HEADER_THEMES;
  overlay: boolean;
  sticky: boolean;
  regionSwitcherSettings?: IRegionSwitcherSettings;
  user: {
    viewer: MaybeValue<Viewer>;
    isLoading?: boolean;
  };
  onNotificationsClick: () => void;
}

export const DesktopHeader = ({
  navigationData,
  theme,
  overlay,
  sticky,
  user,
  regionSwitcherSettings,
  onNotificationsClick,
}: IDesktopHeaderProps) => {
  const [menuContentScope] = useAnimate();
  const { selectedEntityIndex, actions, isOpen } = useNavigationEntity();

  return (
    <Box
      bgColor={isOpen ? 'white' : getBackgroundColor(theme)}
      position="relative"
      zIndex="modal"
      display={{ base: 'none', md: 'block' }}
      borderBottom="1px solid"
      borderColor={isOpen || theme === 'light' ? 'grey.100' : 'transparent'}
      {...(overlay && overlayHeaderStyles)}
      {...(sticky && stickyHeaderStyles)}
    >
      <Box maxW="1152px" m="auto" px="4" background="inherit">
        <Flex
          alignItems="center"
          background="inherit"
          position="relative"
          zIndex="modal" // zIndex needed to ensure the menu content animation happens under the header (prevents flickering)
        >
          <Box mr="2">
            <RepublicLogo
              type={getLogo(isOpen ? 'light' : theme)}
              logoProps={{ height: '30', width: '122' }}
            />
          </Box>

          <Flex
            ml="8"
            gap="12"
            onMouseEnter={actions.cancelCloseAction}
            onMouseLeave={actions.closeCurrentSection}
          >
            {navigationData.map((navItem, index) => (
              <Box
                as="button"
                key={navItem.navigationEntry}
                py="23px"
                color={isOpen ? 'grey.600' : getTextColor(theme, '900')}
                borderBottom="2px solid transparent"
                {...(selectedEntityIndex === index && {
                  borderBottomColor: 'blue.500',
                  color: isOpen ? 'grey.900' : getTextColor(theme, '900'),
                })}
                onClick={() => actions.openSection(index)}
                onMouseEnter={() =>
                  actions.openSection(index, menuContentScope)
                }
                onFocus={() => actions.openSection(index)}
                onBlur={actions.closeCurrentSection}
              >
                {navItem.navigationEntry}
              </Box>
            ))}
          </Flex>
          <Box ml="10">
            <GlobalSearch />
          </Box>
          <Box ml="auto">
            <UserMenu
              theme={isOpen ? 'light' : theme}
              regionSwitcherSettings={regionSwitcherSettings}
              user={user}
              onNotificationsClick={onNotificationsClick}
            />
          </Box>
        </Flex>
        <AnimatePresence>
          {isOpen && (
            <PopoverMenu
              navigationData={navigationData}
              selectedEntityIndex={selectedEntityIndex}
              actions={actions}
              animationScope={menuContentScope}
            />
          )}
        </AnimatePresence>
      </Box>
    </Box>
  );
};

const overlayHeaderStyles = {
  position: 'absolute',
  top: '0',
  right: '0',
  left: '0',
} as const;

const stickyHeaderStyles = {
  position: 'sticky',
  top: '0',
} as const;
