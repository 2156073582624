import { Box, Link, Text } from '@r-client/shared/ui/core';

import styles from './disclaimer.module.scss';

export const Main = function Main() {
  return (
    <Box className={styles.mainDisclaimerBox}>
      <Text m="0 0 1brm 0">
        The testimonials, statements, and opinions presented here are applicable
        to the individuals depicted. Unique experiences and past performances do
        not guarantee future results.
      </Text>
      <Text m="0 0 1brm 0">
        This site (the &ldquo;Site&ldquo;) is owned and maintained by OpenDeal
        Inc., which is not a registered broker-dealer. OpenDeal Inc. does not
        give investment advice, endorsement, analysis or recommendations with
        respect to any securities. All securities listed here are being offered
        by, and all information included on this Site is the responsibility of,
        the applicable issuer of such securities. The intermediary facilitating
        the offering will be identified in such offering’s documentation.
      </Text>
      <Text m="0 0 1brm 0">
        All related securities activity is conducted by OpenDeal Broker LLC a
        registered broker-dealer, Member of{' '}
        <Link
          href={'https://www.finra.org/'}
          variant={Link.variants.underline}
          inherit
        >
          FINRA
        </Link>{' '}
        |{' '}
        <Link
          href={'https://www.sipc.org/'}
          variant={Link.variants.underline}
          inherit
        >
          SiPC
        </Link>
        , an affiliate of OpenDeal Inc. and OpenDeal Portal LLC, located at 149
        5th Avenue, 10th Floor, New York, NY 10010. Please check our background
        on FINRA’s{' '}
        <Link
          href={'https://brokercheck.finra.org/firm/summary/291387'}
          variant={Link.variants.underline}
          inherit
        >
          BrokerCheck
        </Link>
        .
      </Text>
      <Text m="0 0 1brm 0">
        Certain pages discussing the mechanics and providing educational
        materials regarding regulation crowdfunding offerings may refer to
        OpenDeal Broker LLC and OpenDeal Portal LLC collectively as “Republic”,
        solely for explanatory purposes.
      </Text>
      <Text m="0 0 1brm 0">
        Neither OpenDeal Inc., OpenDeal Portal LLC nor OpenDeal Broker LLC make
        investment recommendations and no communication, through this Site, or
        in any other medium, should be construed as a recommendation for any
        security offered on or off this investment platform. Investment
        opportunities posted on this Site are private placements of securities
        that are not publicly traded, involve a high degree of risk, may lose
        value including the total loss of invested capital, are subject to
        holding period requirements and are intended for investors who do not
        need a liquid investment. Past performance is not indicative of future
        results. Investors must be able to afford the loss of their entire
        investment. Only qualified investors, who understand the risks of
        early-stage investment and who meet the Republic's investment criteria
        may invest. Investors may be restricted to only Accredited Investors or
        non-U.S. persons, to invest in offerings hosted by OpenDeal Broker.
        Neither OpenDeal Inc., OpenDeal Portal LLC nor OpenDeal Broker LLC, nor
        any of their officers, directors, agents and employees make any
        warranty, express or implied, of any kind whatsoever related to the
        adequacy, accuracy or completeness of any information on this Site or
        the use of information on this site. Offers to sell securities can only
        be made through official offering documents that contain important
        information about the investment and the issuers, including risks.
        Investors should carefully read the offering documents. Investors should
        conduct their own due diligence and are encouraged to consult with their
        tax, legal and financial advisors.
      </Text>
      <Text m="0 0 1brm 0">
        By accessing the Site and any pages thereof, you agree to be bound by
        the OpenDeal Portal’s{' '}
        <Link href={'/terms'} variant={Link.variants.underline} inherit>
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link href={'/privacy'} variant={Link.variants.underline} inherit>
          Privacy Policy
        </Link>{' '}
        and/or OpenDeal Broker’s{' '}
        <Link
          href={'/capital_r/download/terms'}
          variant={Link.variants.underline}
          inherit
        >
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link
          href={'/capital_r/download/privacy_policy'}
          variant={Link.variants.underline}
          inherit
        >
          Privacy Policy
        </Link>
        . Please also see OpenDeal Broker’s{' '}
        <Link
          href={'/capital_r/download/business_continuity_plan'}
          variant={Link.variants.underline}
          inherit
        >
          Business Continuity Plan
        </Link>{' '}
        and{' '}
        <Link
          href={'/capital_r/download/additional_risk'}
          variant={Link.variants.underline}
          inherit
        >
          Additional Risk Disclosures
        </Link>
        . All issuers offering securities under regulation crowdfunding as
        hosted by OpenDeal Portal LLC are listed on the{' '}
        <Link
          href={'/reg-cf-offerings'}
          variant={Link.variants.underline}
          inherit
        >
          All Companies Page
        </Link>
        . The inclusion or exclusion of an issuer on the{' '}
        <Link href={'/companies'} variant={Link.variants.underline} inherit>
          Platform Page
        </Link>{' '}
        and/or Republic’s{' '}
        <Link href={'/'} variant={Link.variants.underline} inherit>
          Homepage
        </Link>
        , which includes offerings conducted under regulation crowdfunding as
        well as other exemptions from registration, is not based upon any
        endorsement or recommendation by OpenDeal Inc, OpenDeal Portal LLC, or
        OpenDeal Broker LLC, nor any of their affiliates, officers, directors,
        agents, and employees. Rather, issuers of securities may, in their sole
        discretion, opt-out of being listed on the{' '}
        <Link href={'/companies'} variant={Link.variants.underline} inherit>
          Platform Page
        </Link>{' '}
        and{' '}
        <Link href={'/'} variant={Link.variants.underline} inherit>
          Homepage
        </Link>
        .
      </Text>
      <Text align="center" m="0 0 1brm 0" p="1brm 0" weight="500">
        Investors should verify any issuer information they consider important
        before making an investment.
      </Text>
      <Text m="0 0 1brm 0">
        Investments in private companies are particularly risky and may result
        in total loss of invested capital. Past performance of a security or a
        company does not guarantee future results or returns. Only investors who
        understand the{' '}
        <Link href={'/risks'} variant={Link.variants.underline} inherit>
          risks of early stage investment
        </Link>{' '}
        and who meet the Republic's{' '}
        <Link
          href={'/help/can-i-invest'}
          variant={Link.variants.underline}
          inherit
        >
          investment criteria
        </Link>{' '}
        may invest.
      </Text>
      <Text m="0 0 1brm 0">
        Neither OpenDeal Inc., OpenDeal Portal LLC nor OpenDeal Broker LLC
        verify information provided by companies on this Site and makes no
        assurance as to the completeness or accuracy of any such information.
        Additional information about companies fundraising on the Site can be
        found by searching the{' '}
        <Link
          href={'https://www.sec.gov/edgar/searchedgar/companysearch.html'}
          variant={Link.variants.underline}
          inherit
        >
          EDGAR database
        </Link>
        , or the offering documentation located on the Site when the offering
        does not require an EDGAR filing.
      </Text>
      <Text m="0 0 1brm 0">
        Republic and its affiliates are not and do not operate or act as a bank.
        Certain banking services are provided by BankProv, member FDIC / member
        DIF. FDIC coverage only applies in the event of bank failure. Digital
        (crypto) assets and investment products are not insured by the FDIC, may
        lose value, and are not deposits or other obligations of BankProv and
        are not guaranteed by BankProv. Terms and conditions apply.
      </Text>
    </Box>
  );
};
