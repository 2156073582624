/* eslint max-lines: off */
import { MouseEvent } from 'react';

import {
  Flex as ChakraFlex,
  Flex,
  Grid,
  GridItem,
  Img,
  Text as ChakraText,
} from '@chakra-ui/react';
import CN from 'clsx';

import { E_LOGO_TYPES, RepublicLogo } from '@r-client/republic/feature/brand';
import { SocialIcon } from '@r-client/republic/ui/core';
import { useAnalytics } from '@r-client/shared/data/analytics';
import { DollarIcon } from '@r-client/shared/ui/chakra-ui-icons';
import { Box, Container, Link, Text } from '@r-client/shared/ui/core';

import { DisclaimerContainer } from './disclaimer/disclaimer-container';
import { Main } from './disclaimer/main';
import { FooterLinks } from './footer-links/footer-links';
import appStore from './images/app-store.png';
import { ReactComponent as CreditCards } from './images/credit_cards.svg';
import androidStore from './images/play-store.png';

import styles from './footer.module.scss';

export interface IFooterProps {
  hideNav?: boolean;
  hideReferRow?: boolean;
  disclaimer?: React.ReactElement;
  logoType?: E_LOGO_TYPES;
}

export enum E_TEST_IDS {
  FooterTestId = 'footer',
}

const config = [
  {
    label: 'For investors',
    links: [
      { label: 'Why invest', url: '/investors/why-invest' },
      { label: 'How it works', url: '/learn/investors/how-it-works' },
      { label: 'FAQ', url: '/help' },
      {
        label: 'Risks',
        url: '/risks',
      },
      { label: 'Privacy Policy', url: '/privacy' },
      {
        label: 'Accessibility',
        url: '/accessibility',
      },
      {
        label: 'Cookie preferences',
        onClick: (e: MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();

          if (window && 'Osano' in window) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
          }
        },
      },
      {
        label: 'Form CRS',
        url: 'https://files.brokercheck.finra.org/crs_291387.pdf',
        target: '_blank',
      },
    ],
  },
  {
    label: 'For startups',
    links: [
      { label: 'Why raise', url: '/raise' },
      { label: 'Learn', url: '/learn/issuers' },
      { label: 'FAQ', url: '/help/category/fundraising' },
      { label: 'Instruments', url: '/startups/fundraising-instruments' },
      { label: 'Crowd SAFE', url: '/crowdsafe' },
      { label: 'Tokenize assets', url: '/tokenization' },
    ],
  },
  // @TODO: https://linear.app/republic/issue/USMKT-431/switch-back-the-crypto-column-in-the-footer-on-r-client-and-monolith
  // {
  //   label: 'Crypto',
  //   links: [
  //     { label: 'For investors', url: '/crypto' },
  //     { label: 'For companies', url: '/crypto/services' },
  //     {
  //       label: 'How it works',
  //       url: '/crypto/for-investors/how-it-works',
  //     },
  //     { label: 'Token DPA', url: '/token-dpa' },
  //     {
  //       label: 'Tokenization',
  //       url: '/tokenization',
  //     },
  //   ],
  // },
  {
    label: 'Company',
    links: [
      { label: 'About', url: '/about' },
      { label: 'Journal', url: '/blog' },
      { label: 'Events', url: '/events' },
      { label: 'Contact', url: '/contact' },
      { label: "We're hiring!", url: '/jobs' },
    ],
  },
];

export const APPSTORE_LINK = 'https://apps.apple.com/us/app/id1475230022';

export const ANDROIDSTORE_LINK =
  'https://play.google.com/store/apps/details?id=com.republicmobile&hl=en_US';

export function Footer({
  hideNav = false,
  hideReferRow = false,
  disclaimer = <Main />,
  logoType = E_LOGO_TYPES.White,
}: IFooterProps) {
  const analytics = useAnalytics();

  return (
    <Box
      as="footer"
      p="3.5brm 0"
      className={styles.footer}
      data-testid={E_TEST_IDS.FooterTestId}
    >
      <Container>
        {!hideNav && (
          <>
            <Grid templateColumns="repeat(24, 1fr)" gap={4}>
              <GridItem
                className={styles.column}
                px={4}
                colSpan={{ base: 24, md: 5 }}
              >
                <RepublicLogo type={logoType} />
                <Box m="1.5brm 0">
                  <Text className={styles.footerSubTitle} lineHeight="1.3">
                    Giving everyone access to early-stage startup investing
                  </Text>
                </Box>
                <Box m="0 0 2brm 0" className={styles.socialLinks}>
                  <Box
                    as="span"
                    onClick={() => {
                      analytics.track({
                        name: 'bottom_block_click_social',
                        params: {
                          label: 'https://facebook.com/joinrepublic',
                        },
                      });
                    }}
                  >
                    <SocialIcon
                      icon="facebook"
                      url="https://facebook.com/joinrepublic"
                    />
                  </Box>
                  <Box
                    as="span"
                    onClick={() => {
                      analytics.track({
                        name: 'bottom_block_click_social',
                        params: {
                          label: 'https://instagram.com/joinrepublic',
                        },
                      });
                    }}
                  >
                    <SocialIcon
                      icon="instagram"
                      url="https://instagram.com/joinrepublic"
                    />
                  </Box>
                  <Box
                    as="span"
                    onClick={() => {
                      analytics.track({
                        name: 'bottom_block_click_social',
                        params: {
                          label: 'https://twitter.com/joinrepublic',
                        },
                      });
                    }}
                  >
                    <SocialIcon
                      icon="twitter"
                      url="https://twitter.com/joinrepublic"
                    />
                  </Box>
                  <Box
                    as="span"
                    onClick={() => {
                      analytics.track({
                        name: 'bottom_block_click_social',
                        params: {
                          label: 'https://www.linkedin.com/company/republic.co',
                        },
                      });
                    }}
                  >
                    <SocialIcon
                      icon="linkedin"
                      url="https://www.linkedin.com/company/republic.co"
                    />
                  </Box>
                  <Box
                    as="span"
                    onClick={() => {
                      analytics.track({
                        name: 'bottom_block_click_social',
                        params: {
                          label: 'https://rep.pub/discord-invite',
                        },
                      });
                    }}
                  >
                    <SocialIcon
                      icon="discord"
                      url="https://rep.pub/discord-invite"
                    />
                  </Box>
                </Box>
              </GridItem>
              <GridItem
                // @TODO: https://linear.app/republic/issue/USMKT-431/switch-back-the-crypto-column-in-the-footer-on-r-client-and-monolith
                className={CN(styles.column, styles.categoriesColumn)}
                colSpan={{ base: 24, md: 19 }}
              >
                <Flex wrap="wrap">
                  {config.map((data) => (
                    <FooterLinks key={data.label} {...data} />
                  ))}
                </Flex>
              </GridItem>
            </Grid>
            {!hideReferRow ? (
              <>
                <ChakraFlex
                  data-testid="footer-refer-section"
                  py="6"
                  borderBottom="1px solid"
                  borderTop="1px solid"
                  borderColor="rgba(255,255,255, 0.1)"
                  justifyContent={{ base: 'center', lg: 'space-between' }}
                >
                  <ChakraFlex alignItems="center" fontSize={18}>
                    <DollarIcon color="brand.grey.cc" boxSize="8" mr="3" />
                    <Link
                      href="/invite/startups"
                      variant={Link.variants.inherit}
                      onClick={() => {
                        analytics.track({
                          name: 'bottom_block_click_refer',
                        });
                      }}
                    >
                      Refer a startup, get $2,500
                    </Link>
                  </ChakraFlex>
                  <ChakraFlex
                    display={{ base: 'none', lg: 'flex' }}
                    alignItems="center"
                    gap="4"
                  >
                    <ChakraText opacity="0.6" mr="5" size="republic-body-lg">
                      Invest in the app
                    </ChakraText>
                    <Link
                      href={ANDROIDSTORE_LINK}
                      target="_blank"
                      onClick={() => {
                        analytics?.track({
                          name: 'click_mobile_store_external',
                          params: {
                            label: 'android_store',
                          },
                        });
                      }}
                    >
                      <Img
                        width="142px"
                        height="12"
                        src={androidStore}
                        alt="Android store logo"
                      />
                    </Link>
                    <Link
                      href={APPSTORE_LINK}
                      target="_blank"
                      onClick={() => {
                        analytics?.track({
                          name: 'click_mobile_store_external',
                          params: {
                            label: 'app_store',
                          },
                        });
                      }}
                    >
                      <Img
                        width="142px"
                        height="12"
                        src={appStore}
                        alt="Apple store logo"
                      />
                    </Link>
                  </ChakraFlex>
                </ChakraFlex>
                <ChakraFlex
                  display={{ base: 'flex', lg: 'none' }}
                  mt="6"
                  mb="1"
                  flexDirection="column"
                  alignItems="center"
                  gap="5"
                >
                  <ChakraText opacity="0.6" size="republic-body-lg">
                    Invest in the app
                  </ChakraText>
                  <ChakraFlex gap="4">
                    <Link
                      href={ANDROIDSTORE_LINK}
                      target="_blank"
                      onClick={() => {
                        analytics?.track({
                          name: 'click_mobile_store_external',
                          params: {
                            label: 'android_store',
                          },
                        });
                      }}
                    >
                      <Img width="162px" height="54px" src={androidStore} />
                    </Link>
                    <Link
                      href={APPSTORE_LINK}
                      target="_blank"
                      onClick={() => {
                        analytics?.track({
                          name: 'click_mobile_store_external',
                          params: {
                            label: 'app_store',
                          },
                        });
                      }}
                    >
                      <Img width="162px" height="54px<" src={appStore} />
                    </Link>
                  </ChakraFlex>
                </ChakraFlex>
              </>
            ) : null}
          </>
        )}
        {hideNav && (
          <Box p="1.25brm 0">
            <RepublicLogo type={logoType} />
          </Box>
        )}
        <Box p="1.25brm 0" className={CN(styles.footerContent)}>
          <DisclaimerContainer maxHeight="250px">
            {disclaimer}
          </DisclaimerContainer>
        </Box>
        <ChakraFlex
          direction={{ base: 'column', xs: 'row' }}
          justifyContent="space-between"
          alignItems={{ base: 'center', xs: 'flex-start' }}
          mt="4"
        >
          <ChakraText
            textStyle="sm"
            color="grey.500"
            mb="2"
            display={{ xs: 'none' }}
          >
            Invest in startups using your credit card
          </ChakraText>
          <ChakraFlex justifyContent="flex-end" order={{ base: 0, xs: 1 }}>
            <CreditCards />
          </ChakraFlex>
          <ChakraText
            textStyle="sm"
            fontSize="14px"
            color="grey.500"
            mt={{ base: '6', xs: '0' }}
          >
            Made in SF/NYC
          </ChakraText>
        </ChakraFlex>
      </Container>
    </Box>
  );
}
