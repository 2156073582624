import { useRef } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import {
  AnimationPlaybackControls,
  AnimationScope,
  useAnimate,
  useReducedMotion,
} from 'framer-motion';

import { useSelectedNavigationEntry } from '../../utils/use-selected-navigation-entry';

interface IActions {
  openSection: (i?: number, animationScope?: AnimationScope) => void;
  closeCurrentSection: () => void;
  cancelCloseAction: () => void;
}

interface IUsedNavigationEntity {
  selectedEntityIndex: number | undefined;
  isOpen: boolean;
  actions: IActions;
}

const CLOSE_DELAY = 100;

const getAnimationPosition = (
  oldIndex: number | undefined,
  newIndex: number | undefined
): { startPosition: string; endPosition: string; positionChanged: boolean } => {
  if (oldIndex === undefined || newIndex === undefined || oldIndex === newIndex)
    return {
      startPosition: '0',
      endPosition: '0',
      positionChanged: false,
    };

  return oldIndex > newIndex
    ? { startPosition: '-10px', endPosition: '0px', positionChanged: true }
    : { startPosition: '10px', endPosition: '0', positionChanged: true };
};

export function useNavigationEntity(): IUsedNavigationEntity {
  const [, animate] = useAnimate();
  const prefersReducedMotion = useReducedMotion();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selectedNavigationEntry, onSelect, onReset } =
    useSelectedNavigationEntry();

  const isHoveringRef = useRef<boolean>(false);
  const closeTimeout = useRef<number>();
  let animation: AnimationPlaybackControls;

  const actions = {
    async openSection(
      navigationEntryIndex?: number,
      animationScope?: AnimationScope
    ) {
      isHoveringRef.current = true;

      const { startPosition, endPosition, positionChanged } =
        getAnimationPosition(selectedNavigationEntry, navigationEntryIndex);

      onSelect(navigationEntryIndex);
      onOpen();

      if (positionChanged && !prefersReducedMotion && animationScope?.current) {
        animation?.cancel?.();

        animation =
          animationScope.current &&
          (await animate(
            animationScope.current,
            {
              transform: `translateX(${startPosition})`,
              opacity: 0,
            },
            { duration: 0 }
          ));

        animation =
          animationScope.current &&
          (await animate(
            animationScope.current,
            {
              transform: `translateX(${endPosition})`,
              opacity: 1,
            },
            {
              duration: 0.2,
              ease: 'easeInOut',
            }
          ));
      }
    },

    closeCurrentSection() {
      isHoveringRef.current = false;
      animation?.cancel?.();

      closeTimeout.current = window.setTimeout(() => {
        if (isHoveringRef.current === false) {
          onReset();
          onClose();
        }
      }, CLOSE_DELAY);
    },

    cancelCloseAction() {
      window.clearTimeout(closeTimeout.current);
    },
  };

  return { selectedEntityIndex: selectedNavigationEntry, actions, isOpen };
}
