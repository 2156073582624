import { FunctionComponent, useEffect, useState } from 'react';

import AnimateHeight from 'react-animate-height';

export interface ISlideAnimationProps {
  className?: string;
  duration?: number;
  timeout?: number;
  easing?: string;
  show?: boolean;
  unmountContentOnHide?: boolean;
  onAnimationEnd?(): void;
}

export const SlideAnimation: FunctionComponent<
  React.PropsWithChildren<ISlideAnimationProps>
> = ({
  className,
  children,
  duration = 200,
  easing = 'cubic-bezier(.02, .01, .47, 1)',
  show,
  unmountContentOnHide = true,
  onAnimationEnd,
}) => {
  const [animateHeight, setAnimateHeight] = useState(show);
  const [savedChildren, setSavedChildren] = useState(children);
  const [hideChildren, setHideChildren] = useState(!show);
  const unmountChildren = unmountContentOnHide && hideChildren;

  useEffect(() => {
    if (!unmountContentOnHide || show) {
      setSavedChildren(children || []);
    }
    if (show) {
      setHideChildren(false);
    }
    setAnimateHeight(show);
  }, [show, children, unmountContentOnHide]);

  const handleAnimationEnd = () => {
    if (!show) {
      if (unmountContentOnHide) {
        setSavedChildren([]);
      }
      setHideChildren(true);
    }
    if (onAnimationEnd) {
      onAnimationEnd();
    }
  };

  return (
    <AnimateHeight
      className={className}
      duration={duration}
      easing={easing}
      height={animateHeight ? 'auto' : 0}
      onHeightAnimationEnd={handleAnimationEnd}
    >
      {!unmountChildren ? savedChildren : []}
    </AnimateHeight>
  );
};
