import { useEffect, useState } from 'react';

import { Flex, Text } from '@chakra-ui/react';

import { SlideAnimation } from '@r-client/shared/ui/animation';
import { getLocalStorage } from '@r-client/shared/util/core';

export function Disclosure() {
  const [show, setShow] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(500);
  const localStorage = getLocalStorage();

  useEffect(() => {
    if (
      !localStorage?.getItem('t-republic-disclosure:shown') ||
      localStorage?.getItem('t-republic-disclosure:shown') === 'false'
    ) {
      setShow(true);
      localStorage?.setItem('t-republic-disclosure:shown', 'true');
    }
  }, []);

  useEffect(() => {
    if (duration === 0) {
      setTimeout(() => {
        setDuration(500);
        setShow(false);
      }, 10000);
    }
  }, [duration]);

  const onAnimationEnd = () => {
    if (duration === 500) {
      setDuration(0);
    }
  };

  return (
    <SlideAnimation
      show={show}
      duration={duration}
      unmountContentOnHide={false}
      onAnimationEnd={onAnimationEnd}
    >
      <Flex
        width="100%"
        background="brand.grey.cc"
        justifyContent="center"
        alignItems="center"
        py="0.5rem"
        px="1rem"
      >
        <Text fontSize="sm" lineHeight="16px">
          Republic is not affiliated with Trade Republic and offers very
          different financial products.
        </Text>
      </Flex>
    </SlideAnimation>
  );
}
