import CN from 'clsx';

import { Box, IBoxProps } from '@r-client/shared/ui/core';
import {
  brm2px,
  CssBuilderGeneric,
  TMedia,
  TPropsMapping,
  TSizeWithBrm,
} from '@r-client/shared/util/core';

import styles from './flex-item.module.scss';

export const basisOptions = [
  'auto',
  'fill',
  'max-content',
  'fit-content',
  'content',
] as const;

export const alignSelfOptions = [
  'auto',
  'flex-start',
  'flex-end',
  'baseline',
  'stretch',
  'center',
] as const;

type TBasis = typeof basisOptions[number] | TSizeWithBrm | number;
type TAlignSelf = typeof alignSelfOptions[number];

const propsMapping: TPropsMapping = {
  grow: { style: 'flex-grow', cssVar: true },
  shrink: { style: 'flex-shrink', cssVar: true },
  basis: { style: 'flex-basis', cssVar: true, convert: brm2px },
  alignSelf: { style: 'align-self', cssVar: false },
};

export interface IFlexItemProps extends IBoxProps {
  grow?: TMedia<number>;
  shrink?: TMedia<number>;
  basis?: TMedia<TBasis>;
  alignSelf?: TMedia<TAlignSelf>;
}

export const FlexItem = (props: IFlexItemProps) => {
  const { grow, shrink, basis, alignSelf, children, className, ...params } =
    props;
  const builder = new CssBuilderGeneric(
    styles,
    props,
    propsMapping,
    FlexItem.defaultProps
  );
  const style = Object.assign(params.style || {}, builder.cssVars);
  const allClassNames = CN(className, builder.classNames);
  return (
    <Box className={allClassNames} style={style} {...params}>
      {children}
    </Box>
  );
};

FlexItem.defaultProps = {
  alignSelf: 'auto',
} as Partial<IFlexItemProps>;
