import React, { FunctionComponent } from 'react';

import classNames from 'clsx';

import { dataRole } from '@r-client/shared/util/core';

import styles from './input-message.module.scss';

export interface IInputMessageProps
  extends React.HTMLAttributes<HTMLDivElement> {
  mode?: 'error' | 'success' | 'normal';
}

export const InputMessage: FunctionComponent<
  React.PropsWithChildren<IInputMessageProps>
> = function InputMessage({ className, children, mode, role, ...props }) {
  return (
    <div
      className={classNames(className, styles.message, {
        [styles.error]: mode === 'error',
        [styles.success]: mode === 'success',
        [styles.normal]: mode === 'normal',
      })}
      {...props}
      {...dataRole(role)}
    >
      {children}
    </div>
  );
};
