import './social-icon.module.scss';

import { FunctionComponent } from 'react';

import { Link } from '@r-client/shared/ui/core';
import {
  Discord,
  FaceBook,
  Instagram,
  Linkedin,
  SubStack,
  SvgIconLight,
  Telegram,
  Twitter,
} from '@r-client/shared/ui/icons';

const ICONS = {
  instagram: Instagram,
  linkedin: Linkedin,
  twitter: Twitter,
  telegram: Telegram,
  facebook: FaceBook,
  substack: SubStack,
  discord: Discord,
};

export interface ISocialIconProps {
  icon: keyof typeof ICONS;
  url?: string;
  size?: 'small' | 'large';
}

const MAP = {
  small: { width: 19, height: 19 },
  large: { width: 24, height: 24 },
};

export const SocialIcon: FunctionComponent<
  React.PropsWithChildren<ISocialIconProps>
> = ({ icon, url, size = 'large' }) => {
  const iconSize = MAP[size];
  const Icon = ICONS[icon];

  if (url) {
    return (
      <Link href={url}>
        <SvgIconLight icon={Icon} {...iconSize} legacyVerticalAlignment />
      </Link>
    );
  } else {
    return <SvgIconLight icon={Icon} {...iconSize} legacyVerticalAlignment />;
  }
};
