import { IAnalyticsModel } from '@r-client/shared/data/analytics';

import { IGlobalSearchQuery } from '../graphql/types';
import {
  AutosuggestItemData,
  IAutosuggestCategory,
  IAutosuggestOpportunity,
  IAutosuggestUsers,
} from '../types';

type ValueOf<T> = T[keyof T];

type TAutosuggestData<T extends 'tags' | 'offerings' | 'users'> = ValueOf<
  Pick<IGlobalSearchQuery['globalSearch'], T>
>;

export const getAutosuggestOpportunityData = (
  data: TAutosuggestData<'offerings'> = []
) => {
  return (data || []).map<IAutosuggestOpportunity>((offering) => ({
    slug: offering.slug,
    type: 'offering',
    campaignStatus: offering.campaignStatus,
    text: offering.companyName,
    url: `/${offering.slug}` || '/',
    imageURL: offering.logoImageUrl || '/',
    imageURL2x: offering.logoImageUrl2x || '/',
  }));
};

export const getAutosuggestTagData = (data: TAutosuggestData<'tags'>) => {
  return (data || []).map<IAutosuggestCategory>((tag) => ({
    slug: tag.slug,
    type: 'tag',
    url: `/tag/${tag.slug}`,
    text: tag.displayName,
  }));
};

export const getAutosuggestUserData = (data: TAutosuggestData<'users'>) => {
  return (data || []).map<IAutosuggestUsers>((user) => ({
    slug: user.slug,
    type: 'user',
    imageURL: user.avatarUrl || '',
    imageURL2x: user.avatarUrl2x || '',
    text: `${user.firstName} ${user.lastName}`,
    url: `@${user.slug}`,
  }));
};

export const collectAnalyticsAutosuggestOpportunityData = (
  data: TAutosuggestData<'offerings'> = [],
  slug: string
) => {
  const offering = data?.find((item) => item.slug === slug);
  return {
    offering_id: offering?.id,
    offering_slug: offering?.slug,
    list_id: 'list-suggested-search',
  };
};

export const collectAnalyticsAutosuggestTagData = (
  data: TAutosuggestData<'tags'> = [],
  slug: string
) => {
  const category = data?.find((item) => item.slug === slug);
  return {
    category_id: category?.id,
    category_name: category?.displayName,
    parent_category: category?.parentName || '',
    category_slug: category?.slug,
  };
};

export const collectAnalyticsAutosuggestUserData = (
  data: TAutosuggestData<'users'> = [],
  slug: string
) => {
  const user = data?.find((item) => item.slug === slug);
  return {
    clicked_user_id: user?.id,
    clicked_user_slug: user?.slug,
  };
};

export const handleSendSuggestionItemsAnalyticsEvent = (
  item: AutosuggestItemData,
  analytics: IAnalyticsModel,
  globalSearch?: IGlobalSearchQuery['globalSearch'],
  value?: string
) => {
  // TODO: https://linear.app/republic/issue/INV-684/global-search-dropdown-events-not-tracked
  switch (item.type) {
    case 'offering':
      analytics.track({
        name: 'offering_clicked',
        params: {
          platform: 'web',
          search_term: value,
          ...collectAnalyticsAutosuggestOpportunityData(
            globalSearch?.offerings,
            item.value
          ),
        },
      });
      break;
    case 'tag':
      analytics.track({
        name: 'category_clicked',
        params: {
          platform: 'web',
          search_term: value,
          ...collectAnalyticsAutosuggestTagData(globalSearch?.tags, item.value),
        },
      });
      break;
    case 'user':
      analytics.track({
        name: 'user_clicked',
        params: {
          platform: 'web',
          search_term: value,
          ...collectAnalyticsAutosuggestUserData(
            globalSearch?.users,
            item.value
          ),
        },
      });
      break;
  }
};
