import { useAnalytics } from '@r-client/shared/data/analytics';
import { Box, Button, Text, Tooltip } from '@r-client/shared/ui/core';
import { Flex, FlexItem } from '@r-client/shared/ui/flex';
import { InfoGray, SvgIconLight, Warning } from '@r-client/shared/ui/icons';

import styles from './identity-verification-banner.module.scss';

export const IdentityVerificationBanner = () => {
  const analytics = useAnalytics();

  const viewAndConfirmHandler = () => {
    analytics.track({
      name: 'website_banner_clicked',
      params: {
        banner_name: 'IdentityVerificationBanner',
        button_name: 'view_and_confirm',
      },
    });
  };
  return (
    <Flex
      justifyContent="space-between"
      alignItems={{ lg: 'center', sm: 'flex-start' }}
      direction={{ sm: 'column' }}
      p="1.5brm 1brm"
      bg="gray-f2"
      className={styles.actionWrapper}
    >
      <Flex m={{ sm: '0 0 1brm', lg: 'auto' }}>
        <Text bold color="error">
          <SvgIconLight icon={Warning} />
        </Text>
        <Flex m="0 0 0 6px" hidden={{ xs: true }}>
          <Text bold color="error">
            Action required
          </Text>
        </Flex>
        <Box m="0 0 0 6px">
          Updated identity information is needed for your investments.
          <Tooltip
            content="Our records show that certain personal information you provided is no longer up to date.
              Please re-verify your personal information to ensure a seamless experience with respect
              to your existing investments."
          >
            <SvgIconLight
              dataTestId="identity-verification-tooltip"
              icon={InfoGray}
              className={styles.infoIcon}
            />
          </Tooltip>
        </Box>
      </Flex>
      <FlexItem alignSelf="stretch" textAlign="center">
        {/* TODO: Button will launch Cognito flow modal. Currently should ONLY show on investment pages needing identity verification, but might change user journey (pending conversation with design) */}
        <Button
          type="default-thin"
          href="/portfolio"
          className={styles.action}
          onClick={viewAndConfirmHandler}
        >
          Verify now
        </Button>
      </FlexItem>
    </Flex>
  );
};
