import { useAnalytics } from '@r-client/shared/data/analytics';

export const useHeaderMenuLinkTrack = () => {
  const analytics = useAnalytics();

  const track = (label: string, categoryName: string) => {
    const category = categoryName.toLowerCase();
    analytics.track({
      name: 'header_menu_clicked',
      params: { label, category, platform: 'web' },
    });
  };

  return { track };
};
