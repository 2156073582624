import { format } from 'date-fns';

import { DATE_FORMAT } from '@r-client/shared/util/core';

import { INotification } from '../types';

export function groupNotificationsByDate(notifications: INotification[]) {
  return notifications.reduce<Record<string, INotification[]>>((acc, curr) => {
    const currDate = format(
      new Date(curr.createdAt),
      DATE_FORMAT.mediumTwoDigitsDay
    );
    if (acc[currDate]) {
      acc[currDate].push(curr);
    } else {
      acc[currDate] = [curr];
    }
    return acc;
  }, {});
}
