import React, { FunctionComponent } from 'react';

import CN from 'clsx';

import { SlideAnimation } from '@r-client/shared/ui/animation';
import { dataRole } from '@r-client/shared/util/core';

import { Label } from '../label';
import { InputMessage } from './input-message';

import styles from './input-group.module.scss';

export interface IInputGroupProps {
  className?: string;
  classNameLabel?: string;
  error?: React.ReactNode;
  hint?: React.ReactNode;
  htmlFor?: string;
  label?: React.ReactNode;
  showMessage?: boolean;
  success?: React.ReactNode;
  withMargin?: boolean;
  thinHint?: boolean;
  disabled?: boolean;
  role?: string;
  inline?: boolean;
}

export const InputGroup: FunctionComponent<
  React.PropsWithChildren<IInputGroupProps>
> = ({
  children,
  className,
  classNameLabel,
  error,
  hint,
  htmlFor,
  label,
  disabled = false,
  showMessage = true,
  success,
  withMargin,
  thinHint = false,
  role,
  inline,
}) => {
  const invalid = !!error;

  return (
    <div
      className={CN(className, styles.inputGroup, {
        [styles.withMargin]: !!withMargin,
      })}
      {...dataRole(role)}
    >
      {inline ? (
        <div className={styles.wrapperInline}>
          {label && (
            <Label
              htmlFor={htmlFor}
              invalid={invalid}
              disabled={disabled}
              className={styles.label}
            >
              {label}
            </Label>
          )}
          {children}
        </div>
      ) : (
        <>
          {label && (
            <Label htmlFor={htmlFor} disabled={disabled} invalid={invalid}>
              {label}
            </Label>
          )}
          {children}
        </>
      )}
      {showMessage && (
        <SlideAnimation show={invalid || !!success}>
          <InputMessage
            className={classNameLabel}
            mode={error ? 'error' : 'success'}
            role="error"
          >
            {error || success}
          </InputMessage>
        </SlideAnimation>
      )}
      {!!hint && (
        <InputMessage
          className={CN(styles.hint, {
            [styles.thin]: thinHint,
          })}
          mode="normal"
        >
          {hint}
        </InputMessage>
      )}
    </div>
  );
};
