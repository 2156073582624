import lodashSet from 'lodash/set';
import { extendObservable, makeAutoObservable } from 'mobx';

import { loadAsyncComputed } from './load-async-computed';
import { promisedComputed } from './promised-computed';

export type TValue<T, TVal, TKey extends keyof T = keyof T> = {
  [P in TKey]: TVal;
};

export type IFieldModelRecord<K> = {
  [P in keyof K]: IFieldModel<K[P]>;
};

function eachField(ctx: any, fn: (key: string) => void) {
  Object.keys(ctx).forEach((k) => {
    if (k === 'errors' || k === 'touched' || k === 'names') {
      return;
    }
    const key = k.replace(/^\$/, '');

    fn(key);
  });
}

function syncModels<TTarget>(
  target: TTarget,
  source: any,
  setIn = lodashSet,
  path: string[] = [],
  origin: any = target
) {
  eachField(target, (k: any) => {
    const key: keyof TTarget = k;
    const sVal = source[key];
    const tVal = target[key];

    if (typeof tVal === 'object') {
      return syncModels(tVal, sVal, setIn, path.concat(k), origin);
    }
    if (target[key] !== sVal) {
      setIn(origin, path.concat(k), sVal);
    }
  });
}

export class BaseModel<T, P = any> {
  errors: TValue<
    T,
    string,
    keyof Omit<T, 'errors' | 'touched' | 'syncMeta' | 'sync' | 'syncAll'>
  > = {} as any;
  touched: TValue<
    T,
    boolean,
    keyof Omit<T, 'errors' | 'touched' | 'syncMeta' | 'sync' | 'syncAll'>
  > = {} as any;
  readonly names: TValue<
    T,
    string,
    keyof Omit<T, 'errors' | 'touched' | 'syncMeta' | 'sync' | 'syncAll'>
  > & { form: string } = {} as any;

  constructor(aName: string, parent?: BaseModel<P, any>) {
    makeAutoObservable(this, {
      syncMeta: false,
      sync: false,
      syncAll: false,
    });
    const name =
      parent && parent.names.form
        ? parent.names.form + '[' + aName + ']'
        : aName;
    const errors: any = {};
    const touched: any = {};
    const names: any = {};

    eachField(this, (key) => {
      errors[key] = '';
      touched[key] = false;
      names[key] = name ? `${name}[${key}]` : name;
    });
    extendObservable(this, { errors, touched, names });
    // extendObservable(this.errors, errors);
    // extendObservable(this.touched, touched);
    // extendObservable(this.names, names);
    this.names.form = name;
  }

  toJSON() {
    const result: any = {};
    eachField(this, (key) => {
      const field = (this as any)[key];
      result[key] = field instanceof BaseModel ? field.toJSON() : field;
    });
    return result;
  }

  get touchedValue() {
    const touched: any = { ...this.touched };
    eachField(this, (key) => {
      const field = (this as any)[key];
      if (field instanceof BaseModel) {
        touched[key] = field.touchedValue;
      }
    });
    return touched;
  }

  fillAllTouched(value: boolean) {
    eachField(this, (key) => {
      const field = (this as any)[key];
      if (field instanceof BaseModel) {
        field.fillAllTouched(value);
      } else {
        (this.touched as any)[key] = value;
      }
    });
  }

  syncMeta<T1, T2>(touched?: T1, errors?: T2) {
    const meta: any[][] = [];
    if (touched) {
      meta.push([this.touched, touched]);
    } else {
      eachField(this, (key) => {
        (this.touched as any)[key] = false;
      });
    }
    if (errors) {
      meta.push([this.errors, errors]);
    } else {
      eachField(this, (key) => {
        (this.errors as any)[key] = '';
      });
    }
    eachField(this, (key) => {
      const ptr = (this as any)[key];
      if (ptr instanceof BaseModel) {
        const t = (touched as any)?.[key];
        const e = (errors as any)?.[key];
        ptr.syncMeta(t, e);
      } else {
        meta.forEach(([item, source]) => {
          if (source[key] !== item[key]) {
            item[key] = source[key];
          }
        });
      }
    });
  }

  sync(source: any) {
    syncModels(this, source);
  }

  syncAll(source: any, touched: any, errors: any) {
    this.sync(source);
    this.syncMeta(touched, errors);
  }
}

export interface IAttrGet<T> {
  get(): T;
}

export function createAttrGet<T>(get: () => T, _?: never): IAttrGet<T>;
export function createAttrGet<T, TResult = T[keyof T]>(
  target: T,
  key: keyof T
): IAttrGet<TResult>;
export function createAttrGet(target: any, key: any): IAttrGet<any> {
  const get = target instanceof Function ? target : () => target[key];
  return { get };
}

export interface IAttrSet<T> {
  set(val: T): void;
}

export function createAttrSet<T>(set: (val: T) => void, _?: never): IAttrSet<T>;
export function createAttrSet<T, TResult = T[keyof T]>(
  target: T,
  key: keyof T
): IAttrSet<TResult>;
export function createAttrSet(target: any, key: any): IAttrSet<any> {
  const set =
    target instanceof Function ? target : (val: any) => (target[key] = val);
  return { set };
}

export type IAttr<T> = IAttrGet<T> & IAttrSet<T>;

export function createAttr<T>(get: () => T, set: (val: T) => void): IAttr<T>;
export function createAttr<T, TResult = T[keyof T]>(
  target: T,
  key: keyof T
): IAttr<TResult>;
export function createAttr(target: any, key: any): IAttr<any> {
  if (target instanceof Function && key instanceof Function) {
    return {
      get: target,
      set: key,
    };
  }
  return {
    get: () => target[key],
    set: (val: any) => (target[key] = val),
  };
}

export interface IFieldModel<T> {
  readonly id: number;
  value: T | undefined;
  error: string | undefined;
  touched: boolean | undefined;
  readonly isValid: boolean;
  changing?: boolean;
  lazyInitial?: T | (() => T | undefined);
  readonly busy: boolean;
  getError?: () => Promise<string | undefined>;
}

export type IFieldModelMeta<T, TMeta> = IFieldModel<T> & { meta: TMeta };

let ID = 0;

/**
 * @deprecated
 * please use Switch from @chakra-ui/react instead.
 * team is migrating form interaction to react-hook-form
 */
export interface IFieldModelOptions<T> {
  autotouched?: boolean;
  recomputed?: (val?: T) => T | undefined;
}

/**
 * @deprecated
 * team is migrating form interaction to react-hook-form
 */
export class FieldModel<T> implements IFieldModel<T> {
  public readonly id = ID++;
  // TODO: Move back after RC-176
  validation:
    | ((value?: T) => undefined | string | Promise<string | undefined>)
    | undefined;
  constructor(
    validation?: (
      value?: T
    ) => undefined | string | Promise<string | undefined>,
    public lazyInitial?: T | (() => T | undefined),
    protected $$opts?: IFieldModelOptions<T>
  ) {
    this.validation = validation;
    makeAutoObservable(this);
  }

  private $value: T | undefined;

  private $error: string | undefined;

  private $touched: boolean | undefined;

  private $changing: boolean | undefined;

  get value(): T | undefined {
    const lazyInitial: any = this.lazyInitial;
    if (lazyInitial !== undefined && this.$value === undefined) {
      const result: T | undefined =
        typeof lazyInitial === 'function' ? lazyInitial() : lazyInitial;
      return this.$$opts?.recomputed ? this.$$opts?.recomputed(result) : result;
    }
    return this.$$opts?.recomputed
      ? this.$$opts?.recomputed(this.$value)
      : this.$value;
  }

  set value(val) {
    this.$value = val;
    this.$error = undefined;
  }

  get touched() {
    const $touched = this.$touched;
    const value = this.value;
    if (this.$$opts?.autotouched) {
      return value !== undefined;
    }
    return $touched;
  }
  set touched(val) {
    this.$touched = val;
  }

  get changing() {
    return this.$changing;
  }
  set changing(val) {
    this.$changing = val;
  }

  private $$error = promisedComputed<string | undefined>(undefined, () =>
    this.validation?.(this.value)
  );

  get error() {
    if (this.$error) {
      return this.$error;
    }
    const err = this.$$error.get();
    if (this.$$error.busy) {
      return undefined;
    }
    return err;
  }

  set error(val) {
    this.$error = val;
  }

  get busy() {
    return this.$$error.busy;
  }

  get isValid() {
    const error = this.error;
    if (this.$$error.busy) {
      return false;
    }
    return !error;
  }

  async getError(): Promise<string | undefined> {
    if (this.$error) {
      return this.$error;
    }
    return loadAsyncComputed(this.$$error);
  }

  attr() {
    return createAttr(
      () => this.value,
      (val) => (this.value = val)
    );
  }

  attrGet() {
    return createAttrGet(() => this.value);
  }

  attrSet() {
    return createAttrSet<T | undefined>((val) => (this.value = val));
  }
}

export class FieldModelWithMeta<T, TFieldMeta> extends FieldModel<T> {
  constructor(
    public meta: TFieldMeta,
    validation?: (value?: T) => undefined | string,
    lazyInitial?: T | (() => T | undefined),
    opts?: IFieldModelOptions<T>
  ) {
    super(validation, lazyInitial, opts);
  }
}

export class FieldModelAttrValue<T> implements Omit<IFieldModel<T>, 'id'> {
  constructor(private $value: IAttr<T>) {
    makeAutoObservable(this, {
      busy: false,
    });
  }

  get value() {
    return this.$value.get();
  }

  set value(val) {
    this.$value.set(val);
  }

  error: string | undefined;

  touched: boolean | undefined;

  changing: boolean | undefined;

  readonly busy = false;

  readonly isValid = false;
}
