import { INavigationData } from './types';

export const getNavigationGlobal = (hasWallet: boolean): INavigationData[] => [
  {
    navigationEntry: 'Investors',
    showWhatsNewSection: false,
    showSpotlightDeal: false,
    items: [
      {
        name: 'All investors',
        showMobileSearch: true,
        subItems: [
          {
            title: 'US marketplace',
            subTitle: 'US deals',
            href: '/companies',
            analyticsLabel: 'primary_market',
          },
          {
            title: 'Europe marketplace',
            subTitle: 'UK and EU deals',
            href: 'https://europe.republic.com/invest/raising-now',
            analyticsLabel: 'european_market',
          },
          {
            title: 'Partnerships',
            subTitle: 'Explore deals from our partners',
            href: '/partners',
            analyticsLabel: 'partnerships',
            style: {
              rowSpan: 2,
              alignItems: 'flex-start',
            },
          },
        ],
      },
      {
        name: 'Accredited only',
        subItems: [
          {
            title: 'Republic Venture',
            subTitle: 'Opportunities for accredited investors',
            href: '/dealroom',
            analyticsLabel: 'deal_room',
          },
        ],
      },
      {
        name: 'Institutional',
        subItems: [
          {
            title: 'Republic Capital',
            subTitle: 'Multi-stage venture firm',
            href: 'https://republic.com/capital',
            analyticsLabel: 'republic_capital',
          },
          {
            title: 'Republic Digital',
            subTitle: 'Crypto hedge fund',
            href: '/digital',
            analyticsLabel: 'republic_digital',
          },
        ],
      },
      {
        name: 'More',
        subItems: [
          {
            title: 'Wallet',
            subTitle: 'Manage your digital assets',
            href: hasWallet ? '/portfolio/crypto/assets' : '/wallet',
            analyticsLabel: 'wallet',
          },
          {
            title: 'Mobile app',
            subTitle: 'Available on iOS or Android',
            href: '/app',
            analyticsLabel: 'mobile_app',
          },
          {
            title: 'Learning center',
            subTitle: 'Explore investor resources',
            href: '/learn/investors/how-it-works',
            analyticsLabel: 'learning_center',
          },
          {
            title: 'FAQ',
            subTitle: 'Get your questions answered',
            href: '/help',
            analyticsLabel: 'faq',
          },
        ],
      },
    ],
  },
  {
    navigationEntry: 'Businesses',
    showWhatsNewSection: false,
    showSpotlightDeal: false,
    items: [
      {
        name: 'Growth capital solutions',
        subItems: [
          {
            title: 'Capital fundraising',
            subTitle: 'Raise on Republic',
            href: '/raise',
            analyticsLabel: 'community_fundraising',
          },
          {
            title: 'Tokenized assets',
            subTitle: 'Design, launch, manage tokenized assets',
            href: '/tokenization',
            analyticsLabel: 'tokenized_assets',
          },
          {
            title: 'Sharedrops',
            subTitle: 'Gift equity as a reward',
            href: '/sharedrops',
            analyticsLabel: 'sharedrops',
            style: {
              rowSpan: 2,
              alignItems: 'flex-start',
            },
          },
        ],
      },
      {
        name: 'Web3 services',
        subItems: [
          {
            title: 'Advisory',
            subTitle: 'Access veteran web3 advisors',
            href: 'https://republic.com/advisory',
            analyticsLabel: 'web3_advisory',
          },
          {
            title: 'Infrastructure',
            subTitle: 'Stake your digital assets',
            href: 'https://republic.com/infrastructure',
            analyticsLabel: 'web3_infrastructure',
          },
          {
            title: 'Tokenization',
            subTitle: 'Deploy your assets on-chain',
            href: 'https://republic.com/tokenization',
            analyticsLabel: 'web3_tokenization',
          },
          {
            title: 'Asset management',
            subTitle: 'Explore digital asset funds',
            href: 'https://republic.com/digital',
            analyticsLabel: 'web3_asset_management',
          },
        ],
      },
      {
        name: 'Institutional services',
        subItems: [
          {
            title: 'Republic Capital',
            subTitle: 'In-house Venture Capital fund',
            href: 'https://republic.com/capital',
            analyticsLabel: 'capital_fundraising',
          },
          {
            title: 'Broker-dealer',
            subTitle: 'Regulated capital services',
            href: '/capital-r',
            analyticsLabel: 'broker_dealer',
          },
        ],
      },
    ],
  },
];
