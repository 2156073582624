import cn from 'clsx';

import styles from './notification-indicator.module.scss';

export interface INotificationIndicatorProps {
  compact?: boolean;
}

export function NotificationIndicator({
  compact = false,
}: INotificationIndicatorProps) {
  return (
    <div
      className={styles.relativeContainer}
      data-testid="unseen-notification-indicator"
    >
      <div
        className={cn(styles.container, { [styles.compactContainer]: compact })}
      >
        <div className={cn(styles.dot, { [styles.compactDot]: compact })} />
      </div>
    </div>
  );
}
