import { IOfferingRegulationEnum } from '@r-client/data/graphql-types';

export const translateRegulationType = (
  regulationType: IOfferingRegulationEnum
) => {
  switch (regulationType) {
    case IOfferingRegulationEnum.RegulationAPlus:
      return 'Reg A+';
    case IOfferingRegulationEnum.RegulationCf:
      return 'Reg CF';
    case IOfferingRegulationEnum.RegulationD_504:
      return 'Reg D 504';
    case IOfferingRegulationEnum.RegulationD_506B:
      return 'Reg D 506B';
    case IOfferingRegulationEnum.RegulationD_506C:
      return 'Reg D 506C';
    case IOfferingRegulationEnum.RegulationS:
      return 'Reg S';
    default:
      return '';
  }
};
