import { useMemo } from 'react';

import debug from 'debug';
import { observer } from 'mobx-react-lite';

import { useMarkHeaderNotificationsViewedMutation } from '@r-client/republic/feature/notifications';
import {
  GetUserDocument,
  IGetUserQuery,
  MaybeValue,
  Viewer,
} from '@r-client/shared/feature/auth';
import { useHasWallet } from '@r-client/shared/feature/wallet';

import { getNavigationData } from '../../config/navigation-data';
import { HEADER_THEMES } from '../../types';
import { DesktopHeader } from '../desktop-header/desktop-header';
import { MobileHeader } from '../mobile-header/mobile-header';
import { IRegionSwitcherSettings } from '../region-switcher/types';

const log = debug('feature:republic');

export interface IMainHeaderProps {
  theme?: HEADER_THEMES;
  overlay?: boolean;
  sticky?: boolean;
  regionSwitcherSettings?: IRegionSwitcherSettings;
  user: {
    viewer: MaybeValue<Viewer>;
    isLoading?: boolean;
  };
  region?: 'global' | 'us';
}

export const MainHeader = observer(function MainHeader({
  theme = 'light',
  overlay = false,
  sticky = false,
  region = 'us',
  user,
  regionSwitcherSettings,
}: IMainHeaderProps) {
  const { hasWallet } = useHasWallet();
  const navigationData = useMemo(
    () => getNavigationData({ region, hasWallet }),
    [hasWallet, region]
  );

  // Show signed out state for global region
  if (region === 'global') {
    user = { viewer: undefined, isLoading: false };
  }

  const markHeaderNotificationsViewed =
    useMarkHeaderNotificationsViewedMutation({
      variables: {
        input: {
          clientMutationId: '',
        },
      },
      update(cache) {
        const userQuery = cache.readQuery<IGetUserQuery>({
          query: GetUserDocument,
        });

        cache.writeQuery({
          query: GetUserDocument,
          data: {
            ...userQuery,
            user: {
              ...userQuery?.user,
              unreadNotificationsCount: 0,
            },
          },
        });
      },
    });

  const handleNotificationsButtonClick = () => {
    if (user?.viewer?.info?.unreadNotificationsCount) {
      markHeaderNotificationsViewed.submit().catch((err) => {
        log('Error reported to rollbar', err);
      });
    }
  };

  return (
    <>
      <MobileHeader
        navigationData={navigationData}
        theme={theme}
        overlay={overlay}
        sticky={sticky}
        user={user}
        regionSwitcherSettings={regionSwitcherSettings}
      />
      <DesktopHeader
        navigationData={navigationData}
        theme={theme}
        overlay={overlay}
        sticky={sticky}
        user={user}
        regionSwitcherSettings={regionSwitcherSettings}
        onNotificationsClick={handleNotificationsButtonClick}
      />
    </>
  );
});
