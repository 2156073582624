import { useState } from 'react';

import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Link,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { Dropdown } from '@r-client/shared/ui/dropdown';
import { ChevronDown } from '@r-client/shared/ui/icons';

import { useAdminMenuQuery } from '../../graphql/admin-menu-query';
import { IAdminMenuQuery } from '../../graphql/types';

type TAdminMenuItem = NonNullable<
  IAdminMenuQuery['adminMenu'][0]['items']
>['0'];

const ListItemLink = ({ item }: { item: TAdminMenuItem }) => {
  return (
    <>
      {item.link && (
        <Link
          _hover={{
            color: 'brand.primary',
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
          }}
          display="block"
          variant={undefined}
          href={item.link}
          px="4"
          py="3"
        >
          {item.title}
        </Link>
      )}
    </>
  );
};

export const AdminMenu = observer(function AdminMenu() {
  const { data, isLoading } = useAdminMenuQuery();
  const [isHidden, setIsHidden] = useState(true);

  if (!data?.adminMenu?.length && !isLoading) {
    return null;
  }

  return (
    <Box bg="brand.grey.f7" position="relative" p={isHidden ? 0 : 4}>
      <Flex
        data-testid="admin-menu-container"
        display={isHidden ? 'none' : 'flex'}
        mx="4"
        gap="4"
        flexWrap="wrap"
        alignItems="center"
      >
        <Text size="republic-body-sm" fontWeight="semibold">
          ❤️ Viva la Republic!
        </Text>
        {isLoading &&
          Array.from({ length: 3 }, (_, i) => {
            return (
              <Skeleton
                data-testid="admin-menu-skeleton"
                borderRadius="base"
                isLoaded={!isLoading}
                startColor="brand.grey.f2"
                endColor="brand.grey.d3"
                h="40px"
                w="98px"
                key={`skeleton_${i}`}
              />
            );
          })}
        {data?.adminMenu.map((menuItem, idx) => {
          return menuItem.items?.length ? (
            <Dropdown
              interactive
              key={`${menuItem.title}_${idx}`}
              arrow
              trigger="hover"
              content={
                <Box>
                  {menuItem.items.map((item, i, array) => {
                    return (
                      <Box key={`${menuItem.title}_${item.title}`}>
                        {item.items?.length ? (
                          <>
                            {' '}
                            <Text
                              p="2"
                              size="republic-body-sm"
                              color="brand.grey.88"
                            >
                              {item.title}
                            </Text>
                            {item.items?.map((subItem) => (
                              <div
                                data-testid="admin-menu-subitem"
                                key={`${menuItem.title}_${item.title}_${subItem.title}`}
                              >
                                <ListItemLink item={subItem} />
                              </div>
                            ))}
                          </>
                        ) : (
                          <div
                            key={`menuItem_subChild_${i}`}
                            data-testid="admin-menu-subitem"
                          >
                            <ListItemLink item={item} />
                          </div>
                        )}
                        {i !== array.length - 1 && item.items?.length && (
                          <Divider
                            mx="2"
                            width="auto"
                            color="brand.grey.ee"
                            opacity={1}
                          />
                        )}
                      </Box>
                    );
                  })}
                </Box>
              }
            >
              <Button size="md">{menuItem.title}</Button>
            </Dropdown>
          ) : (
            <Box key={`${menuItem.title}_${idx}`}>
              {menuItem.link && (
                <Link href={menuItem.link}>
                  <Button size="md">{menuItem.title}</Button>
                </Link>
              )}
            </Box>
          );
        })}
      </Flex>
      <Box
        data-testid="admin-menu-toggle"
        zIndex="1000000"
        position="absolute"
        h="22px"
        w="22px"
        bg="brand.blue.c2"
        right="0"
        bottom="0"
        p="0px 6px"
        transform="translate(0, 100%)"
        outline="1px solid white"
        _hover={{ bg: 'brand.blue.71' }}
        cursor="pointer"
        onClick={() => {
          setIsHidden(!isHidden);
        }}
      >
        <Icon
          transform={`rotate(${isHidden ? 0 : 180}deg)`}
          transition="transform 0.1s"
          color="white"
          w="12px"
          h="12px"
          as={ChevronDown}
        />
      </Box>
    </Box>
  );
});
