import React, { FunctionComponent } from 'react';

import CN from 'clsx';

import styles from './label.module.scss';

export interface ILabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  size?: 'large' | 'small';
  disabled?: boolean;
  invalid?: boolean;
}

/**
 * @deprecated
 * please use relevant component from @chakra-ui/react instead.
 * team is migrating form interaction to react-hook-form
 * @see https://chakra-ui.com/docs/components/form-control
 */
export const Label: FunctionComponent<React.PropsWithChildren<ILabelProps>> = ({
  className,
  htmlFor,
  size,
  disabled,
  invalid,
  children,
  ...props
}) => (
  <label
    htmlFor={htmlFor}
    className={CN(className, styles.label, {
      [styles.unclickable]: !htmlFor,
      [styles.small]: size === 'small',
      [styles.large]: size === 'large',
      [styles.invalid]: invalid,
      [styles.disabled]: disabled,
    })}
    {...props}
  >
    {children}
  </label>
);
