import { useAnalytics } from '@r-client/shared/data/analytics';
import { BaseQueryModel } from '@r-client/shared/data/client';
import { Box, Button, Text } from '@r-client/shared/ui/core';
import { Flex } from '@r-client/shared/ui/flex';
import { SvgIconLight, Warning } from '@r-client/shared/ui/icons';

import { IBannerQuery } from '../../graphql/types';
import { useUserEmailConfirmationResendMutation } from '../../graphql/user-email-confirmation-resend-mutation';

import styles from './confirm-email-banner.module.scss';

export interface IConfirmEmailBanner {
  shouldPromptToResendConfirmationEmail?: boolean | null;
  unconfirmedEmail?: string | null;
  refetch: BaseQueryModel<IBannerQuery>['refetch'];
}

export function ConfirmEmailBanner({
  shouldPromptToResendConfirmationEmail = false,
  unconfirmedEmail,
  refetch,
}: IConfirmEmailBanner) {
  const analytics = useAnalytics();
  const resendEmail = useUserEmailConfirmationResendMutation();

  const viewAndConfirmHandler = async () => {
    await resendEmail.submit();
    await refetch();
    analytics.track({
      name: 'website_banner_clicked',
      params: {
        banner_name: 'ConfirmEmailBanner',
        button_name: 'view_and_confirm',
      },
    });
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      direction={{ sm: 'column', lg: 'row' }}
      className={styles.messageContainer}
    >
      <Flex alignItems="center" p={{ sm: '1brm 0 0', lg: '1brm 0' }}>
        <SvgIconLight
          height={16}
          icon={Warning}
          iconClassName={styles.warningIcon}
        />
        {unconfirmedEmail ? (
          <Text
            weight="600"
            type="bodyBig"
            color="white"
            lineHeight={'1.3'}
            p={{ sm: '0 0 0 0.25brm', lg: '0 0 0 0.5brm' }}
          >
            You changed your email to {unconfirmedEmail} but haven't confirmed
            it.
          </Text>
        ) : (
          <Text
            weight="600"
            type="bodyBig"
            color="white"
            p={{ sm: '0 0 0 .25brm', lg: '0 0 0 .5brm' }}
          >
            Please confirm your email
            <Text as="span" color="white" className={styles.hiddenXs}>
              {' '}
              to continue using Republic.
            </Text>
          </Text>
        )}
      </Flex>
      <Flex alignItems="center">
        {shouldPromptToResendConfirmationEmail ? (
          <Box m={{ sm: '0.5brm', lg: '0.5brm 0 0.5brm 1.5brm' }}>
            <Button href="" size="small" onClick={viewAndConfirmHandler}>
              Resend confirmation email
            </Button>
          </Box>
        ) : (
          <Text
            weight="600"
            type="bodyBig"
            color="white"
            p={{ sm: '0 0 1brm' }}
          >
            &nbsp;Check your email for instructions.
          </Text>
        )}
      </Flex>
    </Flex>
  );
}
