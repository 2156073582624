import { Box, Divider, Text } from '@r-client/shared/ui/core';

import { TEditableEntities } from '../types';
import { EditableEntity } from './editable-entity';

import styles from './editable-entities.module.scss';

export interface IEditableEntitiesProps {
  offerings?: TEditableEntities;
  teasers?: TEditableEntities;
  mobile?: boolean;
}

export const EditableEntities = ({
  offerings = [],
  teasers = [],
  mobile,
}: IEditableEntitiesProps) => {
  const itemsLength = offerings.length + teasers.length;

  if (!itemsLength) {
    return null;
  }

  return (
    <Box className={styles.editableOfferingSection}>
      <Text
        type="bodySmall"
        color="gray8"
        weight="300"
        lineHeight="1"
        p={mobile ? '0.5brm 1brm 0' : '8px 8px 0'}
      >
        You are editor of
      </Text>
      {teasers.map((teaser) => (
        <EditableEntity key={teaser.id} entity={teaser} entityType="teaser" />
      ))}
      {offerings.map((offering) => (
        <EditableEntity
          key={offering.id}
          entity={offering}
          entityType="offering"
          data-testid="editable-entities-section"
        />
      ))}
      <Divider className={styles.divider} />
    </Box>
  );
};
