/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseQueryModel,
  IQueryModelOpts,
  useQuery,
  useGqlClient,
} from '@r-client/shared/data/client';
import { IBannerQuery, IBannerQueryVariables, BannerDocument } from './types';
import { IBannerUnion } from '@r-client/data/graphql-types';
export type { IBannerUnion };

//export interface IBannerQueryOpts extends Omit<IQueryModelOpts<IBannerQueryVariables>, 'variables'> {
export interface IBannerQueryOpts
  extends IQueryModelOpts<IBannerQueryVariables> {
  variables: IBannerQueryVariables;
}

export class BannerQuery extends BaseQueryModel<
  IBannerQuery,
  IBannerQueryVariables
> {
  // This is fine. Here we explicitly change interface for query
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(opts: IBannerQueryOpts) {
    super(opts);
  }

  get query() {
    return BannerDocument;
  }
}
export function useBannerQuery(opts: Omit<IBannerQueryOpts, 'client'>) {
  const client = useGqlClient();
  return useQuery(() => new BannerQuery({ ...opts, client }), opts?.variables);
}
