import { Viewer } from '@r-client/shared/feature/auth';

import { TEditableEntities, TTeaserEntities } from '../types';
import { teaserToEditableEntity } from './teaser-to-editable-entity';

export const getUserTeasers = (viewer?: Viewer) => {
  return viewer?.info?.teaserPages?.nodes;
};

export const teasersToEditableEntities = (
  teasers?: TTeaserEntities
): TEditableEntities => {
  if (!teasers) {
    return [];
  }

  return teasers.map(teaserToEditableEntity);
};
