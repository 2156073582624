import { Box } from '@chakra-ui/react';

import { MobileUserNavigation } from '@r-client/republic/feature/layout';
import { MaybeValue, Viewer } from '@r-client/shared/feature/auth';

interface IMobileUserMenuProps {
  user: {
    viewer: MaybeValue<Viewer>;
    isLoading?: boolean;
  };
  hasBottomBorder?: boolean;
}

export const MobileUserMenu = ({
  user: { viewer, isLoading },
  hasBottomBorder,
}: IMobileUserMenuProps) => {
  if (!isLoading && !viewer?.info) {
    return null;
  }

  return (
    <Box
      borderTop="1px solid"
      borderBottom={hasBottomBorder ? '1px solid' : undefined}
      borderColor="grey.100"
    >
      <MobileUserNavigation user={viewer?.info} />
    </Box>
  );
};
