import React, { forwardRef, SVGProps } from 'react';

import cn from 'clsx';

import styles from './svg-icon-light.module.scss';

export type SvgComponent = React.FunctionComponent<
  React.PropsWithChildren<SVGProps<SVGSVGElement>>
>;

export type Sizes = '1x';
const DEFAULT_SIZE = '1x';
/** TODO:
 * Linear : https://linear.app/republic/issue/RC-231/ui-kit-svg-icon-light
 * We need to remove width and height props once the sizes issue fix
 *  */

export interface ISvgIconProps {
  className?: string;
  iconClassName?: string;
  icon: SvgComponent;
  onClick?: () => void;
  dataTestId?: string;
  /** aira-label for tests and better accessibility */
  label?: string;
  size?: Sizes;
  width?: number;
  height?: number;
  verticalAlignmentReset?: boolean;
  /** @deprecated Please avoid using this prop in future */
  legacyVerticalAlignment?: boolean;
  style?: React.CSSProperties;
}

export const SvgIconLight = forwardRef<HTMLElement, ISvgIconProps>(
  function SvgIconLightInner(
    {
      className,
      iconClassName,
      icon: Component,
      onClick,
      label,
      dataTestId,
      size = DEFAULT_SIZE,
      height,
      width,
      verticalAlignmentReset,
      legacyVerticalAlignment,
      style,
    },
    ref
  ) {
    const svgStyle: React.CSSProperties = {
      ...style,
      height: height,
      width: width,
    };
    return (
      <span
        className={className}
        ref={ref}
        onClick={onClick}
        aria-label={label}
        data-testid={dataTestId}
      >
        <Component
          className={cn(styles.svg, [styles[`svg_${size}`]], iconClassName, {
            [styles.svg_no_vertical_alignment]: verticalAlignmentReset,
            [styles.svg_legacy_vertical_alignment]: legacyVerticalAlignment,
          })}
          style={svgStyle}
        />
      </span>
    );
  }
);
