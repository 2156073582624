import { FunctionComponent } from 'react';

import { Box } from '../../box/box';
export interface ISpacerProps {
  className?: string;
  size: `${number}brm`;
}

/**
 * Spacer is another way to declare vertical margins between things
 */
export const Spacer: FunctionComponent<React.PropsWithChildren<ISpacerProps>> =
  function Spacer({ size, className }) {
    return <Box className={className} m={Box.bottom(size)}></Box>;
  };
