import { useState } from 'react';

import { Box, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { INotificationOrderEnum } from '@r-client/data/graphql-types';
import { NotificationsIcon } from '@r-client/shared/ui/chakra-ui-icons';
import { Link, Tooltip } from '@r-client/shared/ui/core';

import { useGetNotificationsQuery } from '../../graphql/get-notifications-query';
import { NotificationsDropdown } from '../notifications-dropdown/notifications-dropdown';

export interface INotificationsDropdownMenuButtonProps {
  unreadNotificationsCount: number;
  mobile?: boolean;
  color?: string;
  onNotificationsClick?: VoidFunction;
}

export const NotificationsDropdownMenuButton = observer(
  function NotificationsDropdownMenuButton({
    unreadNotificationsCount,
    mobile = false,
    color = 'grey.900',
    onNotificationsClick,
  }: INotificationsDropdownMenuButtonProps) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const getNotificationsQuery = useGetNotificationsQuery({
      variables: { first: 30, order: INotificationOrderEnum.CreatedAtDesc },
    });

    const { data, isLoading } = getNotificationsQuery;

    const NotificationsIconContent = () => (
      <Box
        role="button"
        position="relative"
        cursor="pointer"
        data-testid="notifications-icon"
        onClick={onNotificationsClick}
      >
        <NotificationsIcon color={color} boxSize="6" />
        {!!unreadNotificationsCount && (
          <Text
            as="span"
            textStyle="sm"
            color="white"
            position="absolute"
            bottom="-5px"
            right="-10px"
            textAlign="center"
            px="4px"
            py="1px"
            fontWeight="bold"
            background="red.500"
            borderRadius="xl"
            height="17px"
            minW="22px"
            data-testid="notifications-count-badge"
          >
            {unreadNotificationsCount < 100 ? unreadNotificationsCount : '99+'}
          </Text>
        )}
      </Box>
    );

    if (mobile) {
      return (
        <Link href="/notifications" variant={Link.variants['noUnderline']}>
          <NotificationsIconContent />
        </Link>
      );
    }

    return (
      <NotificationsDropdown
        notifications={data?.notifications.edges.map((e) => e.node)}
        loading={isLoading}
        visibilitySetter={setDropdownOpen}
        visible={dropdownOpen}
      >
        <div>
          {dropdownOpen ? (
            <NotificationsIconContent />
          ) : (
            <Tooltip content="Notifications" delayHide={0} placement="bottom">
              <div>
                <NotificationsIconContent />
              </div>
            </Tooltip>
          )}
        </div>
      </NotificationsDropdown>
    );
  }
);
