/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import * as Types from '@r-client/data/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGetNotificationsQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<
    Array<Types.INotificationOrderEnum> | Types.INotificationOrderEnum
  >;
}>;

export type IGetNotificationsQuery = {
  __typename?: 'Query';
  notifications: {
    __typename?: 'NotificationConnection';
    edges: Array<{
      __typename?: 'NotificationEdge';
      cursor: string;
      node: {
        __typename?: 'Notification';
        id: string;
        actorName?: string | null | undefined;
        avatarUrl?: string | null | undefined;
        targetName?: string | null | undefined;
        targetEvent?: string | null | undefined;
        action?: string | null | undefined;
        actionEnding?: string | null | undefined;
        description?: string | null | undefined;
        seen: boolean;
        createdAt: string;
        xCtaUrl?: string | null | undefined;
        xCtaText?: string | null | undefined;
        showEntireDescription: boolean;
        actor?:
          | { __typename?: 'User'; isAdmin: boolean; slug: string }
          | null
          | undefined;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null | undefined;
    };
  };
};

export type INotificationsMarkAsSeenMutationVariables = Types.Exact<{
  ids?: Types.InputMaybe<
    Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']
  >;
  notificationLimit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type INotificationsMarkAsSeenMutation = {
  __typename?: 'Mutation';
  notificationsMarkAsSeen?:
    | {
        __typename?: 'NotificationMarkAsSeenPayload';
        notifications?:
          | Array<{
              __typename?: 'Notification';
              id: string;
              actorName?: string | null | undefined;
              targetName?: string | null | undefined;
              targetEvent?: string | null | undefined;
              action?: string | null | undefined;
              actionEnding?: string | null | undefined;
              description?: string | null | undefined;
              seen: boolean;
              createdAt: string;
              xCtaUrl?: string | null | undefined;
              xCtaText?: string | null | undefined;
            }>
          | null
          | undefined;
        errors?:
          | Array<{ __typename?: 'ValidationError'; message: string }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type IMarkHeaderNotificationsViewedMutationVariables = Types.Exact<{
  input: Types.INotificationMarkHeaderViewedInput;
}>;

export type IMarkHeaderNotificationsViewedMutation = {
  __typename?: 'Mutation';
  notificationsMarkHeaderViewed?:
    | {
        __typename?: 'NotificationMarkHeaderViewedPayload';
        viewed?: boolean | null | undefined;
        errors?:
          | Array<{ __typename?: 'ValidationError'; message: string }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const GetNotificationsDocument = gql`
  query GetNotifications(
    $first: Int!
    $after: String
    $order: [NotificationOrderEnum!]
  ) {
    notifications(first: $first, after: $after, order: $order) {
      edges {
        cursor
        node {
          id
          actorName
          avatarUrl
          targetName
          targetEvent
          action
          actionEnding
          description
          seen
          createdAt
          xCtaUrl
          xCtaText
          showEntireDescription
          actor {
            isAdmin
            slug
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`;
export type GetNotificationsQueryResult = Apollo.QueryResult<
  IGetNotificationsQuery,
  IGetNotificationsQueryVariables
>;
export const NotificationsMarkAsSeenDocument = gql`
  mutation NotificationsMarkAsSeen($ids: [ID!], $notificationLimit: Int) {
    notificationsMarkAsSeen(
      input: { ids: $ids, notificationLimit: $notificationLimit }
    ) {
      notifications {
        id
        actorName
        targetName
        targetEvent
        action
        actionEnding
        description
        seen
        createdAt
        xCtaUrl
        xCtaText
      }
      errors {
        message
      }
    }
  }
`;
export type INotificationsMarkAsSeenMutationFn = Apollo.MutationFunction<
  INotificationsMarkAsSeenMutation,
  INotificationsMarkAsSeenMutationVariables
>;
export type NotificationsMarkAsSeenMutationResult =
  Apollo.MutationResult<INotificationsMarkAsSeenMutation>;
export type NotificationsMarkAsSeenMutationOptions = Apollo.BaseMutationOptions<
  INotificationsMarkAsSeenMutation,
  INotificationsMarkAsSeenMutationVariables
>;
export const MarkHeaderNotificationsViewedDocument = gql`
  mutation MarkHeaderNotificationsViewed(
    $input: NotificationMarkHeaderViewedInput!
  ) {
    notificationsMarkHeaderViewed(input: $input) {
      viewed
      errors {
        message
      }
    }
  }
`;
export type IMarkHeaderNotificationsViewedMutationFn = Apollo.MutationFunction<
  IMarkHeaderNotificationsViewedMutation,
  IMarkHeaderNotificationsViewedMutationVariables
>;
export type MarkHeaderNotificationsViewedMutationResult =
  Apollo.MutationResult<IMarkHeaderNotificationsViewedMutation>;
export type MarkHeaderNotificationsViewedMutationOptions =
  Apollo.BaseMutationOptions<
    IMarkHeaderNotificationsViewedMutation,
    IMarkHeaderNotificationsViewedMutationVariables
  >;
