import { Text } from '@r-client/shared/ui/core';

import { INotification } from '../../types';

export interface INotificationTitleProps {
  notification: INotification;
}

export function NotificationTitle({ notification }: INotificationTitleProps) {
  const { action, actionEnding, targetName, targetEvent } = notification;

  return (
    <Text as="span" weight={{ lg: '400' }}>
      <TitlePart condition={!!notification.actor}>
        <Text as="span" weight={{ lg: '500' }}>
          {notification.actorName}
        </Text>
      </TitlePart>

      <TitlePart condition={action}>{action}</TitlePart>
      <TitlePart condition={targetName}>{targetName}</TitlePart>
      <TitlePart condition={actionEnding}>{actionEnding}</TitlePart>
      <TitlePart condition={targetEvent}>{targetEvent}</TitlePart>
    </Text>
  );
}

interface ITitlePartProps {
  condition: string | null | undefined | boolean;
  children: React.ReactNode;
}

function TitlePart({ condition, children }: ITitlePartProps) {
  if (!condition) return null;

  return <>{children} </>;
}
