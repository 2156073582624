/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import * as Types from '@r-client/data/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ICreateRsvpMutationVariables = Types.Exact<{
  eventId: Types.Scalars['ID']['input'];
  fullName: Types.Scalars['String']['input'];
  email: Types.Scalars['Email']['input'];
  linkedinUrl?: Types.InputMaybe<Types.Scalars['Url']['input']>;
  phoneNumber?: Types.InputMaybe<Types.Scalars['PhoneNumber']['input']>;
}>;

export type ICreateRsvpMutation = {
  __typename?: 'Mutation';
  eventsGuestsRsvpCreate?:
    | {
        __typename?: 'EventsGuestRsvpCreatePayload';
        errors?:
          | Array<{ __typename?: 'ValidationError'; message: string }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type IGetEventDetailsQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;

export type IGetEventDetailsQuery = {
  __typename?: 'Query';
  event?:
    | {
        __typename?: 'EventsEvent';
        id: string;
        slug: string;
        title: string;
        body?: string | null | undefined;
        bodySanitized?: string | null | undefined;
        undisclosed?: boolean | null | undefined;
        coverPhotoUrl?: string | null | undefined;
        beginAt: string;
        endAt: string;
        timezone: string;
        future: boolean;
        past?: boolean | null | undefined;
        live: boolean;
        category?: Types.IEventsCategoryEnum | null | undefined;
        useRsvp?: boolean | null | undefined;
        url?: string | null | undefined;
        cta?:
          | {
              __typename?: 'EventsCta';
              rsvp?: boolean | null | undefined;
              text: string;
              url?: string | null | undefined;
            }
          | null
          | undefined;
        address?:
          | {
              __typename?: 'Address';
              id: string;
              street1?: string | null | undefined;
              street2?: string | null | undefined;
              city?: string | null | undefined;
              state?: string | null | undefined;
              country?: string | null | undefined;
              postalCode?: string | null | undefined;
            }
          | null
          | undefined;
        xHosts?:
          | Array<{
              __typename?: 'EventsHost';
              id: string;
              position?: string | null | undefined;
              bio?: string | null | undefined;
              avatarUrl?: string | null | undefined;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
            }>
          | null
          | undefined;
        offerings: Array<{
          __typename?: 'Offering';
          id: string;
          slug: string;
          companyName: string;
          description?: string | null | undefined;
          cardImageUrl?: string | null | undefined;
          cardTooltipText?: string | null | undefined;
          logoUrl?: string | null | undefined;
          state?: Types.IOfferingStateEnum | null | undefined;
          following: boolean;
          indexInAllOfferings: number;
          numOfAllOfferings: number;
          badges: Array<Types.IOfferingBadgeNameEnum>;
          tags?:
            | Array<{
                __typename?: 'TagsTag';
                id: string;
                colorHex?: string | null | undefined;
                displayName: string;
                slug: string;
              }>
            | null
            | undefined;
        }>;
        tags?:
          | Array<{
              __typename?: 'TagsTag';
              id: string;
              colorHex?: string | null | undefined;
              displayName: string;
              slug: string;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type IGetEventsQueryVariables = Types.Exact<{
  timeFilters?: Types.InputMaybe<Types.IEventsTimeFiltersInput>;
  states?: Types.InputMaybe<
    Array<Types.IEventsStateEnum> | Types.IEventsStateEnum
  >;
  order?: Types.InputMaybe<
    Array<Types.IEventsOrderEnum> | Types.IEventsOrderEnum
  >;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  slugs?: Types.InputMaybe<
    Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']
  >;
}>;

export type IGetEventsQuery = {
  __typename?: 'Query';
  events: {
    __typename?: 'EventsEventConnection';
    edges: Array<{
      __typename?: 'EventsEventEdge';
      cursor: string;
      node: {
        __typename?: 'EventsEvent';
        id: string;
        title: string;
        body?: string | null | undefined;
        bodySanitized?: string | null | undefined;
        slug: string;
        url?: string | null | undefined;
        future: boolean;
        past?: boolean | null | undefined;
        live: boolean;
        beginAt: string;
        undisclosed?: boolean | null | undefined;
        endAt: string;
        timezone: string;
        useRsvp?: boolean | null | undefined;
        coverPhotoUrl?: string | null | undefined;
        category?: Types.IEventsCategoryEnum | null | undefined;
        cta?:
          | {
              __typename?: 'EventsCta';
              rsvp?: boolean | null | undefined;
              text: string;
              url?: string | null | undefined;
            }
          | null
          | undefined;
        address?:
          | {
              __typename?: 'Address';
              id: string;
              street1?: string | null | undefined;
              street2?: string | null | undefined;
              city?: string | null | undefined;
              state?: string | null | undefined;
              country?: string | null | undefined;
              postalCode?: string | null | undefined;
            }
          | null
          | undefined;
        xHosts?:
          | Array<{
              __typename?: 'EventsHost';
              id: string;
              avatarUrl?: string | null | undefined;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
            }>
          | null
          | undefined;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null | undefined;
      hasNextPage: boolean;
    };
  };
};

export type IGetTagEventsQueryVariables = Types.Exact<{
  timeFilters?: Types.InputMaybe<Types.IEventsTimeFiltersInput>;
  states?: Types.InputMaybe<
    Array<Types.IEventsStateEnum> | Types.IEventsStateEnum
  >;
  order?: Types.InputMaybe<
    Array<Types.IEventsOrderEnum> | Types.IEventsOrderEnum
  >;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  slugs?: Types.InputMaybe<
    Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']
  >;
  tagSlug?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type IGetTagEventsQuery = {
  __typename?: 'Query';
  events: {
    __typename?: 'EventsEventConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'EventsEventEdge';
      cursor: string;
      node: {
        __typename?: 'EventsEvent';
        id: string;
        title: string;
        body?: string | null | undefined;
        bodySanitized?: string | null | undefined;
        slug: string;
        url?: string | null | undefined;
        future: boolean;
        past?: boolean | null | undefined;
        live: boolean;
        beginAt: string;
        undisclosed?: boolean | null | undefined;
        endAt: string;
        timezone: string;
        useRsvp?: boolean | null | undefined;
        coverPhotoUrl?: string | null | undefined;
        category?: Types.IEventsCategoryEnum | null | undefined;
        cta?:
          | {
              __typename?: 'EventsCta';
              rsvp?: boolean | null | undefined;
              text: string;
              url?: string | null | undefined;
            }
          | null
          | undefined;
        address?:
          | {
              __typename?: 'Address';
              id: string;
              street1?: string | null | undefined;
              street2?: string | null | undefined;
              city?: string | null | undefined;
              state?: string | null | undefined;
              country?: string | null | undefined;
              postalCode?: string | null | undefined;
            }
          | null
          | undefined;
        xHosts?:
          | Array<{
              __typename?: 'EventsHost';
              id: string;
              avatarUrl?: string | null | undefined;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
            }>
          | null
          | undefined;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null | undefined;
      hasNextPage: boolean;
    };
  };
};

export type IGetUpcomingEventQueryVariables = Types.Exact<{
  timeFilters?: Types.InputMaybe<Types.IEventsTimeFiltersInput>;
  states?: Types.InputMaybe<
    Array<Types.IEventsStateEnum> | Types.IEventsStateEnum
  >;
  order?: Types.InputMaybe<
    Array<Types.IEventsOrderEnum> | Types.IEventsOrderEnum
  >;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type IGetUpcomingEventQuery = {
  __typename?: 'Query';
  events: {
    __typename?: 'EventsEventConnection';
    nodes: Array<{
      __typename?: 'EventsEvent';
      id: string;
      slug: string;
      title: string;
      coverPhotoUrl?: string | null | undefined;
      beginAt: string;
      endAt: string;
      timezone: string;
      category?: Types.IEventsCategoryEnum | null | undefined;
      live: boolean;
      address?:
        | {
            __typename?: 'Address';
            id: string;
            city?: string | null | undefined;
          }
        | null
        | undefined;
    }>;
  };
};

export type IGetUserReservationForEventQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;

export type IGetUserReservationForEventQuery = {
  __typename?: 'Query';
  event?:
    | { __typename?: 'EventsEvent'; id: string; userSubscribed: boolean }
    | null
    | undefined;
};

export const CreateRsvpDocument = gql`
  mutation CreateRsvp(
    $eventId: ID!
    $fullName: String!
    $email: Email!
    $linkedinUrl: Url
    $phoneNumber: PhoneNumber
  ) {
    eventsGuestsRsvpCreate(
      input: {
        guest: {
          eventId: $eventId
          fullName: $fullName
          email: $email
          linkedinUrl: $linkedinUrl
          phoneNumber: $phoneNumber
        }
      }
    ) {
      errors {
        message
      }
    }
  }
`;
export type ICreateRsvpMutationFn = Apollo.MutationFunction<
  ICreateRsvpMutation,
  ICreateRsvpMutationVariables
>;
export type CreateRsvpMutationResult =
  Apollo.MutationResult<ICreateRsvpMutation>;
export type CreateRsvpMutationOptions = Apollo.BaseMutationOptions<
  ICreateRsvpMutation,
  ICreateRsvpMutationVariables
>;
export const GetEventDetailsDocument = gql`
  query GetEventDetails($slug: String!) {
    event(slug: $slug) {
      id
      slug
      title
      body
      bodySanitized
      undisclosed
      coverPhotoUrl(resize: { width: 576, height: 1024, type: auto })
      beginAt
      endAt
      timezone
      future
      past
      live
      cta {
        rsvp
        text
        url
      }
      category
      useRsvp
      url
      address {
        id
        street1
        street2
        city
        state
        country
        postalCode
      }
      xHosts {
        id
        position
        bio
        avatarUrl(resize: { width: 200, height: 200, type: auto })
        firstName
        lastName
      }
      offerings {
        id
        slug
        companyName
        description
        cardImageUrl(resize: { width: 348, height: 361, type: auto })
        cardTooltipText
        logoUrl(resize: { width: 200, height: 200, type: auto })
        state
        following
        indexInAllOfferings
        numOfAllOfferings
        badges
        tags {
          id
          colorHex
          displayName
          slug
        }
      }
      tags {
        id
        colorHex
        displayName
        slug
      }
    }
  }
`;
export type GetEventDetailsQueryResult = Apollo.QueryResult<
  IGetEventDetailsQuery,
  IGetEventDetailsQueryVariables
>;
export const GetEventsDocument = gql`
  query GetEvents(
    $timeFilters: EventsTimeFiltersInput
    $states: [EventsStateEnum!]
    $order: [EventsOrderEnum!]
    $after: String
    $first: Int
    $slugs: [String!]
  ) {
    events(
      timeFilters: $timeFilters
      states: $states
      order: $order
      after: $after
      first: $first
      slugs: $slugs
    ) {
      edges {
        cursor
        node {
          id
          title
          body
          bodySanitized
          slug
          url
          future
          past
          live
          cta {
            rsvp
            text
            url
          }
          beginAt
          undisclosed
          endAt
          timezone
          useRsvp
          coverPhotoUrl(resize: { width: 576, height: 1024, type: auto })
          category
          address {
            id
            street1
            street2
            city
            state
            country
            postalCode
          }
          xHosts {
            id
            avatarUrl(resize: { width: 100, height: 100, type: auto })
            firstName
            lastName
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
export type GetEventsQueryResult = Apollo.QueryResult<
  IGetEventsQuery,
  IGetEventsQueryVariables
>;
export const GetTagEventsDocument = gql`
  query GetTagEvents(
    $timeFilters: EventsTimeFiltersInput
    $states: [EventsStateEnum!]
    $order: [EventsOrderEnum!]
    $after: String
    $first: Int
    $slugs: [String!]
    $tagSlug: String
  ) {
    events(
      timeFilters: $timeFilters
      states: $states
      order: $order
      after: $after
      first: $first
      slugs: $slugs
      tagSlug: $tagSlug
    ) {
      edges {
        cursor
        node {
          id
          title
          body
          bodySanitized
          slug
          url
          future
          past
          live
          cta {
            rsvp
            text
            url
          }
          beginAt
          undisclosed
          endAt
          timezone
          useRsvp
          coverPhotoUrl
          category
          address {
            id
            street1
            street2
            city
            state
            country
            postalCode
          }
          xHosts {
            id
            avatarUrl
            firstName
            lastName
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
export type GetTagEventsQueryResult = Apollo.QueryResult<
  IGetTagEventsQuery,
  IGetTagEventsQueryVariables
>;
export const GetUpcomingEventDocument = gql`
  query GetUpcomingEvent(
    $timeFilters: EventsTimeFiltersInput
    $states: [EventsStateEnum!]
    $order: [EventsOrderEnum!]
    $first: Int
  ) {
    events(
      timeFilters: $timeFilters
      states: $states
      order: $order
      first: $first
    ) {
      nodes {
        id
        address {
          id
          city
        }
        slug
        title
        coverPhotoUrl(resize: { width: 348, height: 182, type: fill })
        beginAt
        endAt
        timezone
        category
        live
      }
    }
  }
`;
export type GetUpcomingEventQueryResult = Apollo.QueryResult<
  IGetUpcomingEventQuery,
  IGetUpcomingEventQueryVariables
>;
export const GetUserReservationForEventDocument = gql`
  query getUserReservationForEvent($slug: String!) {
    event(slug: $slug) {
      id
      userSubscribed
    }
  }
`;
export type GetUserReservationForEventQueryResult = Apollo.QueryResult<
  IGetUserReservationForEventQuery,
  IGetUserReservationForEventQueryVariables
>;
