import CN from 'clsx';
import { formatDistance } from 'date-fns';

import { Avatar, Box, Button, Link, Text } from '@r-client/shared/ui/core';
import { Flex, FlexItem } from '@r-client/shared/ui/flex';

import { INotification } from '../../types';
import { NotificationIndicator } from '../notification-indicator/notification-indicator';
import { NotificationTitle } from '../notification-title/notification-title';

import styles from './notification-row.module.scss';

export interface INotificationRowProps {
  notification: INotification;
  onClick?: (id: string) => Promise<void>;
  compact?: boolean;
}

export function NotificationRow({
  notification,
  onClick,
  compact,
}: INotificationRowProps) {
  const { seen } = notification;

  const handleClick = () => {
    if (onClick) onClick(notification.id);
  };

  const avatarSize = compact ? 'xSmall' : 'small';

  return (
    <Link
      href={notification.xCtaUrl ?? undefined}
      onClick={handleClick}
      variant={Link.variants['inherit']}
      data-testid={`notification-${notification.id}`}
    >
      <Box p="1brm" className={CN(styles.row, { [styles.seen]: seen })}>
        {!seen && <NotificationIndicator compact={compact} />}

        <Flex gap="1brm">
          <FlexItem>
            <Avatar
              mobileSize={avatarSize}
              size={avatarSize}
              url={notification.avatarUrl || ''}
              badge={notification.actor?.isAdmin ? 'admin' : undefined}
            />
          </FlexItem>

          <FlexItem grow={1}>
            {compact ? (
              <CompactNotificationBody notification={notification} />
            ) : (
              <FullNotificationBody notification={notification} />
            )}
          </FlexItem>

          {!compact && <NotificationCTA notification={notification} />}
        </Flex>
      </Box>
    </Link>
  );
}

function FullNotificationBody({ notification }: INotificationRowProps) {
  return (
    <>
      <Text type="bodyBig" className={styles.title} m={Box.bottom(0.5)}>
        <NotificationTitle notification={notification} />
      </Text>

      <Text type="bodySmall" weight="300" color="gray70" m={Box.bottom(0.5)}>
        {dateText(notification)}
      </Text>

      <Box>
        <Text
          type="bodyMedium"
          color="gray5"
          className={CN({
            [styles.descriptionQuote]: !notification.showEntireDescription,
          })}
        >
          {notification.description}
        </Text>
      </Box>
    </>
  );
}

function CompactNotificationBody({ notification }: INotificationRowProps) {
  return (
    <>
      <Text
        type="bodyMedium"
        className={styles.title}
        lineHeight={{ lg: '1.2' }}
      >
        <NotificationTitle notification={notification} />
      </Text>

      <Text className={styles.dateText} weight="300" color="gray70" m="4px 0 0">
        {dateText(notification)}
      </Text>
    </>
  );
}

function NotificationCTA({ notification }: INotificationRowProps) {
  const { xCtaText, xCtaUrl: ctaUrl } = notification;

  if (!xCtaText || !ctaUrl) return null;

  return (
    <FlexItem hidden={{ xs: true, sm: true }}>
      <Button
        className={CN(styles.actionBtn, { [styles.seen]: notification.seen })}
      >
        {xCtaText}
      </Button>
    </FlexItem>
  );
}

function dateText(notification: INotification) {
  return formatDistance(new Date(notification.createdAt), new Date(), {
    addSuffix: true,
  });
}
