import { Box, Button, Text } from '@chakra-ui/react';

import { useAnalytics } from '@r-client/shared/data/analytics';

import backgroundDesktop from './assets/back@2x.jpg';
import backgroundMobile from './assets/back-xs.jpg';

export function FilmBanner() {
  const analytics = useAnalytics();

  const onBannerClick = () => {
    analytics.track({
      name: 'promo_flash_banner_clicked',
      params: {
        label: 'film_banner',
      },
    });
  };

  return (
    <Box
      as="a"
      href="/film"
      display="grid"
      gap="8px 16px"
      gridTemplateColumns={{ sm: '1fr auto 1fr' }}
      alignItems="center"
      p={{ base: '12px 16px' }}
      color="white"
      textAlign="center"
      overflow="hidden"
      backgroundColor="black"
      backgroundImage={{
        base: backgroundMobile,
        sm: backgroundDesktop,
      }}
      backgroundSize="cover"
      backgroundPosition="50% 0"
      onClick={onBannerClick}
    >
      <Box
        display={{ sm: 'flex' }}
        gridColumnStart={{ sm: 2 }}
        alignItems="center"
        gap="12px"
      >
        <Text
          display={{ base: 'none', md: 'block' }}
          padding="6px 7px"
          fontSize="10px"
          fontWeight="500"
          lineHeight="1em"
          letterSpacing="0.2px"
          textTransform="uppercase"
          backgroundColor="blue.500"
          borderRadius="3px"
        >
          New
        </Text>
        <Text fontSize="16px" letterSpacing="-0.24px">
          Put your name in the credits.{' '}
          <Text as="span" whiteSpace="nowrap">
            Introducing film investing.
          </Text>
        </Text>
      </Box>
      <Box justifySelf={{ sm: 'right' }}>
        <Button
          display="block"
          backgroundColor="transparent"
          as="span"
          color="white"
          p="7px 18px"
          height="auto"
          borderRadius="3px"
          fontSize="14px"
          fontWeight="600"
          lineHeight="21px"
          boxShadow="inset 0 0 0 1px rgba(255,255,255,0.4)"
          _hover={{
            color: 'inherit',
            backgroundColor: 'grey.900',
          }}
          variant="republic-default"
          size="md"
        >
          Lights, camera, invest!
        </Button>
      </Box>
    </Box>
  );
}
