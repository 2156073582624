export enum E_AUTOSUGGEST_ITEMS_LIST {
  OFFERING = 'offering',
  USER = 'user',
  TAG = 'tag',
}

export const AUTOSUGGEST_ITEMS_CONTENT = {
  [E_AUTOSUGGEST_ITEMS_LIST.OFFERING]: {
    title: 'Deals',
  },
  [E_AUTOSUGGEST_ITEMS_LIST.USER]: {
    title: 'Users',
  },
  [E_AUTOSUGGEST_ITEMS_LIST.TAG]: {
    title: 'Categories',
  },
};

export enum E_OFFERING_STATUS_TYPES {
  live = 'live',
  comingSoon = 'coming-soon',
  funded = 'funded',
}
